import Navbar from "./components/UI/Navbar";
import Sidebar from "./components/UI/Sidebar";
import Footer from "./components/UI/Footer";
import Root from "./routes/root";
import { Toaster } from "react-hot-toast";
import "./style/App.scss";
import LoaderFullScreen from "./components/UI/LoaderFullScreen";
import { getValues } from "./reducers/loaderReducer";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

function App() {
  const { pathname } = useLocation();

  const { hide, title } = useSelector(getValues);

  if (
    pathname === "/login" ||
    pathname === "/register" ||
    pathname.includes("view-formulario")
  ) {
    return <Root />;
  }

  return (
    <div
      className="container-scroller"
      style={{
        height: "100vh",
      }}
    >
      <Sidebar />
      <Toaster position="top-right" />
      <LoaderFullScreen title={title} hide={hide} />
      <div
        className="container-fluid page-body-wrapper"
        style={{ overflowY: "auto" }}
      >
        <Navbar />

        <div className="main-panel">
          <div className="content-wrapper">
            <Root />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
