/* eslint-disable eqeqeq */
import { useState, useRef } from "react";
import Input from "./components/Input";
import Select from "./components/Select";
import "./style/app.css";
import useLogin from "../../hoock/useLogin";
import { ConfiguracionAdd } from "../../service/configuracion";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { Alert, AlertTitle, Paper } from "@mui/material";

function App() {
  const { user_id, token } = useLogin();
  const [formValues, setFormValues] = useState([
    {
      label: "Nombre",
      type: "text",
      value: "",
      disabled: true,
    },
    {
      label: "Apellido paterno",
      type: "text",
      value: "",
      disabled: true,
    },
    {
      label: "Apellido materno",
      type: "text",
      value: "",
      disabled: true,
    },
    {
      label: "RFC",
      type: "text",
      value: "",
      disabled: true,
    },
    {
      label: "CFDI",
      type: "text",
      value: "",
      disabled: true,
    },
    {
      label: "Correo electronico",
      type: "text",
      value: "",
      disabled: true,
    },
    {
      label: "Telefono",
      type: "number",
      value: "",
      disabled: true,
    },
  ]);
  const [toggle, setToggle] = useState(false);
  const [formVal, setformVal] = useState({
    value: "",
  });
  const [opciones, setopciones] = useState([]);
  const [opcionesAfter, setopcionesAfter] = useState([]);
  const [select, setselect] = useState();
  const [linkForm, setlinkForm] = useState();

  const inputRef = useRef();
  const selectRef = useRef();

  const handleChange = (e, index) => {
    const values = [...formValues];
    values[index].value = e.target.value;
    setFormValues(values);
  };

  const handleAddField = (e) => {
    e.preventDefault();
    if (selectRef.current.value === "select") {
      opciones[inputRef.current.value] = opcionesAfter;
      setopciones(opciones);
      setopcionesAfter([]);
    }

    const values = [...formValues];
    values.push({
      label: inputRef.current.value || "label",
      type: selectRef.current.value || "text",
      value: "",
    });
    setFormValues(values);
    setToggle(false);
  };

  const handleDeleteField = (e, index) => {
    const values = [...formValues];
    values.splice(index, 1);
    setFormValues(values);
  };

  const addBtnClick = (e) => {
    e.preventDefault();
    setToggle(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    formValues.forEach((obj) => {
      if (obj.type === "select") {
        const option = opciones[obj.label];
        obj.opciones = option;
      }
    });

    const data = {
      preguntas: JSON.stringify(formValues),
      user_id: user_id,
    };

    ConfiguracionAdd(data, token).then((res) => {
      if (res.success) {
        setlinkForm(`/configuracion/view-formulario/${res.data.uuid}`);
        Swal.fire({
          icon: "success",
          title: "Exito",
          text: "Se ha creado el formulario correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.message,
        });
      }
    });

    setFormValues([]);
    setopciones([]);
    setopcionesAfter([]);
    setselect();
  };

  return (
    <div className="App">
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-5">
          <Paper
            elevation={3}
            sx={{
              padding: "15px",
            }}
          >
            <h2 className="text-center mt-3">
              Formulario de registro de proveedores
            </h2>
          </Paper>
        </div>
      </div>
      <div className="container-fluid">
        {linkForm && (
          <Alert severity="success" style={{ width: "100%" }}>
            <AlertTitle>
              <strong>Guardado con exito</strong>
            </AlertTitle>
            Se ha creado el formulario correctamente
            <Link target="_blank" to={linkForm}>
              Link del formulario
            </Link>
          </Alert>
        )}
      </div>

      <div className="row mt-3">
        <div className="col-md-8">
          {formValues.length === 0 && (
            <Alert severity="info" style={{ width: "100%" }}>
              <AlertTitle>
                <strong>Info</strong>
              </AlertTitle>
              Añade una pregunta para crear el formulario
            </Alert>
          )}
          {formValues.length > 0 && (
            <form onSubmit={handleSubmit}>
              <div className="row">
                {formValues.map((obj, index) => (
                  <div className="col-4">
                    {obj.type === "select" ? (
                      <Select
                        key={index}
                        objValue={obj}
                        onChange={handleChange}
                        opciones={opciones?.[obj.label] ?? []}
                        index={index}
                        deleteField={handleDeleteField}
                      />
                    ) : (
                      <Input
                        key={index}
                        objValue={obj}
                        onChange={handleChange}
                        index={index}
                        deleteField={handleDeleteField}
                      />
                    )}
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-end">
                <Button variant="contained" type="submit" color="success">
                  Guardar
                </Button>
              </div>
            </form>
          )}
        </div>
        <div className="col-md-4 bg-new">
          {!toggle ? (
            <div className="text-end mt-3">
              <Button
                variant="contained"
                onClick={addBtnClick}
                className="add-btn"
              >
                Añadir nueva pregunta
              </Button>
            </div>
          ) : (
            <div className="dialog-box">
              <div>
                <label className="text-dark" htmlFor="input">
                  Titulo de la pregunta
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Titulo de la pregunta"
                  ref={inputRef}
                  id="input"
                />
              </div>
              <div>
                <label className="text-dark" htmlFor="select">
                  Tipo de campo
                </label>
                <select
                  id="select"
                  className="form-control"
                  ref={selectRef}
                  value={select}
                  onChange={(e) => {
                    setselect(e.target.value);
                  }}
                >
                  <option value="">Seleccione una opción</option>
                  <option value="text">Texto</option>
                  <option value="number">Numero</option>
                  <option value="email">Correo electronico</option>
                  <option value="password">Contraseña</option>
                  <option value="date">Fecha</option>
                  <option value="time">Hora</option>
                  <option value="datetime-local">Fecha y hora</option>
                  <option value="color">Color</option>
                  <option value="tel">Telefono</option>
                  <option value="file">Archivo</option>
                  <option value="range">Rango</option>
                  <option value="search">Busqueda</option>
                  <option value="url">URL</option>
                  <option value="select">Opciones</option>
                </select>
              </div>
              {select == "select" && (
                <div>
                  <label htmlFor="input">Opciones</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Opcion"
                    value={formVal.value}
                    onChange={(e) =>
                      setformVal({ ...formVal, value: e.target.value })
                    }
                  />
                  <button
                    className="add-btn mt-2"
                    onClick={(e) => {
                      e.preventDefault();
                      setopcionesAfter([...opcionesAfter, formVal.value]);
                      setformVal({ value: "" });
                    }}
                  >
                    Agregar opción
                  </button>
                </div>
              )}
              {select != "select" ? (
                <div className="text-start pt-4">
                  <Button variant="contained" onClick={handleAddField}>
                    Añadir campo
                  </Button>
                </div>
              ) : (
                <div className="text-end pt-4">
                  <Button variant="contained" onClick={handleAddField}>
                    Añadir campo
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default App;
