import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, Input } from 'reactstrap';
import useHijos from '../../../hoock/useHijos';


export default function ModalAdd({
    ModalFecha,
    toggleFecha,
    IDFecha,
    token,
    PresupuestosGetService }) {

    const { loading, error, message, success, Fechas, HijosDateGetService } = useHijos();
    let i = 1;

    useEffect(() => {
        if (success == true) {
            PresupuestosGetService(token)
        }
    }, [PresupuestosGetService, success])


    useEffect(() => {
        if (ModalFecha == true) {
            HijosDateGetService(IDFecha, token)
        }

    }, [IDFecha])

    const formatNumber = (number) => new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(number);

    const fechas = (fecha) => {
        let meses = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
        ];
        let a = fecha.substr(0, 4);
        let m = fecha.substr(5, 2);
        let d = fecha.substr(8);
        return d + " de " + meses[m - 1] + " de " + a;
    };


    return <>
        <Modal isOpen={ModalFecha} toggle={toggleFecha} size="lg">
            <ModalHeader className="backend_titulo" toggle={toggleFecha}>Fecha de pagos</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-12">
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Fecha</th>
                                    <th>Monto</th>
                                    <th>Status</th>
                                    <th>Tipo</th>
                                    <th>Orden Compra</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Fechas.map(({ id, fecha, monto, status, opcion_pagos }) => {
                                        return <tr className="text-black" key={id}>
                                            <td>{i++}</td>
                                            <td>{fechas(fecha)}</td>
                                            <td>${formatNumber(monto)}</td>
                                            <td>
                                                <div className={
                                                    status == 'Pendiente'
                                                        ?
                                                        'badge bg-warning text-wrap'
                                                        :
                                                        'badge bg-success text-wrap'
                                                }>
                                                    {status}
                                                </div></td>
                                            <td style={{ textTransform: 'uppercase' }}>{opcion_pagos}</td>
                                            <td className='text-center'>
                                                <button className="btn btn-danger btn-sm">
                                                    <i className="mdi mdi-file-pdf-box"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    </>
}
