const ENDPOINT = process.env.REACT_APP_API_URL_DEVELOP;

export async function ProveedoresNuevosGet(users_id, token) {
  return fetch(`${ENDPOINT}ProveedoresNuevosGet/${users_id}`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `${token}`,
    },
    method: "get",
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function ProveedoresNuevosCFDIGet(token) {
  return fetch(`${ENDPOINT}ProveedoresNuevosCFDIGet`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `${token}`,
    },
    method: "get",
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function ProveedoresNuevosAdd(data, token) {
  return fetch(`${ENDPOINT}ProveedoresNuevosAdd`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `${token}`,
    },
    method: "post",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function ProveedoresNuevosUpdate(data, token) {
  return fetch(`${ENDPOINT}ProveedoresNuevosUpdate`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `${token}`,
    },
    method: "post",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function ProveedoresNuevosDelete(data, token) {
  return fetch(`${ENDPOINT}ProveedoresNuevosDelete`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `${token}`,
    },
    method: "post",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}
