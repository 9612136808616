/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import usePresupuesto from "../../hoock/usePresupuesto";
import ModaladdPadre from "./Modals/ModalPadre";
import ModalSubpadre from "./Modals/ModaSubpadre";
import ModalHijo from "./Modals/ModalHijo";
import ModalFechas from "./Modals/ModalFecha";
import ModalEditar from "./Modals/ModalEditarCampo";
import ModalElim from "./Modals/ModalEliminar";
import ModalEditarPre from "./Modals/ModalEditarCuenta";
import ModalAddAportacion from "./Modals/ModalAportacion";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridNoRecords,
  GridCell,
  GridDetailRow,
  GridRow,
  GridHeaderCell,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import {GridPDFExport} from "@progress/kendo-react-pdf";
import {filterBy} from "@progress/kendo-data-query";
import {process} from "@progress/kendo-data-query";
import {setExpandedState, setGroupIds} from "@progress/kendo-react-data-tools";
import {hideLoader, showLoader, setTitle} from "../../reducers/loaderReducer";
import {useDispatch} from "react-redux";
import useLogin from "../../hoock/useLogin";
import useProyectos from "../../hoock/useProyectos";
import Swal from "sweetalert2";
import {useParams} from "react-router-dom";
import ExcelJS from "exceljs";
import "./ExcelStyles.css";
import {format} from "date-fns";

const initialDataState = {
  take: 10000,
  skip: 0,
  group: [
    {
      field: "grupo",
    },
  ],
};
const aggregates = [
  {
    field: "monto",
    aggregate: "sum",
  },
  {
    field: "iva",
    aggregate: "sum",
  },
  {
    field: "total",
    aggregate: "sum",
  },
];

const initialFilter = {
  logic: "and",
  filters: [],
};

const initialSort = [
  // {
  //   field: "modelo",
  //   dir: "desc",
  // },
  // {
  //   field: "serie",
  //   dir: "desc",
  // },
  // {
  //   field: "fecha_venta",
  //   dir: "desc",
  // },
  // {
  //   field: "Venta",
  //   dir: "desc",
  // },
];

const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

export default function Index() {
  const {logout, token, user_rol, cliente_id} = useLogin();
  const {id} = useParams();
  const dispatch = useDispatch();
  const {
    message,
    success,
    Presupuestos,
    Subtotal,
    Iva_,
    Total_,
    Pagado_,
    PresupuestosGetService,
    PresupuestosPadreUpdateService,
    PadresMontoGetService,
  } = usePresupuesto();

  const {Proyecto, ProyectoGetService} = useProyectos();

  useEffect(() => {
    if (id) {
      ProyectoGetService(id, token);
    }
  }, [id, ProyectoGetService]);

  const [dataState, setDataState] = React.useState(initialDataState);
  const [result, setResult] = React.useState(
    processWithGroups([], initialDataState)
  );
  const [collapsedState, setCollapsedState] = React.useState([]);

  const [sort, setSort] = React.useState(initialSort);
  const [filter, setFilter] = React.useState(initialFilter);
  const [page, setPage] = React.useState(initialDataState);

  const [Children, setChildren] = useState([]);
  const [Padre, setPadre] = useState([]);
  const [PadreID, setPadreID] = useState("");
  const [HijoID, setHijoID] = useState("");
  const [NombreH, setNombreH] = useState("");
  const [NivelID, setNivelID] = useState("");
  const [VariableID, setVariableID] = useState("");
  const [array_titles, setarray_titles] = useState([]);

  /*
   * Editar Campos
   */
  const [ModalEditarCampo, setModalEditarCampo] = useState(false);
  const [Campo, setCampo] = useState("");
  const [ValorCampo, setValorCampo] = useState("");
  const [IDCampo, setIDCampo] = useState("");
  const [Tipo, setTipo] = useState("");

  useEffect(() => {
    if (success == true) {
      PresupuestosGetService(id, token);
      //setActivarCampoNu(null);
      //setActivarCampoN(null);
    }
  }, [PresupuestosGetService, success]);

  /*
   * Agregar Padre
   */
  const [ModalPadre, setModalPadre] = useState(false);
  const [IDPadre, setIDPadre] = useState("");
  const [UpdatePadre, setUpdatePadre] = useState(false);
  const [NombrePadre, setNombrePadre] = useState("");
  const [NumeracionPadre, setNumeracionPadre] = useState("");
  const [NivelPadre, setNivelPadre] = useState(1);
  const [GrupoPadre, setGrupoPadre] = useState("");
  /*
   * Agregar Subpadre
   */
  const [ModalSub, setModalSub] = useState(false);
  const [NombreSub, setNombreSub] = useState("");
  /*
   * Agregar hijo
   */
  const [NomPadre, setNomPadre] = useState("");
  const [modalHijo, setmodalHijo] = useState(false);
  const [NombreVari, setNombreVari] = useState("");
  const [NumeracionVari, setNumeracionVari] = useState("");
  const [Cantidad, setCantidad] = useState(0);
  const [Unidades, setUnidades] = useState("");
  const [X, setX] = useState(0);
  const [MostrarVariable, setMostrarVariable] = useState(false);
  const [IsChecked, setIsChecked] = useState(true);
  const [Fecha, setFecha] = useState("");
  const [Opcion, setOpcion] = useState([]);
  const [Cuantos, setCuantos] = useState(0);
  const [Costo, setCosto] = useState(0);
  const [Subtota, setSubtota] = useState(0);
  const [Iva, setIva] = useState(0);
  const [Total, setTotal] = useState(0);

  /*
   * Estados Fecha
   */
  const [ModalFecha, setModalFecha] = useState(false);
  const [IDFecha, setIDFecha] = useState("");

  /*
   * Eliminar Cuenta
   */
  const [ModalEliminar, setModalEliminar] = useState(false);
  const [Cuenta, setCuenta] = useState("");
  const [NomCuenta, setNomCuenta] = useState("");
  const [IDCuenta, setIDCuenta] = useState("");
  const [CuentaTipo, setCuentaTipo] = useState("");

  /*
   * Editar Presupuesto
   */
  const [ModalEditarPresu, setModalEditarPresu] = useState(false);
  const [IdPresupuesto, setIdPresupuesto] = useState("");

  /*
   * Editar Aportacion
   */
  const [ModalAportacion, setModalAportacion] = useState(false);
  const [Monto, setMonto] = useState(0);
  const [IdAportacion, setIdAportacion] = useState("");

  useEffect(() => {
    PresupuestosGetService(id, token);
  }, [PresupuestosGetService]);

  useEffect(() => {
    if (Presupuestos.length >= 0) {
      setResult(processWithGroups(Presupuestos, initialDataState));
      dispatch(hideLoader());
      dispatch(setTitle(""));
    }
  }, [Presupuestos]);

  useEffect(() => {
    dispatch(setTitle("Descargando presupuestos"));
    dispatch(showLoader());
    PadresMontoGetService(id, token);
  }, [PadresMontoGetService]);

  const returnChildren = (e, type = null) => {
    const nivel = type ? e.nivel_id + 1 : e.nivel_id;
    if (nivel === 2) {
      setChildren([]);
    }

    const children = Padre;
    if (nivel === 3) {
      setChildren(children);
    } else if (nivel === 4) {
      const children = Padre[0].children;
      setChildren(children);
    } else if (nivel === 5) {
      const children = Padre[0].children[0].children;
      setChildren(children);
    } else if (nivel === 6) {
      const children = Padre[0].children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 7) {
      const children = Padre[0].children[0].children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 8) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 9) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children;
      setChildren(children);
    } else if (nivel === 10) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children;
      setChildren(children);
    } else if (nivel === 11) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 12) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 13) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 14) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 15) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children[0]
          .children[0].children;
      setChildren(children);
    } else if (nivel === 16) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 17) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 18) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 19) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 20) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children;
      setChildren(children);
    }
    array_titles.pop();
  };

  const retornarNivel = (a) => {
    if (a.nivel_id === 2) {
      setarray_titles((array) => [...array, a]);
      setPadre([a]);
      const numeros = SumatoriaHijos(a);
      setChildren(numeros.children);
    } else {
      setarray_titles((array) => [...array, a]);
      const numeros = SumatoriaHijos(a);
      setChildren(numeros.children);
    }
  };

  const dataStateChange = (event) => {
    const newDataState = processWithGroups(Presupuestos, event.dataState);
    setResult(newDataState);
    setDataState(event.dataState);
  };

  const expandChange = (event) => {
    const item = event.dataItem;

    if (item.groupId) {
      const newCollapsedIds = !event.value
        ? [...collapsedState, item.groupId]
        : collapsedState.filter((groupId) => groupId !== item.groupId);
      setCollapsedState(newCollapsedIds);
    }
  };

  const formatNumber = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  const cellRender = (tdElement, cellProps) => {
    if (cellProps.rowType === "groupFooter") {
      if (cellProps.field === "monto") {
        return (
          <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            Subtotal:{" "}
            {"$" + formatNumber(cellProps.dataItem.aggregates.monto.sum)}
          </td>
        );
      } else if (cellProps.field === "iva") {
        return (
          <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            IVA: {"$" + formatNumber(cellProps.dataItem.aggregates.iva.sum)}
          </td>
        );
      } else if (cellProps.field === "total") {
        return (
          <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            Total: {"$" + formatNumber(cellProps.dataItem.aggregates.total.sum)}
          </td>
        );
      }
    }

    return tdElement;
  };

  const newData = setExpandedState({
    data: result.data,
    collapsedIds: collapsedState,
  });

  const Sumatoria = (e) => {
    for (let i = 0; i < e.children.length; i++) {
      const obtenerhijo = e.children[i];
      if (obtenerhijo.subpadre == "Si") {
        const subtotalHijo = obtenerhijo.children.reduce(
          (acumulador, currenci) =>
            currenci.subpadre == "No"
              ? acumulador + parseFloat(currenci.subtotal)
              : acumulador,
          0
        );
        const ivaHijo = obtenerhijo.children.reduce(
          (acumulador, currenci) =>
            currenci.subpadre == "No"
              ? acumulador + parseFloat(currenci.iva)
              : acumulador,
          0
        );
        const totalHijo = obtenerhijo.children.reduce(
          (acumulador, currenci) =>
            currenci.subpadre == "No"
              ? acumulador + parseFloat(currenci.total)
              : acumulador,
          0
        );
        obtenerhijo.subtotal = subtotalHijo;
        obtenerhijo.iva = ivaHijo;
        obtenerhijo.total = totalHijo;
      }
    }
    setChildren(e.children);
    setNomPadre(e.Nombre);
  };

  const SumatoriaHijos = (e) => {
    for (let i = 0; i < e.children.length; i++) {
      const obtenerhijo = e.children[i];
      if (obtenerhijo.subpadre == "Si") {
        const subtotalHijo = obtenerhijo.children.reduce(
          (acumulador, currenci) =>
            currenci.subpadre == "No"
              ? acumulador + parseFloat(currenci.subtotal)
              : acumulador,
          0
        );
        const ivaHijo = obtenerhijo.children.reduce(
          (acumulador, currenci) =>
            currenci.subpadre == "No"
              ? acumulador + parseFloat(currenci.iva)
              : acumulador,
          0
        );
        const totalHijo = obtenerhijo.children.reduce(
          (acumulador, currenci) =>
            currenci.subpadre == "No"
              ? acumulador + parseFloat(currenci.total)
              : acumulador,
          0
        );
        obtenerhijo.subtotal = subtotalHijo;
        obtenerhijo.iva = ivaHijo;
        obtenerhijo.total = totalHijo;
      }
    }
    return e;
  };

  const togglePadre = () => {
    setModalPadre(!ModalPadre);
    setUpdatePadre(false);
    setIDPadre("");
    setNombrePadre("");
    setNumeracionPadre("");
    setNivelPadre("");
    setGrupoPadre("");
  };

  const toggleSubPadre = () => {
    setModalSub(!ModalSub);
    setPadreID("");
    setHijoID("");
    setNombreSub("");
    setNivelID("");
  };

  const toggleHijo = () => {
    setmodalHijo(!modalHijo);
    setPadreID("");
    setHijoID("");
    setNombreH("");
    setNivelID("");
    setVariableID("");
    setCantidad("");
    setUnidades("");
    setX("");
    setMostrarVariable(false);
    setNombreVari("");
    setNumeracionVari("");
    setIsChecked(true);
    setFecha("");
    setOpcion("");
    setCuantos("");
    setCosto("");
    setSubtota("");
    setIva("");
    setTotal("");
  };

  const toggleFecha = () => {
    setModalFecha(!ModalFecha);
    setIDFecha("");
  };

  const toggleEliminar = () => {
    setModalEliminar(!ModalEliminar);
    setCuenta("");
    setNomCuenta("");
    setCuentaTipo("");
    setIDCuenta("");
  };

  const toggleEditar = () => {
    setModalEditarCampo(!ModalEditarCampo);
    setCampo("");
    setValorCampo("");
    setIDCampo("");
    setTipo("");
  };

  const toggleEditarPre = () => {
    setModalEditarPresu(!ModalEditarPresu);
  };

  const toggleAportacion = () => {
    setModalAportacion(!ModalAportacion);
    setMonto(0);
    setIdAportacion("");
  };

  const pageChange = (event) => {
    setPage(event.page);
  };

  const [pageSizeValue, setPageSizeValue] = React.useState();
  const pageChanges = (event) => {
    const targetEvent = event.page.take;
    const take = isNaN(targetEvent) ? 100 : 10;
    if (take !== event.page.take) {
      setPageSizeValue(take === 100 ? "All" : take);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, success]);

  const fechas = (fecha) => {
    let meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    let a = fecha.substr(0, 4);
    let m = fecha.substr(5, 2);
    let d = fecha.substr(8);
    return d + " de " + meses[m - 1] + " de " + a;
  };

  const generateExcel = () => {
    let subtotal = 0;
    let iva = 0;
    let total = 0;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");
    // agregar texto en la celda B4 y unir las celdas B4 a B5
    worksheet.getColumn("C").width = 30;

    // tamaño de la fuente
    worksheet.getCell("C1").font = {size: 20};
    worksheet.getCell("C1").value = Proyecto.nombre;
    worksheet.getCell("C1").alignment = {horizontal: "center"};

    worksheet.getCell("C2").value = "Directora";
    worksheet.getCell("C3").value = "Productora responsable";
    worksheet.getCell("C4").value = "Costo Final del Proyecto";
    worksheet.getCell("C5").value = "Formato de Filmación";
    worksheet.getCell("C6").value = "Locaciones";
    worksheet.getCell("C7").value = "Fecha de copia final";
    worksheet.getColumn("D").width = 40;
    worksheet.getCell("D2").value = Proyecto.director_de_produccion;
    worksheet.getCell("D3").value = Proyecto.productor;
    worksheet.getCell("D4").value = formatNumber(Proyecto.costo_final);
    worksheet.getCell("D5").value = Proyecto.formato;
    worksheet.getCell("D6").value = Proyecto.locaciones;
    worksheet.getCell("D7").value = fechas(Proyecto.fecha_copia_final);
    worksheet.getCell("D8").value = "";

    // agergar borde negro a addRow
    worksheet.addRow([
      "Cta.",
      "Sub ctas.",
      "Categoría",
      "Cant.",
      "Unidad",
      "X",
      "Precio",
      "Importe",
      "IVA",
      "Subtotal",
      "Total",
      "Total x cuenta",
    ]).height = 30;

    // agregar border a tabla

    Presupuestos.map((item) => {
      worksheet.addRow([
        item["Cuenta presupuestal"],
        "",
        item["Nombre"],
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        formatNumber(item["monto"]),
      ]).font = {bold: true};

      if (item.children.length > 0) {
        item.children.map((subitem) => {
          subtotal = subtotal + subitem["subtotal"];
          iva = iva + subitem["iva"];
          total =  parseFloat(total)  +  parseFloat(subitem["total"]);
          worksheet.addRow([
            item["Cuenta presupuestal"],
            subitem["cuenta_presupuental"],
            subitem["Nombre"],
            subitem["cantidad_veces_pagos"],
            subitem["unidad"],
            subitem["x_cuantas_veces"],
            formatNumber(subitem["costo_unitario"]),
            formatNumber(subitem["costo_unitario"]),
            formatNumber(subitem["iva"]),
            formatNumber(subitem["subtotal"]),
            formatNumber(subitem["total"]),
            "",
          ]);
          subitem.children.map((subitem2) => {
            subtotal = subtotal + subitem2["subtotal"];
            iva = iva + subitem2["iva"];
            total = parseFloat(total) + parseFloat(subitem2["total"]);
            worksheet.addRow([
              subitem["cuenta_presupuental"],
              subitem2["cuenta_presupuental"],
              subitem2["Nombre"],
              subitem2["cantidad_veces_pagos"],
              subitem2["unidad"],
              subitem2["x_cuantas_veces"],
              formatNumber(subitem2["costo_unitario"]),
              formatNumber(subitem2["costo_unitario"]),
              formatNumber(subitem2["iva"]),
              formatNumber(subitem2["subtotal"]),
              formatNumber(subitem2["total"]),
              "",
            ]);
          });
        });
      }
      // }
    });

    // suma total de la columna total
    worksheet.addRow([
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      Subtotal,
      formatNumber(Total_),
    ]).font = {bold: true};

    // Establecer estilos para el encabezado
    worksheet.getRow(9).font = {bold: true};
    // agrgar borde a getRow(9)
    worksheet.getRow(9).border = {
      top: {style: "thin"},
      left: {style: "thin"},
      bottom: {style: "thin"},
      right: {style: "thin"},
    };
    // Guardar el archivo Excel
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "example.xlsx";
      a.click();
      URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      {ModalPadre && (
        <ModaladdPadre
          ModalPadre={ModalPadre}
          togglePadre={togglePadre}
          NombrePadre={NombrePadre}
          NumeracionPadre={NumeracionPadre}
          NivelPadre={NivelPadre}
          GrupoPadre={GrupoPadre}
          UpdatePadre={UpdatePadre}
          IDPadre={IDPadre}
          id={id}
          token={token}
          setNombrePadre={setNombrePadre}
          setNumeracionPadre={setNumeracionPadre}
          setNivelPadre={setNivelPadre}
          setGrupoPadre={setGrupoPadre}
          PresupuestosGetService={PresupuestosGetService}
          PadresMontoGetService={PadresMontoGetService}
        />
      )}

      {ModalSub && (
        <ModalSubpadre
          ModalSub={ModalSub}
          toggleSubPadre={toggleSubPadre}
          PadreID={PadreID}
          HijoID={HijoID}
          NombreSub={NombreSub}
          NivelID={NivelID}
          token={token}
          id={id}
          setPadreID={setPadreID}
          setHijoID={setHijoID}
          setNombreSub={setNombreSub}
          setNivelID={setNivelID}
          PresupuestosGetService={PresupuestosGetService}
          PadresMontoGetService={PadresMontoGetService}
        />
      )}

      {modalHijo && (
        <ModalHijo
          modalHijo={modalHijo}
          toggleHijo={toggleHijo}
          PadreID={PadreID}
          HijoID={HijoID}
          NombreH={NombreH}
          NivelID={NivelID}
          VariableID={VariableID}
          Cantidad={Cantidad}
          Unidades={Unidades}
          X={X}
          MostrarVariable={MostrarVariable}
          NombreVari={NombreVari}
          NumeracionVari={NumeracionVari}
          IsChecked={IsChecked}
          Fecha={Fecha}
          Opcion={Opcion}
          Cuantos={Cuantos}
          Costo={Costo}
          Subtota={Subtota}
          Iva={Iva}
          Total={Total}
          token={token}
          id={id}
          cliente_id={cliente_id}
          setPadreID={setPadreID}
          setHijoID={setHijoID}
          setNombreH={setNombreH}
          setNivelID={setNivelID}
          setVariableID={setVariableID}
          setCantidad={setCantidad}
          setX={setX}
          setUnidades={setUnidades}
          setMostrarVariable={setMostrarVariable}
          setNombreVari={setNombreVari}
          setNumeracionVari={setNumeracionVari}
          setIsChecked={setIsChecked}
          setFecha={setFecha}
          setOpcion={setOpcion}
          setCuantos={setCuantos}
          setCosto={setCosto}
          setSubtota={setSubtota}
          setIva={setIva}
          setTotal={setTotal}
          PresupuestosGetService={PresupuestosGetService}
          PadresMontoGetService={PadresMontoGetService}
        />
      )}

      {ModalFecha && (
        <ModalFechas
          ModalFecha={ModalFecha}
          toggleFecha={toggleFecha}
          IDFecha={IDFecha}
          token={token}
          PresupuestosGetService={PresupuestosGetService}
        />
      )}

      {ModalEliminar && (
        <ModalElim
          ModalEliminar={ModalEliminar}
          toggleEliminar={toggleEliminar}
          IDCuenta={IDCuenta}
          Cuenta={Cuenta}
          NomCuenta={NomCuenta}
          CuentaTipo={CuentaTipo}
          token={token}
          id={id}
          PresupuestosGetService={PresupuestosGetService}
        />
      )}
      {ModalEditarCampo && (
        <ModalEditar
          ModalEditarCampo={ModalEditarCampo}
          toggleEditar={toggleEditar}
          Campo={Campo}
          ValorCampo={ValorCampo}
          IDCampo={IDCampo}
          Tipo={Tipo}
          id={id}
          token={token}
          setCampo={setCampo}
          setValorCampo={setValorCampo}
          setIDCampo={setIDCampo}
          setTipo={setTipo}
          PresupuestosGetService={PresupuestosGetService}
        />
      )}
      {ModalEditarPresu && (
        <ModalEditarPre
          ModalEditarPresu={ModalEditarPresu}
          toggleEditarPre={toggleEditarPre}
          IdPresupuesto={IdPresupuesto}
          id={id}
          token={token}
          cliente_id={cliente_id}
          PresupuestosGetService={PresupuestosGetService}
          PadresMontoGetService={PadresMontoGetService}
        />
      )}

      {ModalAportacion && (
        <ModalAddAportacion
          ModalAportacion={ModalAportacion}
          toggleAportacion={toggleAportacion}
          Monto={Monto}
          setMonto={setMonto}
          IdAportacion={IdAportacion}
          id={id}
          token={token}
          PresupuestosGetService={PresupuestosGetService}
        />
      )}

      <section style={{paddingTop: 10, paddingBottom: 50}}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Presupuestos</h2>
            </div>
            {Children.length === 0 ? (
              <>
                <div className="col-md-6 text-end">
                  <a className="btn btn-info" onClick={togglePadre}>
                    <i className="mdi mdi-plus"></i>Agregar cuenta Padre
                  </a>
                  <a className="btn btn-success" onClick={generateExcel}>
                    <i className="mdi mdi-file-excel"></i> Exportar Presupuesto
                  </a>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <br />

          <div className="row">
            <div className="col-md-12">
              {Children.length > 0 ? (
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <button
                        onClick={() => {
                          setChildren([]);
                        }}
                        className="btn text-uppercase"
                      >
                        {NomPadre}
                      </button>
                    </li>
                    {array_titles.map((a) => (
                      <li key={a.nombre} className="breadcrumb-item">
                        <button
                          className="btn text-uppercase"
                          onClick={() => returnChildren(a, "nivel")}
                        >
                          {a.nombre}
                        </button>
                      </li>
                    ))}
                  </ol>
                </nav>
              ) : (
                ""
              )}
            </div>
          </div>
          {Children.length === 0 ? (
            <div className="row">
              <div className="col-md-12">
                <Grid
                  style={{
                    height: "720px",
                  }}
                  resizable={true}
                  reorderable={true}
                  filterable={true}
                  filter={filter}
                  onFilterChange={(e) => setFilter(e.filter)}
                  sortable={true}
                  pageable={{
                    pageSizes: ["All"],
                    pageSizeValue: pageSizeValue,
                  }}
                  groupable={{
                    footer: "visible",
                  }}
                  data={filterBy(
                    newData.slice(page.skip, page.take + page.skip),
                    filter
                  )}
                  //data={newData}
                  skip={page.skip}
                  take={page.take}
                  onPageChange={pageChanges}
                  onDataStateChange={dataStateChange}
                  {...dataState}
                  onExpandChange={expandChange}
                  expandField="expanded"
                  cellRender={cellRender}
                >
                  <Column
                    field="Cuenta presupuestal"
                    filter={"text"}
                    width={160}
                    cell={(a) => {
                      if (!a.dataItem.items) {
                        let numeracion = a.dataItem["Cuenta presupuestal"];
                        let id = a.dataItem.id;
                        return (
                          <td
                            style={{
                              marginTop: 10,
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setCampo("Cuenta presupuestal");
                              setValorCampo(numeracion);
                              setIDCampo(id);
                              setTipo("padre");
                              setModalEditarCampo(true);
                            }}
                          >
                            <p>{numeracion}</p>
                          </td>
                        );
                      } else {
                        return (
                          <td style={{marginTop: 10, textAlign: "center"}}></td>
                        );
                      }
                    }}
                  />
                  <Column
                    field="Nombre de la cuenta"
                    filter={"text"}
                    width={228}
                    cell={(a) => {
                      if (!a.dataItem.items) {
                        let nombre = a.dataItem["Nombre"];
                        let id = a.dataItem.id;
                        return (
                          <td
                            style={{
                              marginTop: 10,
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setCampo("Nombre");
                              setValorCampo(nombre);
                              setIDCampo(id);
                              setTipo("padre");
                              setModalEditarCampo(true);
                            }}
                          >
                            <p>{nombre}</p>
                          </td>
                        );
                      } else {
                        return (
                          <td style={{marginTop: 10, textAlign: "center"}}></td>
                        );
                      }
                    }}
                  />
                  <Column
                    field="monto"
                    title="Subtotal"
                    filter={false}
                    filterable={false}
                    format="{0:c}"
                    width={160}
                  />
                  <Column
                    field="iva"
                    title="IVA"
                    filter={false}
                    filterable={false}
                    format="{0:c}"
                    width={160}
                  />
                  <Column
                    field="total"
                    title="Total"
                    filter={false}
                    filterable={false}
                    format="{0:c}"
                    width={160}
                  />
                  <Column
                    field="Subpadre"
                    filter={false}
                    filterable={false}
                    width={90}
                    cell={(a) => {
                      if (!a.dataItem.items) {
                        let id = a.dataItem.id;
                        let nivel_id = a.dataItem.nivel_id;
                        return (
                          <td style={{marginTop: 10, textAlign: "center"}}>
                            <button
                              className="btn btn-info btn-sm btn-icon-text"
                              onClick={() => {
                                setPadreID(id);
                                setHijoID(null);
                                setNivelID(nivel_id);
                                setModalSub(true);
                              }}
                            >
                              <i className="mdi mdi-plus-box"></i>
                            </button>
                          </td>
                        );
                      } else {
                        return (
                          <td style={{marginTop: 10, textAlign: "center"}}></td>
                        );
                      }
                    }}
                  />
                  <Column
                    field="Hijos"
                    filter={false}
                    filterable={false}
                    width={60}
                    cell={(e) => {
                      if (!e.dataItem.items) {
                        let id = e.dataItem.id;
                        let nivel_id = e.dataItem.nivel_id;
                        return (
                          <td style={{marginTop: 10, textAlign: "center"}}>
                            <button
                              className="btn btn-primary btn-sm btn-icon-text"
                              onClick={() => {
                                setPadreID(id);
                                setHijoID(null);
                                setNivelID(nivel_id);
                                setmodalHijo(true);
                              }}
                            >
                              <i className="mdi mdi-plus-box"></i>
                            </button>
                          </td>
                        );
                      } else {
                        return (
                          <td style={{marginTop: 10, textAlign: "center"}}></td>
                        );
                      }
                    }}
                  />
                  <Column
                    field=""
                    filter={false}
                    filterable={false}
                    width={80}
                    cell={(o) => {
                      if (!o.dataItem.items) {
                        let index = o.dataItem;
                        return (
                          <td style={{marginTop: 10, textAlign: "center"}}>
                            {index.children.length !== 0 ? (
                              <button
                                className="btn btn-success btn-sm btn-icon-text"
                                onClick={() => {
                                  Sumatoria(index);
                                }}
                              >
                                <i className="mdi mdi-arrow-right-bold"></i>
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        );
                      } else {
                        return (
                          <td style={{marginTop: 10, textAlign: "center"}}></td>
                        );
                      }
                    }}
                  />
                  {user_rol === "super administrador" ||
                  user_rol === "cliente" ? (
                    <Column
                      field=""
                      filter={false}
                      filterable={false}
                      width={60}
                      cell={(e) => {
                        if (!e.dataItem.items) {
                          let numeracion = e.dataItem.numeracion;
                          let id = e.dataItem.id;
                          let nombre = e.dataItem.nombre;
                          return (
                            <td style={{marginTop: 10, textAlign: "center"}}>
                              <button
                                className="btn btn-danger btn-sm btn-icon-text"
                                onClick={() => {
                                  setCuenta(numeracion);
                                  setNomCuenta(nombre);
                                  setIDCuenta(id);
                                  setCuentaTipo("padre");
                                  setModalEliminar(true);
                                }}
                              >
                                <i className="mdi mdi-delete-forever"></i>
                              </button>
                            </td>
                          );
                        } else {
                          return (
                            <td
                              style={{marginTop: 10, textAlign: "center"}}
                            ></td>
                          );
                        }
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              </div>
            </div>
          ) : (
            ""
          )}
          {Children.length > 0 ? (
            <Grid
              sortable={true}
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
              filterable={true}
              filter={filter}
              onFilterChange={(e) => setFilter(e.filter)}
              pageable={true}
              data={filterBy(
                Children.slice(page.skip, page.take + page.skip),
                filter
              )}
              skip={page.skip}
              take={page.take}
              total={Children.length}
              onPageChange={pageChange}
              style={{
                height: "520px",
              }}
            >
              <GridNoRecords>No hay informacion disponible</GridNoRecords>
              <Column
                field="cuenta_presupuental"
                title="Cuenta presupuental"
                width={155}
              />
              <Column
                field="Nombre"
                filter={"text"}
                width={200}
                cell={(a) => {
                  if (!a.dataItem.items) {
                    let nombre = a.dataItem["Nombre"];
                    let id = a.dataItem.id;
                    return (
                      <td
                        style={{
                          marginTop: 10,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setCampo("Nombre");
                          setValorCampo(nombre);
                          setIDCampo(id);
                          setTipo("hijo");
                          setModalEditarCampo(true);
                        }}
                      >
                        <p>{nombre}</p>
                      </td>
                    );
                  } else {
                    return (
                      <td style={{marginTop: 10, textAlign: "center"}}></td>
                    );
                  }
                }}
              />
              <Column
                field="padre_nombre"
                title="Cuenta Raiz"
                filter="text"
                width={200}
              />
              <Column
                field="nombre_hijo"
                title="Cuenta Padre"
                filter="text"
                width={200}
              />
              <Column
                field="Cantidad"
                filterable={false}
                width={85}
                cell={(a) => {
                  if (!a.dataItem.items) {
                    let cantidad = a.dataItem["Cantidad"];
                    let id = a.dataItem.id;
                    return (
                      <td style={{textAlign: "center"}}>
                        <p
                          style={{marginTop: 15}}
                          onClick={() => {
                            setCampo("Cantidad");
                            setValorCampo(cantidad);
                            setIDCampo(id);
                            setTipo("hijo");
                            setModalEditarCampo(true);
                          }}
                        >
                          {cantidad}
                        </p>
                      </td>
                    );
                  } else {
                    return (
                      <td style={{marginTop: 10, textAlign: "center"}}></td>
                    );
                  }
                }}
              />
              <Column
                field="Unidad"
                filterable={false}
                width={85}
                cell={(a) => {
                  if (!a.dataItem.items) {
                    let unidad = a.dataItem["Unidad"];
                    let id = a.dataItem.id;
                    return (
                      <td style={{textAlign: "center"}}>
                        <p
                          style={{marginTop: 15}}
                          onClick={() => {
                            setCampo("Unidad");
                            setValorCampo(unidad);
                            setIDCampo(id);
                            setTipo("hijo");
                            setModalEditarCampo(true);
                          }}
                        >
                          {unidad}
                        </p>
                      </td>
                    );
                  } else {
                    return (
                      <td style={{marginTop: 10, textAlign: "center"}}></td>
                    );
                  }
                }}
              />
              <Column
                field="X"
                filterable={false}
                width={85}
                cell={(a) => {
                  if (!a.dataItem.items) {
                    let x = a.dataItem["X"];
                    let id = a.dataItem.id;
                    return (
                      <td style={{textAlign: "center"}}>
                        <p
                          style={{marginTop: 15}}
                          onClick={() => {
                            setCampo("X");
                            setValorCampo(x);
                            setIDCampo(id);
                            setTipo("hijo");
                            setModalEditarCampo(true);
                          }}
                        >
                          {x}
                        </p>
                      </td>
                    );
                  } else {
                    return (
                      <td style={{marginTop: 10, textAlign: "center"}}></td>
                    );
                  }
                }}
              />

              <Column
                field="subtotal"
                title="Subtotal"
                filter="text"
                format="{0:c}"
                width={160}
              />
              <Column
                field="iva"
                title="IVA"
                filter="text"
                format="{0:c}"
                width={160}
              />
              <Column
                field="total"
                title="Total"
                filter="text"
                format="{0:c}"
                width={160}
              />
              <Column
                field="Subpadre"
                filter={false}
                filterable={false}
                cell={(e) => {
                  let subpadre = e.dataItem.subpadre;
                  return (
                    <td style={{marginTop: 10, textAlign: "center"}}>
                      <div
                        className={
                          subpadre == "Si"
                            ? "badge bg-info text-wrap"
                            : "badge bg-secondary text-wrap"
                        }
                      >
                        {subpadre}
                      </div>
                    </td>
                  );
                }}
              />
              <Column
                field="Fechas"
                filter={false}
                filterable={false}
                width={80}
                cell={(e) => {
                  let id = e.dataItem.id;
                  let subpadre = e.dataItem.subpadre;
                  return (
                    <td style={{marginTop: 10, textAlign: "center"}}>
                      {subpadre == "No" ? (
                        <button
                          className="btn btn-secondary btn-sm btn-icon-text"
                          onClick={() => {
                            setIDFecha(id);
                            setModalFecha(true);
                          }}
                        >
                          <i className="mdi mdi-calendar"></i>
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  );
                }}
              />
              <Column
                field="Editar"
                filter={false}
                filterable={false}
                width={69}
                cell={(e) => {
                  let id = e.dataItem.id;
                  let subpadre = e.dataItem.subpadre;
                  return (
                    <td style={{marginTop: 10, textAlign: "center"}}>
                      {subpadre == "No" ? (
                        <button
                          className="btn btn-warning btn-sm btn-icon-text"
                          onClick={() => {
                            setIdPresupuesto(id);
                            setModalEditarPresu(true);
                          }}
                        >
                          <i className="mdi mdi-pencil"></i>
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  );
                }}
              />
              <Column
                field="Aportaciones"
                filter={false}
                filterable={false}
                width={150}
                cell={(e) => {
                  let id = e.dataItem.id;
                  let subpadre = e.dataItem.subpadre;
                  return (
                    <td style={{marginTop: 10, textAlign: "center"}}>
                      {subpadre == "No" ? (
                        <button
                          className="btn btn-dark btn-sm btn-icon-text"
                          onClick={() => {
                            setIdAportacion(id);
                            setModalAportacion(true);
                          }}
                        >
                          <i className="mdi mdi-plus"></i> Aportacion
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  );
                }}
              />
              <Column
                field="Subpadre"
                filter={false}
                filterable={false}
                width={90}
                cell={(e) => {
                  let presupuestos_padre_id = e.dataItem.presupuestos_padre_id;
                  let id = e.dataItem.id;
                  let nivel_id = e.dataItem.nivel_id;
                  return (
                    <td style={{marginTop: 10, textAlign: "center"}}>
                      <button
                        className="btn btn-info btn-sm btn-icon-text"
                        onClick={() => {
                          setPadreID(presupuestos_padre_id);
                          setHijoID(id);
                          setNivelID(nivel_id);
                          setModalSub(true);
                        }}
                      >
                        <i className="mdi mdi-plus-box"></i>
                      </button>
                    </td>
                  );
                }}
              />
              <Column
                field="Hijos"
                filter={false}
                filterable={false}
                width={65}
                cell={(e) => {
                  let presupuestos_padre_id = e.dataItem.presupuestos_padre_id;
                  let id = e.dataItem.id;
                  let nivel_id = e.dataItem.nivel_id;
                  return (
                    <td style={{marginTop: 10, textAlign: "center"}}>
                      <button
                        className="btn btn-primary btn-sm btn-icon-text"
                        onClick={() => {
                          setPadreID(presupuestos_padre_id);
                          setHijoID(id);
                          setNivelID(nivel_id);
                          setmodalHijo(true);
                        }}
                      >
                        <i className="mdi mdi-plus-box"></i>
                      </button>
                    </td>
                  );
                }}
              />
              <Column
                field="Ver Hijos"
                filter={false}
                filterable={false}
                width={90}
                cell={(e) => {
                  let index = e.dataItem;
                  return (
                    <td style={{marginTop: 10, textAlign: "center"}}>
                      {index.children.length !== 0 ? (
                        <button
                          className="btn btn-success btn-sm  btn-icon-text"
                          onClick={() => {
                            retornarNivel(index);
                          }}
                        >
                          <i className="mdi mdi-arrow-right-bold"></i>
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  );
                }}
              />
              <Column
                field="Regresar"
                filter={false}
                filterable={false}
                width={90}
                cell={(e) => {
                  let index = e.dataItem;
                  return (
                    <td style={{marginTop: 10, textAlign: "center"}}>
                      <button
                        className="btn btn-warning btn-sm  btn-icon-text"
                        onClick={() => {
                          returnChildren(index);
                        }}
                      >
                        <i className="mdi mdi-arrow-left-bold"></i>
                      </button>
                    </td>
                  );
                }}
              />
              {user_rol === "super administrador" || user_rol === "cliente" ? (
                <Column
                  field="Eliminar"
                  filter={false}
                  filterable={false}
                  width={80}
                  cell={(e) => {
                    if (!e.dataItem.items) {
                      let numeracion = e.dataItem.cuenta_presupuental;
                      let id = e.dataItem.id;
                      let nombre = e.dataItem.nombre_hijo;
                      return (
                        <td style={{marginTop: 10, textAlign: "center"}}>
                          <button
                            className="btn btn-danger btn-sm btn-icon-text"
                            onClick={() => {
                              setCuenta(numeracion);
                              setNomCuenta(nombre);
                              setIDCuenta(id);
                              setCuentaTipo("hijo");
                              setModalEliminar(true);
                            }}
                          >
                            <i className="mdi mdi-delete-forever"></i>
                          </button>
                        </td>
                      );
                    } else {
                      return (
                        <td style={{marginTop: 10, textAlign: "center"}}></td>
                      );
                    }
                  }}
                />
              ) : (
                ""
              )}
            </Grid>
          ) : (
            ""
          )}
        </div>
      </section>
    </>
  );
}
