/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import useHijos from "../../../hoock/useHijos";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

// arreglo de productos
const Prducts = [
  {
    id: "1",
    cantidad: "1",
    descripcion: "Contador de producción en set",
    partida: "1003",
    importe: 10000,
  },
  {
    id: "2",
    cantidad: "1",
    descripcion: "Contador de producción en set",
    partida: "1002",
    importe: 20000,
  },
];

export default function ModalAdd({
  ModalFecha,
  toggleFecha,
  IDFecha,
  token,
  PresupuestosGetService,
}) {
  const { success, Fechas, HijosDateGetService } = useHijos();
  let i = 1;

  useEffect(() => {
    if (success == true) {
      PresupuestosGetService(token);
    }
  }, [PresupuestosGetService, success]);

  useEffect(() => {
    if (ModalFecha == true) {
      HijosDateGetService(IDFecha, token);
    }
  }, [IDFecha]);

  const formatNumber = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  const fechas = (fecha) => {
    let meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    let a = fecha.substr(0, 4);
    let m = fecha.substr(5, 2);
    let d = fecha.substr(8);
    return d + " de " + meses[m - 1] + " de " + a;
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    var img = new Image();
    img.src = "/logo-login.jpg";
    //img.src = "/incidencias/images/Logo_Horizontal_ANAM.png";
    doc.addImage(img, "png", 10, 10, 80, 20);
    doc.setFontSize(12);
    doc.text(110, 10, "SUMO CINEMA DIGITAL COLOR, S.A. DE C.V.");
    doc.setFontSize(10);
    doc.text(110, 15, "RFC: SCD110511T22");
    doc.text(
      110,
      20,
      "Campeche 295-A, Col. Hipódromo Condesa, Alcaldía Cuauhtémoc. C.P. 06170",
      { align: "left", lineHeightFactor: 1, maxWidth: 100 }
    );
    doc.text(110, 29, "LOS HERMANOS SALVADOR");
    doc.text(110, 33, "(Producción de Sula Films, S.A. DE C.V.)");
    doc.text(110, 38, "SOLICITUD DE PAGO No. 6");
    doc.text(110, 43, "FECHA DE ELABORACION: 08-09-2021 7:21 PM");

    autoTable(doc, { html: "#my-pagos" });
    autoTable(doc, {
      theme: "grid",
      body: [
        [
          "PERIODO DE PAGO",
          "DEL 26 AL 30 DE ABRIL",
          "SEMANA",
          "4",
          "ETAPA",
          "Rodaje",
        ],
        [
          { content: "FECHA DE PAGO", colSpan: 1 },
          { content: "29-04-202", colSpan: 1 },
          { content: "FORMA DE PAGO", colSpan: 1 },
          { content: "03 - Transferencia electrónica de fondos", colSpan: 3 },
        ],
      ],

      startY: 50,
    });

    autoTable(doc, { html: "#my-departamento" });
    autoTable(doc, {
      theme: "plain",
      body: [
        [
          "DEPARTAMENTO PRESTADOR DEL SERVICIO ",
          "ELYSIAN SYS MEXICO, SA DE CV",
        ],
        ["PAGO A FAVOR DE:", "ELYSIAN SYS MÉXICO S.A. DE C.V."],
        ["R. F. C.", "ESM170628CK1"],
        ["BANCO", "INBURSA"],
        ["CUENTA - CLABE", "036180500422014822"],
      ],

      startY: 73,
    });

    autoTable(doc, { html: "#my-table" });
    autoTable(doc, {
      theme: "striped",
      head: [["CANTIDAD", "DESCRIPCIÓN", "PARTIDA P", "IMPORTE"]],
      body: Prducts.map((product) => {
        return [
          product.cantidad,
          product.descripcion,
          { content: product.partida, styles: { halign: "center" } },
          {
            content: "$" + formatNumber(product.importe),
            styles: { halign: "right" },
          },
        ];
      }),
      foot: [
        [
          "",
          "",
          "SUBTOTAL",
          { content: "$" + formatNumber("12760"), styles: { halign: "right" } },
        ],
        [
          "",
          "",
          "IVA",
          { content: "$" + formatNumber("2552"), styles: { halign: "right" } },
        ],
        [
          "",
          "",
          "TOTAL A PAGAR",
          { content: "$" + formatNumber("15312"), styles: { halign: "right" } },
        ],
        ["", "", "FOLIO FISCAL", "7204a394-cdb2-4352-92a4-3b7b26028341"],
      ],

      startY: 115,
    });

    autoTable(doc, { html: "#my-productor" });
    autoTable(doc, {
      theme: "grid",
      body: [
        ["PRODUCTOR", "DIRECCIÓN DE PRODUCCIÓN", "PRODUCTOR EJECUTIVO"],
        ["Alejandro Sugich", "Paola Méndez", "Julieta Perales"],
      ],
    });

    autoTable(doc, { html: "#my-nota" });
    autoTable(doc, {
      theme: "plain",
      body: [
        [
          "Nota",
          "El pago se hará a la cuenta bancaria indicada en esta solicitud de pago, por lo que se solicita a la empresa proveedora de servicios, que en caso de no ser la misma cuenta bancaria, se indique la cuenta a la que se hará el depósito.",
        ],
      ],
    });

    // doc.text(
    //   15,
    //   200,
    //   "Nota: ",
    //   { align: "left", lineHeightFactor: 1, maxWidth: 180 }
    // );

    // doc.save("demo.pdf");
    window.open(
      doc.output("bloburl"),
      "_blank",
      "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes,top=100,left=250,width=1200,height=800"
    );
  };

  return (
    <>
      <Modal isOpen={ModalFecha} toggle={toggleFecha} size="lg">
        <ModalHeader className="backend_titulo" toggle={toggleFecha}>
          Fecha de pagos
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Fecha</th>
                    <th>Monto</th>
                    <th>Status</th>
                    <th>Tipo</th>
                    <th>Orden Compra</th>
                  </tr>
                </thead>
                <tbody>
                  {Fechas.map(({ id, fecha, monto, status, opcion_pagos }) => {
                    return (
                      <tr className="text-black" key={id}>
                        <td>{i++}</td>
                        <td>{fechas(fecha)}</td>
                        <td>${formatNumber(monto)}</td>
                        <td>
                          <div
                            className={
                              status == "Pendiente"
                                ? "badge bg-warning text-wrap"
                                : "badge bg-success text-wrap"
                            }
                          >
                            {status}
                          </div>
                        </td>
                        <td style={{ textTransform: "uppercase" }}>
                          {opcion_pagos}
                        </td>
                        <td className="text-center">
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => {
                              generatePDF();
                            }}
                          >
                            <i className="mdi mdi-file-pdf-box"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
