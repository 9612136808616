/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import useCliente from "../../hoock/useCliente";
import ModalAdd from "./Modals/ModalAdd";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import { filterBy } from "@progress/kendo-data-query";
import Link from "../../components/Form/Link";
import Title from "../../components/Form/Title";
import { hideLoader, showLoader, setTitle } from "../../reducers/loaderReducer";
import { useDispatch } from "react-redux";
import useLogin from "../../hoock/useLogin";
import Swal from "sweetalert2";

const PageTemplate = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        left: "10px",
      }}
    >
      Page {props.pageNum} of {props.totalPages}
    </div>
  );
};

const initialDataState = {
  skip: 0,
  take: 10,
};

const initialFilter = {
  logic: "and",
  filters: [],
};

const initialSort = [
  // {
  //   field: "modelo",
  //   dir: "desc",
  // },
  // {
  //   field: "serie",
  //   dir: "desc",
  // },
  // {
  //   field: "fecha_venta",
  //   dir: "desc",
  // },
  // {
  //   field: "Venta",
  //   dir: "desc",
  // },
];

export default function Index() {
  const { logout, token, user_rol } = useLogin();
  const dispatch = useDispatch();
  const { message, success, Clientes, ClienteGetService, ClienteDeleteService } = useCliente();
  const [modal, setModal] = useState(false);
  const [Update, setUpdate] = useState(false);
  const [Id, setId] = useState("");
  const [sort, setSort] = React.useState(initialSort);
  const [filter, setFilter] = React.useState(initialFilter);
  const [page, setPage] = React.useState(initialDataState);
  const [form, setform] = useState({
    nombre_comercial: "",
    telefono: "",
    tipo: "",
    RFC: "",
    calle: "",
    exterior: "",
    inerior: "",
    colonia: "",
    municipio: "",
    estado: "",
    cp: "",
    clave: ""
  });

  const toggle = () => {
    setModal(!modal);
    setUpdate(false);
    setId("");
    setform({
      nombre_comercial: "",
      telefono: "",
      tipo: "",
      RFC: "",
      calle: "",
      exterior: "",
      inerior: "",
      colonia: "",
      municipio: "",
      estado: "",
      cp: "",
      clave: ""
    });
  };

  useEffect(() => {
    dispatch(setTitle("Descargando Clientes"));
    dispatch(showLoader());
    ClienteGetService(token);
    return () => { };
  }, []);

  useEffect(() => {
    if (Clientes.length >= 0) {
      dispatch(hideLoader());
      dispatch(setTitle(""));
    }
    return () => { };
  }, [Clientes]);

  useEffect(() => {
    if (success === true) {
      ClienteGetService(token);
    }
  }, [ClienteGetService, success]);

  const pageChange = (event) => {
    setPage(event.page);
  };

  const Eliminar = (e) => {
    const data = new FormData();
    data.append("id", e);
    ClienteDeleteService(data, token);

    Swal.fire({
      title: "Espere por favor...",
      text: "Cargando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      icon: 'question',
      onOpen: () => {
        Swal.showLoading();
      }
    });
  }

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, success])

  return (
    <>
      <ModalAdd
        modal={modal}
        toggle={toggle}
        Update={Update}
        Id={Id}
        token={token}
        ClienteGetService={ClienteGetService}
        form={form}
        setform={setform}
      />
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Title title="Listado de Clientes" className="text-left" />
          </div>
          <div className="col-md-6 text-end">
            <Link
              className="btn btn-info"
              onClick={toggle}
              label="Agregar Clientes"
            />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <Grid
              sortable={true}
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
              filterable={true}
              filter={filter}
              onFilterChange={(e) => setFilter(e.filter)}
              pageable={true}
              data={filterBy(
                Clientes.slice(page.skip, page.take + page.skip),
                filter
              )}
              skip={page.skip}
              take={page.take}
              total={Clientes.length}
              onPageChange={pageChange}
              style={{
                height: "420px",
              }}
            >
              <GridNoRecords>No hay informacion disponible</GridNoRecords>
              <GridColumn
                field="nombre_comercial"
                title="Nombre"
                filter={false}
                filterable={false}
              />
              <GridColumn
                field="telefono"
                title="Telefono"
                filter={false}
                filterable={false}
              />
              <GridColumn
                field="tipo"
                title="Tipo"
                filter={false}
                filterable={false}
              />
              <GridColumn
                field="RFC"
                title="RFC"
                filter={false}
                filterable={false}
              />
              <GridColumn
                field="calle"
                title="Calle"
                filter={false}
                filterable={false}
              />
              <GridColumn
                field="exterior"
                title="Exterior"
                filter={false}
                filterable={false}
              />
              <GridColumn
                field="inerior"
                title="Interior"
                filter={false}
                filterable={false}
              />
              <GridColumn
                field="colonia"
                title="Colonia"
                filter={false}
                filterable={false}
              />
              <GridColumn
                field="municipio"
                title="Municipio"
                filter={false}
                filterable={false}
              />
              <GridColumn
                field="estado"
                title="Estado"
                filter={false}
                filterable={false}
              />
              <GridColumn
                field="cp"
                title="CP"
                filter={false}
                filterable={false}
              />
              <GridColumn
                field="clave"
                title="Clave"
                filter={false}
                filterable={false}
              />

              {
                user_rol === "super administrador" || user_rol === "cliente"
                  ?
                  <GridColumn
                    field="Editar"
                    filter={false}
                    filterable={false}
                    cell={(e) => {
                      let id = e.dataItem.id;
                      let nombre = e.dataItem.nombre_comercial;
                      let telefono = e.dataItem.telefono;
                      let tipo = e.dataItem.tipo;
                      let RFC = e.dataItem.RFC;
                      let calle = e.dataItem.calle;
                      let exterior = e.dataItem.exterior;
                      let inerior = e.dataItem.inerior;
                      let colonia = e.dataItem.colonia;
                      let municipio = e.dataItem.municipio;
                      let estado = e.dataItem.estado;
                      let cp = e.dataItem.cp;
                      let clave = e.dataItem.clave;

                      return (
                        <td style={{ marginTop: 10, textAlign: "center" }}>
                          <button
                            className="btn btn-warning btn-sm"
                            onClick={() => {
                              setId(id);
                              setUpdate(true);
                              setModal(true);
                              setform({
                                nombre_comercial: nombre,
                                telefono: telefono,
                                tipo: tipo,
                                RFC: RFC,
                                calle: calle,
                                exterior: exterior,
                                inerior: inerior,
                                colonia: colonia,
                                municipio: municipio,
                                estado: estado,
                                cp: cp,
                                clave: clave
                              });
                            }}
                          >
                            Editar
                          </button>
                        </td>
                      );
                    }}
                  />
                  :
                  ''
              }

              {
                user_rol === "super administrador" || user_rol === "cliente"
                  ?
                  <GridColumn
                    field="Eliminar"
                    filter={false}
                    filterable={false}
                    cell={(o) => {
                      let id = o.dataItem.id;
                      return (
                        <td style={{ marginTop: 10, textAlign: "center" }}>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => { Eliminar(id) }}
                          >
                            Eliminar
                          </button>
                        </td>
                      );
                    }}
                  />
                  :
                  ''
              }
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
