import React, {useState, useEffect} from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
} from "reactstrap";
import useProveedor from "../../../hoock/useProveedor";
import Select from "react-select";
import Swal from "sweetalert2";

const Tipo = [
  {value: "", label: "Selecciona el tipo de persona"},
  {value: "Fisica", label: "Fisica"},
  {value: "Moral", label: "Moral"},
];

export default function ModalAdd({
  modal,
  toggle,
  Id,
  Update,
  token,
  cliente_id,
  TipoPersona,
  setTipoPersona,
  Nombre,
  setNombre,
  ApellidoP,
  setApellidoP,
  ApellidoM,
  setApellidoM,
  RFC,
  setRFC,
  Correo,
  setCorreo,
  Telefono,
  setTelefono,
  UsoCFDI,
  setUsoCFDI,
  ProveedorGetService,
}) {
  const [SelectedTipo, setSelectedTipo] = useState([]);
  const [SelectedCFDI, setSelectedCFDI] = useState([]);
  const {
    error,
    message,
    loading,
    success,
    CFDI,
    ProveedorCFDIGetService,
    ProveedorAddService,
    ProveedorUpdateService,
  } = useProveedor();

  useEffect(() => {
    if (success === true) {
      ProveedorGetService(cliente_id, token);
    }
  }, [ProveedorGetService, success]);

  useEffect(() => {
    ProveedorCFDIGetService(token);
  }, [ProveedorCFDIGetService]);

  const createUser = () => {
    if (Update) {
      const data = new FormData();
      data.append("id", Id);
      data.append("tipo_persona", TipoPersona);
      data.append("nombre", Nombre);
      data.append("apellido_p", ApellidoP);
      data.append("apellido_m", ApellidoM);
      data.append("RFC", RFC);
      data.append("correo", Correo);
      data.append("telefono", Telefono);
      data.append("cfdi", UsoCFDI);
      data.append("cliente_id", cliente_id);

      ProveedorUpdateService(data, token);

      Swal.fire({
        title: "Actualizando proveedor",
        text: "Loading...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: "question",
        onOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      const data = new FormData();
      data.append("tipo_persona", TipoPersona);
      data.append("nombre", Nombre);
      data.append("apellido_p", ApellidoP);
      data.append("apellido_m", ApellidoM);
      data.append("RFC", RFC);
      data.append("correo", Correo);
      data.append("telefono", Telefono);
      data.append("cfdi", UsoCFDI);
      data.append("cliente_id", cliente_id);

      ProveedorAddService(data, token);

      Swal.fire({
        title: "Registrando proveedor",
        text: "Loading...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: "question",
        onOpen: () => {
          Swal.showLoading();
        },
      });
    }
  };

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
      setTipoPersona("");
      setSelectedTipo("");
      setNombre("");
      setApellidoP("");
      setApellidoM("");
      setRFC("");
      setCorreo("");
      setTelefono("");
      setUsoCFDI("");

      toggle();
    }
  }, [message, success]);

  console.log(UsoCFDI);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader className="backend_titulo" toggle={toggle}>
          Agregar Proveedor
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <Select
                value={SelectedTipo}
                onChange={(e) => {
                  setTipoPersona(e.value);
                  setSelectedTipo(e);
                }}
                options={Tipo}
                placeholder={TipoPersona ? TipoPersona : "Tipo de persona"}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder={
                  TipoPersona === "Fisica" ? "Nombre" : "Razon Social"
                }
                value={Nombre}
                onChange={(e) => setNombre(e.target.value)}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder="Apellido Paterno"
                value={ApellidoP}
                onChange={(e) => setApellidoP(e.target.value)}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder="Apellido Materno"
                value={ApellidoM}
                onChange={(e) => setApellidoM(e.target.value)}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder="RFC"
                value={RFC}
                onChange={(e) => setRFC(e.target.value)}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <Input
                placeholder="Correo Electronico"
                value={Correo}
                onChange={(e) => setCorreo(e.target.value)}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <Input
                type="number"
                placeholder="Teléfono"
                value={Telefono}
                onChange={(e) => setTelefono(e.target.value)}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <Select
                value={SelectedCFDI}
                onChange={(e) => {
                  setUsoCFDI(e.value);
                  setSelectedCFDI(e);
                }}
                options={CFDI}
                placeholder={UsoCFDI ? UsoCFDI : "CFDI"}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="backend_boton" onClick={() => createUser()}>
            Agregar
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
}
