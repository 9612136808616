//const ENDPOINT = "https://fundaciondies.org/api-presupuestos-lciseguros-com/public/api/auth/"

const ENDPOINT = process.env.REACT_APP_API_URL_DEVELOP;

export async function PresupuestosGet(id, token) {
    return fetch(`${ENDPOINT}presupuestos/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function PresupuestosPadreUpdate(data, token) {
    return fetch(`${ENDPOINT}presupuestosPadreUpdate`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
            
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function PadresMontoGet(id, token) {
    return fetch(`${ENDPOINT}PadresMonto/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`

        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function CuentaDelete(data, token) {
    return fetch(`${ENDPOINT}presupuestosDelete`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function PresupuestosAportacionUpdate(data, token) {
    return fetch(`${ENDPOINT}presupuestosAportacionUpdate`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
            
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}