import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, Input } from 'reactstrap';
import useHijos from '../../../hoock/useHijos';
import Subpadre from "../../../components/HOC´s/Subpadre";
import Swal from "sweetalert2";

const templete = {
    NombreSub: "",
    CuentaPre: ""
};


export default function ModalAdd({
    ModalSub,
    toggleSubPadre,
    PadreID,
    HijoID,
    NombreSub,
    NivelID,
    token,
    setNombreSub,
    PresupuestosGetService }) {

    const { loading, error, message, success, CuentaPre, setCuentaPre, PadreCuentaService, HijosCuentaService, HijosSubAddService } = useHijos();
    const [Arreglo, setArreglo] = useState([templete]);
    const [Numerica, setNumerica] = useState(null);

    useEffect(() => {
        if (success === true) {
            PresupuestosGetService(token);
        }
    }, [PresupuestosGetService, success]);

    const NuevaSerie = () => {
        setArreglo([...Arreglo, templete]);
    };

    const guardar = () => {
        const data = new FormData();
        data.append("padre_id", PadreID);
        data.append("hijos_id", HijoID);
        data.append("items", JSON.stringify(Arreglo));
        data.append("nivel_id", parseInt(NivelID) + 1);
        data.append("subpadre", 'Si');
        HijosSubAddService(data, token);

        Swal.fire({
            title: "Registrando cuenta subpadre",
            text: "Loading...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            icon: 'question',
            onOpen: () => {
                Swal.showLoading();
            }
        });
    }

    useEffect(() => {
        if (error) {
            Swal.close();
            Swal.fire({
                icon: "error",
                title: "Error",
                confirmButtonColor: "#3b82f6",
                text: message,
            });
        }
    }, [message, error])

    useEffect(() => {
        if (success) {
            Swal.close();
            Swal.fire({
                icon: "success",
                title: "Exito",
                confirmButtonColor: "#3b82f6",
                text: message,
            });
            setNombreSub('');
            toggleSubPadre();
        }
    }, [message, success])

    return <>
        <Modal isOpen={ModalSub} toggle={toggleSubPadre} size="lg">
            <ModalHeader className="backend_titulo" toggle={toggleSubPadre}>Agregar Hijo</ModalHeader>
            <ModalBody>
                {Arreglo.map((item, index) => {
                    return (
                        <Subpadre
                            key={index}
                            item={item}
                            error={error}
                            success={success}
                            message={message}
                            index={index}
                            token={token}
                            Arreglo={Arreglo}
                            templete={templete}
                            Numerica={Numerica}
                            setArreglo={setArreglo}
                            setNumerica={setNumerica}
                            PadreID={PadreID}
                            HijoID={HijoID}
                        />
                    );
                })}
                {/* <div className="row">
                    <div className="col-md-5">
                        <label>Descripcion:</label>
                       
                            <Input placeholder="Nombre" value={NombreH} onChange={e => setNombreH(e.target.value)} />
                        
                    </div>
                    <div className="col-md-5">
                        <label>Cuenta Presupuestal:</label>
                       
                            <Input placeholder="Cuenta Presupuestal" disabled={true} value={CuentaPre} onChange={e => setCuentaPre(e.target.value)} />
                        
                    </div>
                    <div className="col-md-2">
                        <button className='btn btn-info btn-sm mt-4' style={{ marginRight: 10 }}><i className="mdi mdi-plus-circle"></i></button>
                        <button className='btn btn-danger btn-sm mt-4'><i className="mdi mdi-close-circle"></i></button>
                    </div>
                </div> */}
            </ModalBody>
            <ModalFooter>
                <Button className="backend_boton" onClick={(() => guardar())}>Guardar y Cerrar</Button>
                <Button color="danger" onClick={toggleSubPadre}>Cerrar</Button>
            </ModalFooter>
        </Modal>
    </>
}
