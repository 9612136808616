const ENDPOINT = process.env.REACT_APP_API_URL_DEVELOP;

export async function FechasGetGet(user_id, token) {
    return fetch(`${ENDPOINT}FechasGet/${user_id}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function FechasOrdenAdd(data, token) {
    return fetch(`${ENDPOINT}FechasOrdenAdd`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}