/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
} from "reactstrap";
import Select from "react-select";
import usePadre from "../../../hoock/usePadre";
import Swal from "sweetalert2";

const clasificacion = [
  {value: "Arriba de la linea", label: "Arriba de la linea"},
  {value: "Produccion", label: "Produccion"},
  {value: "Post produccion", label: "Post produccion"},
  {value: "Otros", label: "Otros"},
];

export default function ModalAdd({
  ModalPadre,
  togglePadre,
  NombrePadre,
  NumeracionPadre,
  NivelPadre,
  GrupoPadre,
  UpdatePadre,
  IDPadre,
  id,
  token,
  setNombrePadre,
  setNumeracionPadre,
  setNivelPadre,
  setGrupoPadre,
  PresupuestosGetService,
  PadresMontoGetService,
}) {
  const {error, message, success, PadreAddService, PadreUpdateService} =
    usePadre();
  const [SelectedGrupo, setSelectedGrupo] = useState([]);

  useEffect(() => {
    if (success == true) {
      PresupuestosGetService(id, token);
    }
  }, [PresupuestosGetService, success]);

  useEffect(() => {
    if (success == true) {
      PadresMontoGetService(id, token);
    }
  }, [PadresMontoGetService, success]);

  const createUser = () => {
    if (UpdatePadre) {
      const data = new FormData();
      data.append("id", IDPadre);
      data.append("proyectos_id", id);
      data.append("nombre", NombrePadre);
      data.append("numeracion", NumeracionPadre);
      data.append("grupo", GrupoPadre);

      PadreUpdateService(data, token);

      Swal.fire({
        title: "Actualizando cuenta padre",
        text: "Loading...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: "question",
        onOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      const data = new FormData();
      data.append("proyectos_id", id);
      data.append("nombre", NombrePadre);
      data.append("numeracion", NumeracionPadre);
      data.append("grupo", GrupoPadre);

      PadreAddService(data, token);

      Swal.fire({
        title: "Registrando cuenta padre",
        text: "Loading...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: "question",
        onOpen: () => {
          Swal.showLoading();
        },
      });
    }
  };

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
      setNombrePadre("");
      setNumeracionPadre("");
      setGrupoPadre("");
      setSelectedGrupo("");
      togglePadre();
    }
  }, [message, success]);

  return (
    <>
      <Modal isOpen={ModalPadre} toggle={togglePadre}>
        <ModalHeader className="backend_titulo" toggle={togglePadre}>
          Agregar Subpadre
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <label>Nombre:</label>

              <Input
                placeholder="Nombre"
                value={NombrePadre}
                onChange={(e) => setNombrePadre(e.target.value)}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <label>Cuenta presupuestal:</label>

              <Input
                placeholder="Cuenta presupuestal"
                value={NumeracionPadre}
                onChange={(e) => setNumeracionPadre(e.target.value)}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <label>Calsificacion:</label>
              <Select
                value={SelectedGrupo}
                onChange={(e) => {
                  setGrupoPadre(e.value);
                  setSelectedGrupo(e);
                }}
                options={clasificacion}
                placeholder={GrupoPadre ? GrupoPadre : "Calsificacion"}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="backend_boton" onClick={() => createUser()}>
            Agregar
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
}
