/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
} from "reactstrap";
import Select from "react-select";
import useHijos from "../../hoock/useHijos";

const unidades = [
  { value: "unico", label: "Unico" },
  { value: "semanal", label: "Semanal" },
  { value: "quincenal", label: "Quincenal" },
  { value: "mensual", label: "Mensual" },
  { value: "bimestral", label: "Bimestral" },
  { value: "trimestral", label: "Trimestral" },
  { value: "semestral", label: "Semestral" },
  { value: "anual", label: "Anual" },
];

export default function Ventas({
  item,
  index,
  error,
  success,
  message,
  Arreglo,
  templete,
  Numerica,
  token,
  settemplete,
  setArreglo,
  setNumerica,
  PadreID,
  HijoID,
}) {
  const { CuentaPre, setCuentaPre, PadreCuentaService, HijosCuentaService } =
    useHijos();
  const [SelectedVariable, setSelectedVariable] = useState([]);
  const [SelectedOpcion, setSelectedOpcion] = useState([]);

  console.log(CuentaPre);

  useEffect(() => {
    if (PadreID > 0 && HijoID == null) {
      PadreCuentaService({ id: PadreID, token });
    } else {
      if (HijoID != "") {
        HijosCuentaService({ id: HijoID, token });
      }
    }
  }, [PadreID, HijoID]);

  useEffect(() => {
    if (index == 0) {
      ChangeInfo("", "NombreH");
      ChangeInfo(CuentaPre, "CuentaPre");
      setNumerica(CuentaPre);
    }
  }, [CuentaPre]);

  const ChangeInfo = (value, name) => {
    const newArreglo = Arreglo.map((item, i) => {
      if (i === index) {
        return { ...item, [name]: value };
      } else {
        return item;
      }
    });
    setArreglo(newArreglo);
  };

  const NuevaSerie = () => {
    const separar = Numerica.split(".");
    const decimal = parseInt(separar[separar.length - 1]) + 1;
    separar[separar.length - 1] = decimal;
    const v = separar.toString();
    const valor = v.replace(/,/g, ".");

    setArreglo([
      ...Arreglo,
      {
        NombreH: "",
        CuentaPre: valor,
      },
    ]);
    setNumerica(valor);
  };

  const EliminarSerie = (id) => {
    const items = Arreglo.filter((item, index) => index !== id);
    setArreglo(items);

    const separar = Numerica.split(".");
    const decimal = parseInt(separar[separar.length - 1]) - 1;
    separar[separar.length - 1] = decimal;
    const v = separar.toString();
    const valor = v.replace(/,/g, ".");

    setNumerica(valor);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <label>Descripcion:</label>
          
            <Input
              placeholder="Nombre"
              value={item.NombreH}
              onChange={(e) => ChangeInfo(e.target.value, "NombreH")}
            />
          
        </div>
        <div className="col-md-3">
          <label>Cuenta Presupuestal:</label>
          
            <Input
              placeholder="Cuenta Presupuestal"
              disabled={true}
              value={item.CuentaPre}
            />
          
        </div>
        <div className="col-md-3 text-end">
          <button
            className="btn btn-info btn-sm mt-4"
            style={{ marginRight: 10 }}
            onClick={() => NuevaSerie()}
          >
            <i className="mdi mdi-plus-circle"></i>
          </button>
          {index >= 1 ? (
            <button
              className="btn btn-danger btn-sm mt-4"
              onClick={() => EliminarSerie(index)}
            >
              <i className="mdi mdi-close-circle"></i>
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-12 mb-3 mt-3">
          <hr />
        </div>
      </div>
    </>
  );
}
