/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import usePresupuesto from "../../hoock/usePresupuesto";
import { hideLoader, showLoader, setTitle } from "../../reducers/loaderReducer";
import { useDispatch } from "react-redux";
import useLogin from "../../hoock/useLogin";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

export default function Index() {
  const { token } = useLogin();
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    message,
    success,
    Presupuestos,
    Subtotal,
    Iva_,
    Total_,
    Pagado_,
    PresupuestosGetService,
    PadresMontoGetService,
  } = usePresupuesto();

  useEffect(() => {
    if (success === true) {
      PresupuestosGetService(id, token);
      //setActivarCampoNu(null);
      //setActivarCampoN(null);
    }
  }, [PresupuestosGetService, success]);

  useEffect(() => {
    PresupuestosGetService(id, token);
  }, [PresupuestosGetService]);

  useEffect(() => {
    if (Presupuestos.length >= 0) {
      dispatch(hideLoader());
      dispatch(setTitle(""));
    }
  }, [Presupuestos]);

  useEffect(() => {
    dispatch(setTitle("Descargando presupuestos"));
    dispatch(showLoader());
    PadresMontoGetService(id, token);
  }, [PadresMontoGetService]);

  const formatNumber = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, success]);

  return (
    <>
      <section style={{ paddingTop: 10, paddingBottom: 50 }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to={'/proyectos'}>Proyectos</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  Dashboard
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h2>Dashboard</h2>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-4 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h5>Subtotal presupuestado</h5>
                  <div className="row">
                    <div className="col-8 col-sm-12 col-xl-8 my-auto">
                      <div className="d-flex d-sm-block d-md-flex align-items-center">
                        <h2 className="mb-0">${formatNumber(Subtotal)}</h2>
                      </div>
                    </div>
                    <div className="col-4 col-sm-12 col-xl-4 text-center text-xl-right">
                      <i className="icon-lg mdi mdi-currency-usd text-primary ml-auto"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h5>IVA presupuestado</h5>
                  <div className="row">
                    <div className="col-8 col-sm-12 col-xl-8 my-auto">
                      <div className="d-flex d-sm-block d-md-flex align-items-center">
                        <h2 className="mb-0">${formatNumber(Iva_)}</h2>
                      </div>
                    </div>
                    <div className="col-4 col-sm-12 col-xl-4 text-center text-xl-right">
                      <i className="icon-lg mdi mdi-currency-usd text-primary ml-auto"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h5>Total presupuestado</h5>
                  <div className="row">
                    <div className="col-8 col-sm-12 col-xl-8 my-auto">
                      <div className="d-flex d-sm-block d-md-flex align-items-center">
                        <h2 className="mb-0">${formatNumber(Total_)}</h2>
                      </div>
                    </div>
                    <div className="col-4 col-sm-12 col-xl-4 text-center text-xl-right">
                      <i className="icon-lg mdi mdi-currency-usd text-primary ml-auto"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-sm-3 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h5>Pagado hasta el momento</h5>
                  <div className="row">
                    <div className="col-8 col-sm-12 col-xl-8 my-auto">
                      <div className="d-flex d-sm-block d-md-flex align-items-center">
                        <h2 className="mb-0">${formatNumber(Pagado_)}</h2>
                      </div>
                    </div>
                    <div className="col-4 col-sm-12 col-xl-4 text-center text-xl-right">
                      <i className="icon-lg mdi mdi-currency-usd text-primary ml-auto"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row">
            <div className="col-sm-4 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h5>Subtotal facturado</h5>
                  <div className="row">
                    <div className="col-8 col-sm-12 col-xl-8 my-auto">
                      <div className="d-flex d-sm-block d-md-flex align-items-center">
                        <h2 className="mb-0">${formatNumber(Pagado_)}</h2>
                      </div>
                    </div>
                    <div className="col-4 col-sm-12 col-xl-4 text-center text-xl-right">
                      <i className="icon-lg mdi mdi-currency-usd text-primary ml-auto"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h5>IVA facturado</h5>
                  <div className="row">
                    <div className="col-8 col-sm-12 col-xl-8 my-auto">
                      <div className="d-flex d-sm-block d-md-flex align-items-center">
                        <h2 className="mb-0">
                          ${formatNumber(Pagado_ * 0.16)}
                        </h2>
                      </div>
                    </div>
                    <div className="col-4 col-sm-12 col-xl-4 text-center text-xl-right">
                      <i className="icon-lg mdi mdi-currency-usd text-primary ml-auto"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h5>Total facturado</h5>
                  <div className="row">
                    <div className="col-8 col-sm-12 col-xl-8 my-auto">
                      <div className="d-flex d-sm-block d-md-flex align-items-center">
                        <h2 className="mb-0">
                          ${formatNumber(Pagado_ * 1.16)}
                        </h2>
                      </div>
                    </div>
                    <div className="col-4 col-sm-12 col-xl-4 text-center text-xl-right">
                      <i className="icon-lg mdi mdi-currency-usd text-primary ml-auto"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
