/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, Input } from 'reactstrap';
import useProveedor from '../../../hoock/useProveedor';
import Swal from "sweetalert2";

export default function ModalDir({
    ModalProv, toggleProveedor,
    token, user_id, id,
    Fotos, setFotos, NombreP, setNombreP, ApellidoPP, setApellidoPP, ApellidoMP, setApellidoMP, TelefonoP, setTelefonoP, CorreoP, setCorreoP,
    RFCP, setRFCP,
    PerfilProveGetService }) {

    const { error, message, success, ProveedorUpdateService } = useProveedor();

    useEffect(() => {
        if (success === true) {
            PerfilProveGetService(user_id, token)
        }
    }, [PerfilProveGetService, success])


    const createUser = () => {
        const data = new FormData();
        data.append("id", id);
        data.append("nombre", NombreP);
        data.append("apellido_p", ApellidoPP);
        data.append("apellido_m", ApellidoMP);
        data.append("correo", CorreoP);
        data.append("RFC", RFCP);
        data.append("telefono", TelefonoP);
  
        ProveedorUpdateService(data, token);
  
        Swal.fire({
          title: "Actualizando proveedor",
          text: "Loading...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          icon: 'question',
          onOpen: () => {
              Swal.showLoading();
          }
      });
    }

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                confirmButtonColor: "#3b82f6",
                text: message,
            });
        }
    }, [message, error])

    useEffect(() => {
        if (success) {
            Swal.fire({
                icon: "success",
                title: "Exito",
                confirmButtonColor: "#3b82f6",
                text: message,
            });
            toggleProveedor();
        }
    }, [message, success])

    return <>
        <Modal isOpen={ModalProv} toggle={toggleProveedor} size='lg'>
            <ModalHeader className="backend_titulo" toggle={toggleProveedor}>Actualizar Proveedor</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label htmlFor="nombre">Nombre</label>
                            <input type="text" className="form-control" id="nombre" placeholder="Nombre" value={NombreP} onChange={(e) => setNombreP(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label htmlFor="apellido_p">Apellido Paterno</label>
                            <input type="text" className="form-control" id="apellido_p" placeholder="Apellido Paterno" value={ApellidoPP} onChange={(e) => setApellidoPP(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label htmlFor="apellido_m">Apellido Materno</label>
                            <input type="text" className="form-control" id="apellido_m" placeholder="Apellido Materno" value={ApellidoMP} onChange={(e) => setApellidoMP(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label htmlFor="telefono">Telefono</label>
                            <input type="text" className="form-control" id="telefono" placeholder="Telefono" value={TelefonoP} onChange={(e) => setTelefonoP(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <label htmlFor="RFC">RFC</label>
                            <input type="text" className="form-control" id="RFC" placeholder="RFC" value={RFCP} onChange={(e) => setRFCP(e.target.value)} />
                        </div>
                    </div>
                </div>
                </ModalBody>
            <ModalFooter>
                <Button className="backend_boton" onClick={(() => createUser())}>Actualizar</Button>
            </ModalFooter>
        </Modal>
    </>
}
