import { useCallback, useState } from 'react';
import { PresupuestosGet, PresupuestosPadreUpdate, PadresMontoGet, CuentaDelete, PresupuestosAportacionUpdate } from '../service/presupuesto';

export default function useUsers() {
    const [Presupuestos, setPresupuestos] = useState([]);
    const [Subtotal, setSubtotal] = useState(0);
    const [Iva_, setIva] = useState(0);
    const [Total_, setTotal] = useState(0);
    const [Pagado_, setPagado] = useState(0);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const PresupuestosGetService = useCallback((id, token) => {
        PresupuestosGet(id, token).then(response => {
            setPresupuestos(response.presupuensto)
        })
    }, []);

    const PresupuestosPadreUpdateService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        PresupuestosPadreUpdate(
            data, token
        ).then(response => {
            if (response.guardado === true) {
                setState({
                    loading: false,
                    error: false,
                    message: 'Padre actualizado con exito',
                    success: true
                })
            } else {
                setState({
                    loading: false,
                    error: true,
                    message: 'Error al actualizar Padre',
                    success: false
                })
            }
        })
    }, [])

    const PadresMontoGetService = useCallback((id, token) => {
        PadresMontoGet(id, token).then(response => {
            setSubtotal(response.subtotal);
            setIva(response.iva);
            setTotal(response.total);
            setPagado(response.pagado);
        })
    }, []);

    const CuentaDeleteService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        CuentaDelete(
            data, token
        ).then(response => {
            if (response.guardado === true) {
                setState({
                    loading: false,
                    error: false,
                    message: 'Cuenta presupuestal eliminada con exito',
                    success: true
                })
            } else {
                setState({
                    loading: false,
                    error: true,
                    message: 'Error al eliminar cuenta presupuestal',
                    success: false
                })
            }
        })
    }, [])

    const PresupuestosAportacionUpdateService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        PresupuestosAportacionUpdate(
            data, token
        ).then(response => {
            if (response.guardado === true) {
                setState({
                    loading: false,
                    error: false,
                    message: 'Aportacion agregada con exito',
                    success: true
                })
            } else {
                setState({
                    loading: false,
                    error: true,
                    message: 'Error al agregar Aportacion',
                    success: false
                })
            }
        })
    }, [])

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Presupuestos,
        Subtotal,
        Iva_,
        Total_,
        Pagado_,
        PresupuestosGetService,
        PresupuestosPadreUpdateService,
        PadresMontoGetService,
        CuentaDeleteService,
        PresupuestosAportacionUpdateService
    }
}