import { useCallback, useState } from 'react';
import { PerfilCliGet, PerfilProfeGet, PasswordUpdate } from '../service/perfil';

export default function useDirecciones() {
    // Cliente
    const [Fotos, setFotos] = useState('');
    const [NombreComercial, setNombreComercial] = useState('');
    const [Telefono, setTelefono] = useState('');
    const [Email, setEmail] = useState('');
    const [Tipo, setTipo] = useState('');
    const [RFC, setRFC] = useState('');
    const [Calle, setCalle] = useState('');
    const [Exterior, setExterior] = useState('');
    const [Interior, setInterior] = useState('');
    const [Colonia, setColonia] = useState('');
    const [CP, setCP] = useState('');
    const [Municipio, setMunicipio] = useState('');
    const [Estado, setEstado] = useState('');
    const [Pais, setPais] = useState('');
    // Proveedor
    const [NombreP, setNombreP] = useState('');
    const [ApellidoPP, setApellidoPP] = useState('');
    const [ApellidoMP, setApellidoMP] = useState('');
    const [TelefonoP, setTelefonoP] = useState('');
    const [CorreoP, setCorreoP] = useState('');
    const [RFCP, setRFCP] = useState('');
    const [EstadoId, setEstadoId] = useState('')
    const [id, setid] = useState('')
    const [MunicipioId, setMunicipioId] = useState('')
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const PerfilCliGetService = useCallback((id, token) => {
        PerfilCliGet(id, token).then(response => {
            setFotos(response.foto);
            setNombreComercial(response.nombre_comercial);
            setEmail(response.email);
            setTipo(response.tipo);
            setRFC(response.RFC);
            setTelefono(response.telefono);
            setCalle(response.calle);
            setExterior(response.exterior);
            setInterior(response.inerior);
            setColonia(response.colonia);
            setCP(response.cp);
            setMunicipio(response.municipio);
            setEstado(response.estado);
            setPais(response.pais);
            setEstadoId(response.estadoId);
            setMunicipioId(response.municipioId);
        })
    }, []);

    const PerfilProveGetService = useCallback((id, token) => {
        PerfilProfeGet(id, token).then(response => {
            setFotos(response.foto);
            setNombreP(response.nombre);
            setApellidoPP(response.apellido_p);
            setApellidoMP(response.apellido_m);
            setTelefonoP(response.telefono);
            setCorreoP(response.email);
            setRFCP(response.RFC);
            setid(response.id);
        })
    }, []);
    /*
     * Actualizar password
     */
    const PasswordUpdateService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        PasswordUpdate(
            data, token
        ).then(response => {
            if (response.guardado === true) {
                setState({
                    loading: false,
                    error: false,
                    message: 'Password actualizada con exito',
                    success: true
                })
            } else {
                setState({
                    loading: false,
                    error: true,
                    message: 'Error al actualizar password',
                    success: false
                })
            }
        })
    }, [])

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Fotos, setFotos,
        NombreComercial, setNombreComercial,
        Telefono, setTelefono,
        Email, setEmail,
        RFC, setRFC,
        Tipo, setTipo,
        Calle, setCalle,
        Exterior, setExterior,
        Interior, setInterior,
        Colonia, setColonia,
        CP, setCP,
        Municipio, setMunicipio,
        Estado, setEstado,
        Pais, setPais,
        EstadoId, setEstadoId,
        MunicipioId, setMunicipioId,
        id, setid,

        NombreP, setNombreP,
        ApellidoPP, setApellidoPP,
        ApellidoMP, setApellidoMP,
        TelefonoP, setTelefonoP,
        CorreoP, setCorreoP,
        RFCP, setRFCP,

        PerfilCliGetService,
        PerfilProveGetService,
        PasswordUpdateService,
    }
}