/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import usePresupuesto from "../../hoock/usePresupuesto";
import ModaladdPadre from "./Modals/ModalPadre";
import ModalSubpadre from "./Modals/ModaSubpadre";
import ModalHijo from "./Modals/ModalHijo";
import ModalFechas from "./Modals/ModalFecha";
import ModalEditar from "./Modals/ModalEditarCampo";
import ModalElim from "./Modals/ModalEliminar";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridNoRecords,
  GridCell,
  GridDetailRow,
  GridRow,
  GridHeaderCell,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { filterBy } from "@progress/kendo-data-query";
import { process } from "@progress/kendo-data-query";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import { hideLoader, showLoader, setTitle } from "../../reducers/loaderReducer";
import { useDispatch } from "react-redux";
import useLogin from "../../hoock/useLogin";
import Swal from "sweetalert2";

const initialDataState = {
  take: 10,
  skip: 0,
  group: [
    {
      field: "grupo",
    },
  ],
};
const aggregates = [
  {
    field: "monto",
    aggregate: "sum",
  },
  {
    field: "iva",
    aggregate: "sum",
  },
  {
    field: "total",
    aggregate: "sum",
  },
];

const initialFilter = {
  logic: "and",
  filters: [],
};

const initialSort = [
  // {
  //   field: "modelo",
  //   dir: "desc",
  // },
  // {
  //   field: "serie",
  //   dir: "desc",
  // },
  // {
  //   field: "fecha_venta",
  //   dir: "desc",
  // },
  // {
  //   field: "Venta",
  //   dir: "desc",
  // },
];

const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

export default function Index() {
  const { logout, token, user_rol } = useLogin();

  const dispatch = useDispatch();
  const { message, success, Presupuestos, Subtotal, Iva_, Total_, PresupuestosGetService, PresupuestosPadreUpdateService, PadresMontoGetService } = usePresupuesto();
  const [dataState, setDataState] = React.useState(initialDataState);
  const [result, setResult] = React.useState(
    processWithGroups([], initialDataState)
  );
  const [collapsedState, setCollapsedState] = React.useState([]);

  const [sort, setSort] = React.useState(initialSort);
  const [filter, setFilter] = React.useState(initialFilter);
  const [page, setPage] = React.useState(initialDataState);

  const [Children, setChildren] = useState([]);
  const [Padre, setPadre] = useState([]);
  const [PadreID, setPadreID] = useState("");
  const [HijoID, setHijoID] = useState("");
  const [NombreH, setNombreH] = useState("");
  const [NivelID, setNivelID] = useState("");
  const [VariableID, setVariableID] = useState("");
  const [array_titles, setarray_titles] = useState([]);

  /*
   * Editar Campos
   */
  const [ModalEditarCampo, setModalEditarCampo] = useState(false);
  const [Campo, setCampo] = useState("");
  const [ValorCampo, setValorCampo] = useState("");
  const [IDCampo, setIDCampo] = useState("");
  const [Tipo, setTipo] = useState("");

  useEffect(() => {
    if (success == true) {
      PresupuestosGetService(token);
      //setActivarCampoNu(null);
      //setActivarCampoN(null);
    }
  }, [PresupuestosGetService, success]);

  /*
   * Agregar Padre
   */
  const [ModalPadre, setModalPadre] = useState(false);
  const [IDPadre, setIDPadre] = useState("");
  const [UpdatePadre, setUpdatePadre] = useState(false);
  const [NombrePadre, setNombrePadre] = useState("");
  const [NumeracionPadre, setNumeracionPadre] = useState("");
  const [NivelPadre, setNivelPadre] = useState(1);
  const [GrupoPadre, setGrupoPadre] = useState("");
  /*
   * Agregar Subpadre
   */
  const [ModalSub, setModalSub] = useState(false);
  const [NombreSub, setNombreSub] = useState("");
  /*
   * Agregar hijo
   */
  const [NomPadre, setNomPadre] = useState("");
  const [modalHijo, setmodalHijo] = useState(false);
  const [NombreVari, setNombreVari] = useState("");
  const [NumeracionVari, setNumeracionVari] = useState("");
  const [Cantidad, setCantidad] = useState(0);
  const [Unidades, setUnidades] = useState("");
  const [X, setX] = useState(0);
  const [MostrarVariable, setMostrarVariable] = useState(false);
  const [IsChecked, setIsChecked] = useState(true);
  const [Fecha, setFecha] = useState("");
  const [Opcion, setOpcion] = useState([]);
  const [Cuantos, setCuantos] = useState(0);
  const [Costo, setCosto] = useState(0);
  const [Subtota, setSubtota] = useState(0);
  const [Iva, setIva] = useState(0);
  const [Total, setTotal] = useState(0);

  /*
   * Estados Fecha
   */
  const [ModalFecha, setModalFecha] = useState(false);
  const [IDFecha, setIDFecha] = useState("");

  /*
   * Eliminar Cuenta
   */
  const [ModalEliminar, setModalEliminar] = useState(false);
  const [Cuenta, setCuenta] = useState("");
  const [NomCuenta, setNomCuenta] = useState("");
  const [IDCuenta, setIDCuenta] = useState("");
  const [CuentaTipo, setCuentaTipo] = useState("");

  useEffect(() => {
    PresupuestosGetService(token);
  }, [PresupuestosGetService]);

  useEffect(() => {
    if (Presupuestos.length > 0) {
      setResult(processWithGroups(Presupuestos, initialDataState));
      dispatch(hideLoader())
      dispatch(setTitle(""))
    }
  }, [Presupuestos]);

  useEffect(() => {
    dispatch(setTitle("Descargando presupuestos"));
    dispatch(showLoader());
    PadresMontoGetService(token);
  }, [PadresMontoGetService]);

  const returnChildren = (e, type = null) => {
    const nivel = type ? e.nivel_id + 1 : e.nivel_id;
    if (nivel === 2) {
      setChildren([]);
    }

    const children = Padre;
    if (nivel === 3) {
      setChildren(children);
    } else if (nivel === 4) {
      const children = Padre[0].children;
      setChildren(children);
    } else if (nivel === 5) {
      const children = Padre[0].children[0].children;
      setChildren(children);
    } else if (nivel === 6) {
      const children = Padre[0].children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 7) {
      const children = Padre[0].children[0].children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 8) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 9) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children;
      setChildren(children);
    } else if (nivel === 10) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children;
      setChildren(children);
    } else if (nivel === 11) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 12) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 13) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 14) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 15) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children[0]
          .children[0].children;
      setChildren(children);
    } else if (nivel === 16) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 17) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 18) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 19) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children;
      setChildren(children);
    } else if (nivel === 20) {
      const children =
        Padre[0].children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children[0]
          .children[0].children[0].children[0].children[0].children;
      setChildren(children);
    }
    array_titles.pop();
  };

  const retornarNivel = (a) => {
    if (a.nivel_id === 2) {
      setarray_titles((array) => [...array, a]);
      setPadre([a]);
      const numeros = SumatoriaHijos(a);
      setChildren(numeros.children);
    } else {
      setarray_titles((array) => [...array, a]);
      const numeros = SumatoriaHijos(a);
      setChildren(numeros.children);
    }
  };

  const dataStateChange = (event) => {
    const newDataState = processWithGroups(Presupuestos, event.dataState);
    setResult(newDataState);
    setDataState(event.dataState);
  };

  const expandChange = (event) => {
    const item = event.dataItem;

    if (item.groupId) {
      const newCollapsedIds = !event.value
        ? [...collapsedState, item.groupId]
        : collapsedState.filter((groupId) => groupId !== item.groupId);
      setCollapsedState(newCollapsedIds);
    }
  };

  const formatNumber = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  const cellRender = (tdElement, cellProps) => {
    //console.log(cellProps.field);
    if (cellProps.rowType === "groupFooter") {
      if (cellProps.field === "monto") {
        return (
          <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            Subtotal:{" "}
            {"$" + formatNumber(cellProps.dataItem.aggregates.monto.sum)}
          </td>
        );
      } else if (cellProps.field === "iva") {
        return (
          <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            IVA: {"$" + formatNumber(cellProps.dataItem.aggregates.iva.sum)}
          </td>
        );
      } else if (cellProps.field === "total") {
        return (
          <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            Total: {"$" + formatNumber(cellProps.dataItem.aggregates.total.sum)}
          </td>
        );
      }
    }

    return tdElement;
  };

  const newData = setExpandedState({
    data: result.data,
    collapsedIds: collapsedState,
  });

  const Sumatoria = (e) => {
    for (let i = 0; i < e.children.length; i++) {
      const obtenerhijo = e.children[i];
      if (obtenerhijo.subpadre == "Si") {
        const subtotalHijo = obtenerhijo.children.reduce(
          (acumulador, currenci) =>
            currenci.subpadre == "No"
              ? acumulador + parseFloat(currenci.subtotal)
              : acumulador,
          0
        );
        const ivaHijo = obtenerhijo.children.reduce(
          (acumulador, currenci) =>
            currenci.subpadre == "No"
              ? acumulador + parseFloat(currenci.iva)
              : acumulador,
          0
        );
        const totalHijo = obtenerhijo.children.reduce(
          (acumulador, currenci) =>
            currenci.subpadre == "No"
              ? acumulador + parseFloat(currenci.total)
              : acumulador,
          0
        );
        obtenerhijo.subtotal = subtotalHijo;
        obtenerhijo.iva = ivaHijo;
        obtenerhijo.total = totalHijo;
      }
    }
    setChildren(e.children);
    setNomPadre(e.Nombre);
  };

  const SumatoriaHijos = (e) => {
    for (let i = 0; i < e.children.length; i++) {
      const obtenerhijo = e.children[i];
      if (obtenerhijo.subpadre == "Si") {
        const subtotalHijo = obtenerhijo.children.reduce(
          (acumulador, currenci) =>
            currenci.subpadre == "No"
              ? acumulador + parseFloat(currenci.subtotal)
              : acumulador,
          0
        );
        const ivaHijo = obtenerhijo.children.reduce(
          (acumulador, currenci) =>
            currenci.subpadre == "No"
              ? acumulador + parseFloat(currenci.iva)
              : acumulador,
          0
        );
        const totalHijo = obtenerhijo.children.reduce(
          (acumulador, currenci) =>
            currenci.subpadre == "No"
              ? acumulador + parseFloat(currenci.total)
              : acumulador,
          0
        );
        obtenerhijo.subtotal = subtotalHijo;
        obtenerhijo.iva = ivaHijo;
        obtenerhijo.total = totalHijo;
      }
    }
    return e;
  };

  const togglePadre = () => {
    setModalPadre(!ModalPadre);
    setUpdatePadre(false);
    setIDPadre("");
    setNombrePadre("");
    setNumeracionPadre("");
    setNivelPadre("");
    setGrupoPadre("");
  };

  const toggleSubPadre = () => {
    setModalSub(!ModalSub);
    setPadreID("");
    setHijoID("");
    setNombreSub("");
    setNivelID("");
  };

  const toggleHijo = () => {
    setmodalHijo(!modalHijo);
    setPadreID("");
    setHijoID("");
    setNombreH("");
    setNivelID("");
    setVariableID("");
    setCantidad("");
    setUnidades("");
    setX("");
    setMostrarVariable(false);
    setNombreVari("");
    setNumeracionVari("");
    setIsChecked(true);
    setFecha("");
    setOpcion("");
    setCuantos("");
    setCosto("");
    setSubtota("");
    setIva("");
    setTotal("");
  };

  const toggleFecha = () => {
    setModalFecha(!ModalFecha);
    setIDFecha("");
  };

  const toggleEliminar = () => {
    setModalEliminar(!ModalEliminar);
    setCuenta("");
    setNomCuenta("");
    setCuentaTipo("");
    setIDCuenta("");
  };

  const toggleEditar = () => {
    setModalEditarCampo(!ModalEditarCampo);
    setCampo("");
    setValorCampo("");
    setIDCampo("");
    setTipo("");
  };

  const pageChange = (event) => {
    setPage(event.page);
  };

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, success])

  return (
    <>
      <ModaladdPadre
        ModalPadre={ModalPadre}
        togglePadre={togglePadre}
        NombrePadre={NombrePadre}
        NumeracionPadre={NumeracionPadre}
        NivelPadre={NivelPadre}
        GrupoPadre={GrupoPadre}
        UpdatePadre={UpdatePadre}
        IDPadre={IDPadre}
        token={token}
        setNombrePadre={setNombrePadre}
        setNumeracionPadre={setNumeracionPadre}
        setNivelPadre={setNivelPadre}
        setGrupoPadre={setGrupoPadre}
        PresupuestosGetService={PresupuestosGetService}
      />

      <ModalSubpadre
        ModalSub={ModalSub}
        toggleSubPadre={toggleSubPadre}
        PadreID={PadreID}
        HijoID={HijoID}
        NombreSub={NombreSub}
        NivelID={NivelID}
        token={token}
        setPadreID={setPadreID}
        setHijoID={setHijoID}
        setNombreSub={setNombreSub}
        setNivelID={setNivelID}
        PresupuestosGetService={PresupuestosGetService}
      />

      <ModalHijo
        modalHijo={modalHijo}
        toggleHijo={toggleHijo}
        PadreID={PadreID}
        HijoID={HijoID}
        NombreH={NombreH}
        NivelID={NivelID}
        VariableID={VariableID}
        Cantidad={Cantidad}
        Unidades={Unidades}
        X={X}
        MostrarVariable={MostrarVariable}
        NombreVari={NombreVari}
        NumeracionVari={NumeracionVari}
        IsChecked={IsChecked}
        Fecha={Fecha}
        Opcion={Opcion}
        Cuantos={Cuantos}
        Costo={Costo}
        Subtota={Subtota}
        Iva={Iva}
        Total={Total}
        token={token}
        setPadreID={setPadreID}
        setHijoID={setHijoID}
        setNombreH={setNombreH}
        setNivelID={setNivelID}
        setVariableID={setVariableID}
        setCantidad={setCantidad}
        setX={setX}
        setUnidades={setUnidades}
        setMostrarVariable={setMostrarVariable}
        setNombreVari={setNombreVari}
        setNumeracionVari={setNumeracionVari}
        setIsChecked={setIsChecked}
        setFecha={setFecha}
        setOpcion={setOpcion}
        setCuantos={setCuantos}
        setCosto={setCosto}
        setSubtota={setSubtota}
        setIva={setIva}
        setTotal={setTotal}
        PresupuestosGetService={PresupuestosGetService}
      />

      <ModalFechas
        ModalFecha={ModalFecha}
        toggleFecha={toggleFecha}
        IDFecha={IDFecha}
        token={token}
        PresupuestosGetService={PresupuestosGetService}
      />

      <ModalElim
        ModalEliminar={ModalEliminar}
        toggleEliminar={toggleEliminar}
        IDCuenta={IDCuenta}
        Cuenta={Cuenta}
        NomCuenta={NomCuenta}
        CuentaTipo={CuentaTipo}
        token={token}
        PresupuestosGetService={PresupuestosGetService}
      />

      <ModalEditar
        ModalEditarCampo={ModalEditarCampo}
        toggleEditar={toggleEditar}
        Campo={Campo}
        ValorCampo={ValorCampo}
        IDCampo={IDCampo}
        Tipo={Tipo}
        token={token}
        setCampo={setCampo}
        setValorCampo={setValorCampo}
        setIDCampo={setIDCampo}
        setTipo={setTipo}
        PresupuestosGetService={PresupuestosGetService}
      />
      <section style={{ paddingTop: 10, paddingBottom: 50 }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Presupuestos</h2>
            </div>
            {Children.length === 0 ? (
              <>
                <div className="col-md-6 text-end">
                  <a className="btn btn-info" onClick={togglePadre}>
                    Agregar cuenta Padre
                  </a>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <br />
          <div className="row">
            <div className="col-sm-4 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h5>Subtotal</h5>
                  <div className="row">
                    <div className="col-8 col-sm-12 col-xl-8 my-auto">
                      <div className="d-flex d-sm-block d-md-flex align-items-center">
                        <h2 className="mb-0">${formatNumber(Subtotal)}</h2>
                      </div>
                    </div>
                    <div className="col-4 col-sm-12 col-xl-4 text-center text-xl-right">
                      <i className="icon-lg mdi mdi-currency-usd text-primary ml-auto"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h5>IVA</h5>
                  <div className="row">
                    <div className="col-8 col-sm-12 col-xl-8 my-auto">
                      <div className="d-flex d-sm-block d-md-flex align-items-center">
                        <h2 className="mb-0">${formatNumber(Iva_)}</h2>
                      </div>
                    </div>
                    <div className="col-4 col-sm-12 col-xl-4 text-center text-xl-right">
                      <i className="icon-lg mdi mdi-currency-usd text-primary ml-auto"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h5>Total</h5>
                  <div className="row">
                    <div className="col-8 col-sm-12 col-xl-8 my-auto">
                      <div className="d-flex d-sm-block d-md-flex align-items-center">
                        <h2 className="mb-0">${formatNumber(Total_)}</h2>
                      </div>
                    </div>
                    <div className="col-4 col-sm-12 col-xl-4 text-center text-xl-right">
                      <i className="icon-lg mdi mdi-currency-usd text-primary ml-auto"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {Children.length > 0 ? (
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <button
                        onClick={() => {
                          setChildren([]);
                        }}
                        className="btn text-uppercase"
                      >
                        {NomPadre}
                      </button>
                    </li>
                    {array_titles.map((a) => (
                      <li key={a.nombre} className="breadcrumb-item">
                        <button
                          className="btn text-uppercase"
                          onClick={() => returnChildren(a, "nivel")}
                        >
                          {a.nombre}
                        </button>
                      </li>
                    ))}
                  </ol>
                </nav>
              ) : (
                ""
              )}
            </div>
          </div>
          {Children.length === 0 ? (
            <div className="row">
              <div className="col-md-12">
                <Grid
                  style={{
                    height: "720px",
                  }}
                  resizable={true}
                  reorderable={true}
                  filterable={true}
                  sortable={true}
                  pageable={{
                    pageSizes: true,
                  }}
                  groupable={{
                    footer: "visible",
                  }}
                  data={newData}
                  onDataStateChange={dataStateChange}
                  {...dataState}
                  onExpandChange={expandChange}
                  expandField="expanded"
                  cellRender={cellRender}
                >
                  <Column
                    field="Cuenta presupuestal"
                    filter={"text"}
                    width={160}
                    cell={(a) => {
                      if (!a.dataItem.items) {
                        let numeracion = a.dataItem["Cuenta presupuestal"];
                        let id = a.dataItem.id;
                        return (
                          <td style={{ marginTop: 10, textAlign: "center" }}>
                            <p
                              onClick={() => {
                                setCampo("Cuenta presupuestal");
                                setValorCampo(numeracion);
                                setIDCampo(id);
                                setTipo("padre");
                                setModalEditarCampo(true);
                              }}
                            >
                              {numeracion}
                            </p>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            style={{ marginTop: 10, textAlign: "center" }}
                          ></td>
                        );
                      }
                    }}
                  />
                  <Column
                    field="Nombre"
                    filter={"text"}
                    width={228}
                    cell={(a) => {
                      if (!a.dataItem.items) {
                        let nombre = a.dataItem["Nombre"];
                        let id = a.dataItem.id;
                        return (
                          <td style={{ marginTop: 10, textAlign: "center" }}>
                            <p
                              onClick={() => {
                                setCampo("Nombre");
                                setValorCampo(nombre);
                                setIDCampo(id);
                                setTipo("padre");
                                setModalEditarCampo(true);
                              }}
                            >
                              {nombre}
                            </p>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            style={{ marginTop: 10, textAlign: "center" }}
                          ></td>
                        );
                      }
                    }}
                  />
                  <Column
                    field="monto"
                    title="Subtotal"
                    filter={false}
                    filterable={false}
                    format="{0:c}"
                    width={160}
                  />
                  <Column
                    field="iva"
                    title="IVA"
                    filter={false}
                    filterable={false}
                    format="{0:c}"
                    width={160}
                  />
                  <Column
                    field="total"
                    title="Total"
                    filter={false}
                    filterable={false}
                    format="{0:c}"
                    width={160}
                  />
                  <Column
                    field="Subpadre"
                    filter={false}
                    filterable={false}
                    width={85}
                    cell={(a) => {
                      if (!a.dataItem.items) {
                        let id = a.dataItem.id;
                        let nivel_id = a.dataItem.nivel_id;
                        return (
                          <td style={{ marginTop: 10, textAlign: "center" }}>
                            <button
                              className="btn btn-info btn-sm btn-icon-text"
                              onClick={() => {
                                setPadreID(id);
                                setHijoID(null);
                                setNivelID(nivel_id);
                                setModalSub(true);
                              }}
                            >
                              <i className="mdi mdi-plus-box"></i>
                            </button>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            style={{ marginTop: 10, textAlign: "center" }}
                          ></td>
                        );
                      }
                    }}
                  />
                  <Column
                    field="Hijos"
                    filter={false}
                    filterable={false}
                    width={60}
                    cell={(e) => {
                      if (!e.dataItem.items) {
                        let id = e.dataItem.id;
                        let nivel_id = e.dataItem.nivel_id;
                        return (
                          <td style={{ marginTop: 10, textAlign: "center" }}>
                            <button
                              className="btn btn-primary btn-sm btn-icon-text"
                              onClick={() => {
                                setPadreID(id);
                                setHijoID(null);
                                setNivelID(nivel_id);
                                setmodalHijo(true);
                              }}
                            >
                              <i className="mdi mdi-plus-box"></i>
                            </button>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            style={{ marginTop: 10, textAlign: "center" }}
                          ></td>
                        );
                      }
                    }}
                  />
                  <Column
                    field=""
                    filter={false}
                    filterable={false}
                    width={80}
                    cell={(o) => {
                      if (!o.dataItem.items) {
                        let index = o.dataItem;
                        return (
                          <td style={{ marginTop: 10, textAlign: "center" }}>
                            {index.children.length !== 0 ? (
                              <button
                                className="btn btn-success btn-sm btn-icon-text"
                                onClick={() => {
                                  Sumatoria(index);
                                }}
                              >
                                <i className="mdi mdi-arrow-right-bold"></i>
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        );
                      } else {
                        return (
                          <td
                            style={{ marginTop: 10, textAlign: "center" }}
                          ></td>
                        );
                      }
                    }}
                  />
                  {
                    user_rol === "super administrador" || user_rol === "cliente"
                      ?
                      <Column
                        field=""
                        filter={false}
                        filterable={false}
                        width={60}
                        cell={(e) => {
                          if (!e.dataItem.items) {
                            let numeracion = e.dataItem.numeracion;
                            let id = e.dataItem.id;
                            let nombre = e.dataItem.nombre;
                            return (
                              <td style={{ marginTop: 10, textAlign: "center" }}>
                                <button
                                  className="btn btn-danger btn-sm btn-icon-text"
                                  onClick={() => {
                                    setCuenta(numeracion);
                                    setNomCuenta(nombre);
                                    setIDCuenta(id);
                                    setCuentaTipo("padre");
                                    setModalEliminar(true);
                                  }}
                                >
                                  <i className="mdi mdi-delete-forever"></i>
                                </button>
                              </td>
                            );
                          } else {
                            return (
                              <td
                                style={{ marginTop: 10, textAlign: "center" }}
                              ></td>
                            );
                          }
                        }}
                      />
                      :
                      ''
                  }

                </Grid>
              </div>
            </div>
          ) : (
            ""
          )}
          {Children.length > 0 ? (
            <Grid
              sortable={true}
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
              filterable={true}
              filter={filter}
              onFilterChange={(e) => setFilter(e.filter)}
              pageable={true}
              data={filterBy(
                Children.slice(page.skip, page.take + page.skip),
                filter
              )}
              skip={page.skip}
              take={page.take}
              total={Children.length}
              onPageChange={pageChange}
              style={{
                height: "520px",
              }}
            >
              <GridNoRecords>No hay informacion disponible</GridNoRecords>
              <Column
                field="cuenta_presupuental"
                title="Cuenta presupuental"
                width={155}
              />
              <Column
                field="Nombre"
                filter={"text"}
                width={200}
                cell={(a) => {
                  if (!a.dataItem.items) {
                    let nombre = a.dataItem["Nombre"];
                    let id = a.dataItem.id;
                    return (
                      <td style={{ marginTop: 10, textAlign: "center" }}>
                        <p
                          onClick={() => {
                            setCampo("Nombre");
                            setValorCampo(nombre);
                            setIDCampo(id);
                            setTipo("hijo");
                            setModalEditarCampo(true);
                          }}
                        >
                          {nombre}
                        </p>
                      </td>
                    );
                  } else {
                    return (
                      <td style={{ marginTop: 10, textAlign: "center" }}></td>
                    );
                  }
                }}
              />
              <Column
                field="padre_nombre"
                title="Cuenta Raiz"
                filter="text"
                width={200}
              />
              <Column
                field="nombre_hijo"
                title="Cuenta Padre"
                filter="text"
                width={200}
              />
              <Column
                field="Cantidad"
                filterable={false}
                width={85}
                cell={(a) => {
                  if (!a.dataItem.items) {
                    let cantidad = a.dataItem["Cantidad"];
                    let id = a.dataItem.id;
                    return (
                      <td style={{ textAlign: "center" }}>
                        <p
                          style={{ marginTop: 15 }}
                          onClick={() => {
                            setCampo("Cantidad");
                            setValorCampo(cantidad);
                            setIDCampo(id);
                            setTipo("hijo");
                            setModalEditarCampo(true);
                          }}
                        >
                          {cantidad}
                        </p>
                      </td>
                    );
                  } else {
                    return (
                      <td style={{ marginTop: 10, textAlign: "center" }}></td>
                    );
                  }
                }}
              />
              <Column
                field="Unidad"
                filterable={false}
                width={85}
                cell={(a) => {
                  if (!a.dataItem.items) {
                    let unidad = a.dataItem["Unidad"];
                    let id = a.dataItem.id;
                    return (
                      <td style={{ textAlign: "center" }}>
                        <p
                          style={{ marginTop: 15 }}
                          onClick={() => {
                            setCampo("Unidad");
                            setValorCampo(unidad);
                            setIDCampo(id);
                            setTipo("hijo");
                            setModalEditarCampo(true);
                          }}
                        >
                          {unidad}
                        </p>
                      </td>
                    );
                  } else {
                    return (
                      <td style={{ marginTop: 10, textAlign: "center" }}></td>
                    );
                  }
                }}
              />
              <Column
                field="X"
                filterable={false}
                width={85}
                cell={(a) => {
                  if (!a.dataItem.items) {
                    let x = a.dataItem["X"];
                    let id = a.dataItem.id;
                    return (
                      <td style={{ textAlign: "center" }}>
                        <p
                          style={{ marginTop: 15 }}
                          onClick={() => {
                            setCampo("X");
                            setValorCampo(x);
                            setIDCampo(id);
                            setTipo("hijo");
                            setModalEditarCampo(true);
                          }}
                        >
                          {x}
                        </p>
                      </td>
                    );
                  } else {
                    return (
                      <td style={{ marginTop: 10, textAlign: "center" }}></td>
                    );
                  }
                }}
              />

              <Column
                field="subtotal"
                title="Subtotal"
                filter="text"
                format="{0:c}"
                width={160}
              />
              <Column
                field="iva"
                title="IVA"
                filter="text"
                format="{0:c}"
                width={160}
              />
              <Column
                field="total"
                title="Total"
                filter="text"
                format="{0:c}"
                width={160}
              />
              <Column
                field="Subpadre"
                filter={false}
                filterable={false}
                cell={(e) => {
                  let subpadre = e.dataItem.subpadre;
                  return (
                    <td style={{ marginTop: 10, textAlign: "center" }}>
                      <div
                        className={
                          subpadre == "Si"
                            ? "badge bg-info text-wrap"
                            : "badge bg-secondary text-wrap"
                        }
                      >
                        {subpadre}
                      </div>
                    </td>
                  );
                }}
              />
              <Column
                field="Fechas"
                filter={false}
                filterable={false}
                width={69}
                cell={(e) => {
                  let id = e.dataItem.id;
                  let subpadre = e.dataItem.subpadre;
                  return (
                    <td style={{ marginTop: 10, textAlign: "center" }}>
                      {subpadre == "No" ? (
                        <button
                          className="btn btn-secondary btn-sm btn-icon-text"
                          onClick={() => {
                            setIDFecha(id);
                            setModalFecha(true);
                          }}
                        >
                          <i className="mdi mdi-calendar"></i>
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  );
                }}
              />
              <Column
                field="Subpadre"
                filter={false}
                filterable={false}
                width={85}
                cell={(e) => {
                  let presupuestos_padre_id = e.dataItem.presupuestos_padre_id;
                  let id = e.dataItem.id;
                  let nivel_id = e.dataItem.nivel_id;
                  return (
                    <td style={{ marginTop: 10, textAlign: "center" }}>
                      <button
                        className="btn btn-info btn-sm btn-icon-text"
                        onClick={() => {
                          setPadreID(presupuestos_padre_id);
                          setHijoID(id);
                          setNivelID(nivel_id);
                          setModalSub(true);
                        }}
                      >
                        <i className="mdi mdi-plus-box"></i>
                      </button>
                    </td>
                  );
                }}
              />
              <Column
                field="Hijos"
                filter={false}
                filterable={false}
                width={65}
                cell={(e) => {
                  let presupuestos_padre_id = e.dataItem.presupuestos_padre_id;
                  let id = e.dataItem.id;
                  let nivel_id = e.dataItem.nivel_id;
                  return (
                    <td style={{ marginTop: 10, textAlign: "center" }}>
                      <button
                        className="btn btn-primary btn-sm btn-icon-text"
                        onClick={() => {
                          setPadreID(presupuestos_padre_id);
                          setHijoID(id);
                          setNivelID(nivel_id);
                          setmodalHijo(true);
                        }}
                      >
                        <i className="mdi mdi-plus-box"></i>
                      </button>
                    </td>
                  );
                }}
              />
              <Column
                field=""
                filter={false}
                filterable={false}
                width={60}
                cell={(e) => {
                  let index = e.dataItem;
                  return (
                    <td style={{ marginTop: 10, textAlign: "center" }}>
                      {index.children.length !== 0 ? (
                        <button
                          className="btn btn-success btn-sm  btn-icon-text"
                          onClick={() => {
                            retornarNivel(index);
                          }}
                        >
                          <i className="mdi mdi-arrow-right-bold"></i>
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  );
                }}
              />
              <Column
                field=""
                filter={false}
                filterable={false}
                width={60}
                cell={(e) => {
                  let index = e.dataItem;
                  return (
                    <td style={{ marginTop: 10, textAlign: "center" }}>
                      <button
                        className="btn btn-warning btn-sm  btn-icon-text"
                        onClick={() => {
                          returnChildren(index);
                        }}
                      >
                        <i className="mdi mdi-arrow-left-bold"></i>
                      </button>
                    </td>
                  );
                }}
              />
              {
                user_rol === "super administrador" || user_rol === "cliente"
                  ?
                  <Column
                    field=""
                    filter={false}
                    filterable={false}
                    width={60}
                    cell={(e) => {
                      if (!e.dataItem.items) {
                        let numeracion = e.dataItem.cuenta_presupuental;
                        let id = e.dataItem.id;
                        let nombre = e.dataItem.nombre_hijo;
                        return (
                          <td style={{ marginTop: 10, textAlign: "center" }}>
                            <button
                              className="btn btn-danger btn-sm btn-icon-text"
                              onClick={() => {
                                setCuenta(numeracion);
                                setNomCuenta(nombre);
                                setIDCuenta(id);
                                setCuentaTipo("hijo");
                                setModalEliminar(true);
                              }}
                            >
                              <i className="mdi mdi-delete-forever"></i>
                            </button>
                          </td>
                        );
                      } else {
                        return (
                          <td style={{ marginTop: 10, textAlign: "center" }}></td>
                        );
                      }
                    }}
                  />
                  :
                  ''
              }
            </Grid>
          ) : (
            ""
          )}
        </div>
      </section>
    </>
  );
}
