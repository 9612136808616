import { useCallback, useState } from "react";
import {
  ClienteAdd,
  ClienteDelete,
  ClienteGet,
  ClienteUpdate,
} from "../service/clientes";

export default function useCliente() {
  const [Clientes, setClientes] = useState([]);
  const [State, setState] = useState({
    loading: false,
    error: false,
    message: "",
    success: false,
  });

  const ClienteGetService = useCallback((token) => {
    ClienteGet(token).then((response) => {
      if (response.success) {
        setClientes(response?.data ?? []);
      }
    });
  }, []);
  /*
   * Agregar Cliente
   */
  const ClienteAddService = useCallback((data, token) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
    });
    ClienteAdd(data, token).then((response) => {
      if (response.success) {
        setState({
          loading: false,
          error: false,
          message: "Cliente registrado con exito",
          success: true,
        });
      } else {
        setState({
          loading: false,
          error: true,
          message: response.data,
          success: false,
        });
      }
    });
  }, []);

  /*
   * Actualizar Cliente
   */
  const ClienteUpdateService = useCallback((data, token) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
    });
    ClienteUpdate(data, token).then((response) => {
      if (response.success) {
        setState({
          loading: false,
          error: false,
          message: "Cliente actualizado con exito",
          success: true,
        });
      } else {
        setState({
          loading: false,
          error: true,
          message: response.data,
          success: false,
        });
      }
    });
  }, []);
  /*
   * Eliminar Cliente
   */
  const ClienteDeleteService = useCallback((data, token) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
    });
    ClienteDelete(data, token).then((response) => {
      if (response.success) {
        setState({
          loading: false,
          error: false,
          message: "Cliente eliminado con exito",
          success: true,
        });
      } else {
        setState({
          loading: false,
          error: true,
          message: "Error al eliminar Cliente",
          success: false,
        });
      }
    });
  }, []);

  return {
    loading: State.loading,
    error: State.error,
    message: State.message,
    success: State.success,
    Clientes,
    ClienteGetService,
    ClienteAddService,
    ClienteUpdateService,
    ClienteDeleteService,
  };
}
