/* eslint-disable eqeqeq */
import { useCallback, useState } from 'react';
import { PadreGet, PadreAdd, PadreUpdate, PadreDelete } from '../service/padre';

export default function useUsers() {
    const [Padres, setPadres] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const PadreGetService = useCallback((token) => {
        PadreGet(token).then(response => {
            setPadres(response)
        })
    }, []);
    /*
     * Agregar Padre
     */
    const PadreAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        PadreAdd(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let nombre = errores['nombre'];
                let email = errores['email'];
                let password = errores['password'];
                let tipo_Padre = errores['tipo_Padre'];
                if (nombre != null) {
                    setState({ loading: false, error: true, message: nombre[0], success: false })
                }
                if (email != null) {
                    setState({ loading: false, error: true, message: email[0], success: false })
                }
                if (password != null) {
                    setState({ loading: false, error: true, message: password[0], success: false })
                }
                if (tipo_Padre != null) {
                    setState({ loading: false, error: true, message: tipo_Padre[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Padre registrado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al registrar Padre',
                        success: false
                    })
                }
            }
        })
    }, [])

    /*
     * Actualizar Padre
     */
    const PadreUpdateService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        PadreUpdate(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let nombre = errores['nombre'];
                let email = errores['email'];
                let tipo_Padre = errores['tipo_Padre'];
                if (nombre != null) {
                    setState({ loading: false, error: true, message: nombre[0], success: false })
                }
                if (email != null) {
                    setState({ loading: false, error: true, message: email[0], success: false })
                }
                if (tipo_Padre != null) {
                    setState({ loading: false, error: true, message: tipo_Padre[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Padre actualizado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al actualizar Padre',
                        success: false
                    })
                }
            }
        })
    }, [])
    /*
     * Eliminar Padre
     */
    const PadreDeleteService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        PadreDelete(
            data, token
        ).then(response => {
            if (response.guardado === true) {
                setState({
                    loading: false,
                    error: false,
                    message: 'Padre eliminado con exito',
                    success: true
                })
            } else {
                setState({
                    loading: false,
                    error: true,
                    message: 'Error al eliminar Padre',
                    success: false
                })
            }
        })
    }, [])

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Padres,
        PadreGetService,
        PadreAddService,
        PadreUpdateService,
        PadreDeleteService
    }
}