import React from "react";
import { Navigate, Outlet, useLocation } from "react-router";
import useUser from "../hoock/useLogin";

export default function PrivateRoute(props) {
  const { token, user_rol } = useUser();
  const { pathname } = useLocation();

  if (token) {
    if (pathname === "/") {
      if (user_rol === 'super administrador' || user_rol === 'administrador') {
        return <Navigate to="/proyectos" />;
      } else if (user_rol === 'contabilidad') {
        return <Navigate to="/dashboard-accounting" />;
      } else if (user_rol === 'cliente') {
        return <Navigate to="/proyectos" />;
      } else if (user_rol === 'proveedor') {
        return <Navigate to="/proyectos" />;
      }

    } else {
      return <Outlet />;
    }
  } else {
    return <Navigate to={"/login"} />;
  }
}
