/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import useProveedor from "../../hoock/useProveedor";
import ModalAdd from "./Modals/ModalAdd";
import {
  Grid,
  GridColumn,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import {ExcelExport} from "@progress/kendo-react-excel-export";
import {GridPDFExport} from "@progress/kendo-react-pdf";
import {filterBy} from "@progress/kendo-data-query";
import Link from "../../components/Form/Link";
import Title from "../../components/Form/Title";
import {hideLoader, showLoader, setTitle} from "../../reducers/loaderReducer";
import {useDispatch} from "react-redux";
import useLogin from "../../hoock/useLogin";
import Swal from "sweetalert2";

const PageTemplate = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        left: "10px",
      }}
    >
      Page {props.pageNum} of {props.totalPages}
    </div>
  );
};

const initialDataState = {
  skip: 0,
  take: 10,
};

const initialFilter = {
  logic: "and",
  filters: [],
};

const initialSort = [
  // {
  //   field: "modelo",
  //   dir: "desc",
  // },
  // {
  //   field: "serie",
  //   dir: "desc",
  // },
  // {
  //   field: "fecha_venta",
  //   dir: "desc",
  // },
  // {
  //   field: "Venta",
  //   dir: "desc",
  // },
];

export default function Index() {
  const {user_id, logout, token, cliente_id, user_rol} = useLogin();
  const dispatch = useDispatch();
  const {
    loading,
    error,
    message,
    success,
    Proveedores,
    ProveedorGetService,
    ProveedorDeleteService,
  } = useProveedor();
  const [modal, setModal] = useState(false);
  const [Update, setUpdate] = useState(false);
  const [Id, setId] = useState("");
  const [TipoPersona, setTipoPersona] = useState("");
  const [Nombre, setNombre] = useState("");
  const [ApellidoP, setApellidoP] = useState("");
  const [ApellidoM, setApellidoM] = useState("");
  const [RFC, setRFC] = useState("");
  const [Correo, setCorreo] = useState("");
  const [Telefono, setTelefono] = useState("");
  const [UsoCFDI, setUsoCFDI] = useState("");
  const [sort, setSort] = React.useState(initialSort);
  const [filter, setFilter] = React.useState(initialFilter);
  const [page, setPage] = React.useState(initialDataState);
  let i = 1;

  const toggle = () => {
    setModal(!modal);
    setUpdate(false);
    setId("");
    setTipoPersona("");
    setNombre("");
    setApellidoP("");
    setApellidoM("");
    setRFC("");
    setCorreo("");
    setTelefono("");
    setUsoCFDI("");
  };

  useEffect(() => {
    dispatch(setTitle("Descargando proveedores"));
    dispatch(showLoader());
    if (user_rol === "super administrador") {
      ProveedorGetService(0, token);
    } else {
      ProveedorGetService(cliente_id, token);
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (Proveedores.length >= 0) {
      dispatch(hideLoader());
      dispatch(setTitle(""));
    }
    return () => {};
  }, [Proveedores]);

  useEffect(() => {
    if (success === true) {
      if (user_rol === "super administrador") {
        ProveedorGetService(0, token);
      } else {
        ProveedorGetService(cliente_id, token);
      }
    }
  }, [ProveedorGetService, success]);

  const pageChange = (event) => {
    setPage(event.page);
  };

  const formatNumber = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  const Eliminar = (e) => {
    const data = new FormData();
    data.append("id", e);
    ProveedorDeleteService(data, token);

    console.log(e);

    Swal.fire({
      title: "Espere por favor...",
      text: "Cargando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      icon: "question",
      onOpen: () => {
        Swal.showLoading();
      },
    });
  };

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, success]);

  return (
    <>
      <ModalAdd
        modal={modal}
        toggle={toggle}
        Id={Id}
        Update={Update}
        token={token}
        TipoPersona={TipoPersona}
        setTipoPersona={setTipoPersona}
        Nombre={Nombre}
        setNombre={setNombre}
        ApellidoP={ApellidoP}
        setApellidoP={setApellidoP}
        ApellidoM={ApellidoM}
        setApellidoM={setApellidoM}
        RFC={RFC}
        setRFC={setRFC}
        Correo={Correo}
        setCorreo={setCorreo}
        Telefono={Telefono}
        setTelefono={setTelefono}
        UsoCFDI={UsoCFDI}
        setUsoCFDI={setUsoCFDI}
        cliente_id={cliente_id}
        ProveedorGetService={ProveedorGetService}
      />
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Title title="Listado de Proveedores" className="text-left" />
          </div>
          <div className="col-md-6 text-end">
            <a className="btn btn-info" onClick={toggle}>
              Agregar proveedor
            </a>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <Grid
              sortable={true}
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
              filterable={true}
              filter={filter}
              onFilterChange={(e) => setFilter(e.filter)}
              pageable={true}
              data={filterBy(
                Proveedores.slice(page.skip, page.take + page.skip),
                filter
              )}
              skip={page.skip}
              take={page.take}
              total={Proveedores.length}
              onPageChange={pageChange}
              style={{
                height: "420px",
              }}
            >
              <GridNoRecords>No hay informacion disponible</GridNoRecords>
              {user_rol === "super administrador" && (
                <GridColumn
                  field="nombre_comercial"
                  title="Cliente"
                  filter={false}
                  filterable={false}
                />
              )}
              <GridColumn field="tipo_persona" title="Tipo de persona" />
              <GridColumn
                field="Razon Social"
                filter={false}
                filterable={false}
                cell={(e) => {
                  let id = e.dataItem.id;
                  let tipo_persona = e.dataItem.tipo_persona;
                  let nombre = e.dataItem.nombre;
                  let apellido_p = e.dataItem.apellido_p;
                  let apellido_m = e.dataItem.apellido_m;

                  if (tipo_persona === "Fisica") {
                    return (
                      <td style={{marginTop: 10, textAlign: "center"}}>
                        {nombre} {apellido_p} {apellido_m}
                      </td>
                    );
                  } else {
                    return (
                      <td style={{marginTop: 10, textAlign: "center"}}>
                        {nombre}
                      </td>
                    );
                  }
                }}
              />
              <GridColumn field="RFC" title="RFC" />
              <GridColumn field="correo" title="Correo" />
              <GridColumn field="telefono" title="Teléfono" />

              {user_rol === "super administrador" || user_rol === "cliente" ? (
                <GridColumn
                  field="Editar"
                  filter={false}
                  filterable={false}
                  cell={(e) => {
                    let id = e.dataItem.id;
                    let nombre = e.dataItem.nombre;
                    let cantidad = e.dataItem.cantidad;
                    return (
                      <td style={{marginTop: 10, textAlign: "center"}}>
                        <button
                          className="btn btn-warning btn-sm"
                          onClick={() => {
                            setId(id);
                            setTipoPersona(e.dataItem.tipo_persona);
                            setNombre(e.dataItem.nombre);
                            setApellidoP(e.dataItem.apellido_p);
                            setApellidoM(e.dataItem.apellido_m);
                            setRFC(e.dataItem.RFC);
                            setCorreo(e.dataItem.correo);
                            setTelefono(e.dataItem.telefono);
                            setUsoCFDI(e.dataItem.CFDI);
                            setUpdate(true);
                            setModal(true);
                          }}
                        >
                          Editar
                        </button>
                      </td>
                    );
                  }}
                />
              ) : (
                ""
              )}
              {user_rol === "super administrador" || user_rol === "cliente" ? (
                <GridColumn
                  field="Eliminar"
                  filter={false}
                  filterable={false}
                  cell={(o) => {
                    let id = o.dataItem.id;
                    return (
                      <td style={{marginTop: 10, textAlign: "center"}}>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => {
                            Eliminar(id);
                          }}
                        >
                          Eliminar
                        </button>
                      </td>
                    );
                  }}
                />
              ) : (
                ""
              )}
              {/* <GridColumn
                            field="Monto"
                            filter="text"
                            format={null}
                            cell={(a) => {
                                let cantidad = a.dataItem.cantidad;
                                return (
                                    <td style={{ marginTop: 10, textAlign: 'right' }}>${formatNumber(cantidad)}</td>
                                );
                            }}
                        /> */}
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
