/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
} from "reactstrap";
import usePresupuesto from "../../../hoock/usePresupuesto";
import useHijos from "../../../hoock/useHijos";
import Swal from "sweetalert2";
import Select from "react-select";

const unidades = [
  {value: "unico", label: "Unico"},
  {value: "semanal", label: "Semanal"},
  {value: "quincenal", label: "Quincenal"},
  {value: "mensual", label: "Mensual"},
  {value: "bimestral", label: "Bimestral"},
  {value: "trimestral", label: "Trimestral"},
  {value: "semestral", label: "Semestral"},
  {value: "anual", label: "Anual"},
];

export default function ModalAdd({
  ModalEditarPresu,
  toggleEditarPre,
  IdPresupuesto,
  token,
  cliente_id,
  id,
  PadresMontoGetService,
  PresupuestosGetService,
}) {
  const {
    error,
    success,
    message,
    Proveedores,
    ProveedorGetService,
    PresupuestoHijosGetService,
    Presupuesto,
    Variables,
    VariableGetService,
    VariableAddService,
    HijosHijosUpdate_Service,
  } = useHijos();
  const [Form, setForm] = useState({
    proveedores: [],
    proveedor: "",
    descripcion: "",
    cuenta_presupuestal: "",
    Variables: [],
    VariableID: "",
    MostrarVariable: false,
    isChecked: true,
    cantidad_veces_pagos: 0,
    unidad: "",
    x_cuantas_veces: "",
    costo_unitario: 0,
    fecha_inical_pagos: "",
    opcion_pagos: "",
    Opcion: [],
    cuantos_pagos: 0,
    subtotal: 0,
    iva: 0,
    total: 0,
  });

  const [NombreVari, setNombreVari] = useState("");
  const [NumeracionVari, setNumeracionVari] = useState("");

  useEffect(() => {
    ProveedorGetService(cliente_id, token);
  }, [ProveedorGetService]);

  useEffect(() => {
    VariableGetService(token);
  }, [VariableGetService]);

  useEffect(() => {
    PresupuestoHijosGetService(IdPresupuesto, token);
  }, [PresupuestoHijosGetService]);

  useEffect(() => {
    if (Presupuesto) {
      Presupuesto.map((item) => {
        setForm({
          ...Form,
          proveedor: item.proveedor_id ? item.proveedor_id : "",
          descripcion: item.nombre,
          cuenta_presupuestal: item.cuenta_presupuental,
          VariableID: item.variables_id ? item.variables_id : "",
          isChecked: item.con_iva == "1" ? true : false,
          cantidad_veces_pagos: item.cantidad_veces_pagos,
          unidad: item.unidad,
          x_cuantas_veces: item.x_cuantas_veces,
          costo_unitario: item.costo_unitario,
          fecha_inical_pagos: item.fecha_inicial_pagos,
          opcion_pagos: item.opcion_pagos,
          cuantos_pagos: item.cuantos_pagos,
          subtotal: item.subtotal,
          iva: item.iva,
          total: item.total,
        });
      });
    }
  }, [Presupuesto]);

  const guardarVariable = () => {
    const data = new FormData();
    data.append("nombre", NombreVari);
    data.append("cantidad", NumeracionVari);

    VariableAddService(data, token);
    Swal.fire({
      title: "Registrando variable",
      text: "Loading...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      icon: "question",
      onOpen: () => {
        Swal.showLoading();
      },
    });
  };

  useEffect(() => {
    if (success === true) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
      VariableGetService();
      setNombreVari("");
      setNumeracionVari("");
    }
  }, [VariableGetService, success]);

  const handleOnChange = () => {
    setForm({...Form, isChecked: !Form.isChecked});
  };

  const functionVariable = (
    precio,
    cantidad,
    x,
    cuantosPagos,
    options = null
  ) => {
    let sub;
    if (cuantosPagos) {
      sub = cantidad * x * precio * cuantosPagos;
    } else {
      sub = cantidad * x * precio;
    }

    // validar si checkbox esta activo
    if (Form.isChecked === true) {
      setForm({
        ...Form,
        subtotal: sub,
        iva: sub * 0.16,
        total: Math.round(sub * 1.16),
        cuantos_pagos: cuantosPagos,
        costo_unitario: precio,
        cantidad_veces_pagos: cantidad,
        x_cuantas_veces: x,
      });
    } else {
      setForm({
        ...Form,
        subtotal: sub,
        iva: 0,
        total: sub,
        cuantos_pagos: cuantosPagos,
        costo_unitario: precio,
        cantidad_veces_pagos: cantidad,
        x_cuantas_veces: x,
      });
    }

    // if (options) {
    //   const newArreglo = Arreglo.map((item, i) => {
    //     if (i === index) {
    //       return {
    //         ...item,
    //         Costo: precio,
    //         Cantidad: cantidad,
    //         X: x,
    //         Subtota: sub,
    //         Iva: sub * 0.16,
    //         Total: Math.round(sub * 1.16),
    //         Cuantos: cuantosPagos,
    //         [options[1]]: options[0],
    //       };
    //     } else {
    //       return item;
    //     }
    //   });
    //   setArreglo(newArreglo);
    // } else {
    //   const newArreglo = Arreglo.map((item, i) => {
    //     if (i === index) {
    //       return {
    //         ...item,
    //         Costo: precio,
    //         Cantidad: cantidad,
    //         X: x,
    //         Subtota: sub,
    //         Iva: sub * 0.16,
    //         Total: Math.round(sub * 1.16).toFixed(2),
    //         Cuantos: cuantosPagos,
    //       };
    //     } else {
    //       return item;
    //     }
    //   });
    //   setArreglo(newArreglo);
    // }
  };

  useEffect(() => {
    if (Form.isChecked === true) {
      setForm({
        ...Form,
        iva: Form.subtotal * 0.16,
        total: Math.round(Form.subtotal * 1.16),
      });
    } else {
      setForm({...Form, iva: 0, total: Form.subtotal});
    }
  }, [Form.isChecked]);

  const guardar = () => {
    const data = new FormData();
    data.append("id", IdPresupuesto);
    data.append("proveedor_id", Form.proveedor);
    data.append("nombre", Form.descripcion);
    data.append("variables_id", Form.VariableID);
    data.append("con_iva", Form.isChecked ? 1 : 0);
    data.append("cantidad_veces_pagos", Form.cantidad_veces_pagos);
    data.append("unidad", Form.unidad);
    data.append("x_cuantas_veces", Form.x_cuantas_veces);
    data.append("costo_unitario", Form.costo_unitario);
    data.append("fecha_inicial_pagos", Form.fecha_inical_pagos);
    data.append("opcion_pagos", Form.opcion_pagos);
    data.append("cuantos_pagos", Form.cuantos_pagos);
    data.append("subtotal", Form.subtotal);
    data.append("iva", Form.iva);
    data.append("total", Form.total);

    HijosHijosUpdate_Service(data, token);

    Swal.fire({
      title: "Actualizado cuenta hijo",
      text: "Loading...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      icon: "question",
      onOpen: () => {
        Swal.showLoading();
      },
    });
  
  };

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
      toggleEditarPre();
    }
  }, [message, success]);

  useEffect(() => {
    if (success === true) {
      PresupuestosGetService(id, token);
      toggleEditarPre();
    }
  }, [PresupuestosGetService, success]);


  return (
    <>
      <Modal
        isOpen={ModalEditarPresu}
        toggle={toggleEditarPre}
        size="lg"
        style={{maxWidth: "1600px", width: "80%"}}
      >
        <ModalHeader className="backend_titulo" toggle={toggleEditarPre}>
          Editar Presupuesto
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-6">
              <label>Proveedor:</label>
              <Select
                value={Form.proveedores}
                options={Proveedores}
                onChange={(e) => {
                  setForm({...Form, proveedores: e, proveedor: e.value});
                }}
                placeholder={Form.proveedor ? Form.proveedor : "Proveedor"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <label>Descripcion:</label>
              <Input
                placeholder="Nombre"
                value={Form.descripcion}
                onChange={(e) => {
                  setForm({...Form, descripcion: e.target.value});
                }}
              />
            </div>
            <div className="col-md-3">
              <label>Cuenta Presupuestal:</label>

              <Input
                placeholder="Cuenta Presupuestal"
                disabled={true}
                value={Form.cuenta_presupuestal}
              />
            </div>
            <div className="col-md-4">
              <label>Nombre de la Variable:</label>
              <div className="row">
                <div className="col-md-6">
                  <Select
                    value={Form.Variables}
                    onChange={(e) => {
                      setForm({
                        ...Form,
                        VariableID: e.value,
                        Variables: e,
                      });
                      functionVariable(
                        e.precio,
                        Form.cantidad_veces_pagos,
                        Form.x_cuantas_veces,
                        Form.cuantos_pagos
                      );
                    }}
                    options={Variables}
                    placeholder={"Variable"}
                  />
                </div>
                <div className="col-md-6">
                  <button
                    className="btn btn-info btn-sm"
                    onClick={(e) => {
                      setForm({
                        ...Form,
                        MostrarVariable: !Form.MostrarVariable,
                      });
                    }}
                  >
                    <i className="mdi mdi-plus-circle"></i> Agregar variable
                  </button>
                </div>
              </div>
              {Form.MostrarVariable === true ? (
                <div className="mb-3 mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nombre variable"
                    aria-label="Nombre variable"
                    value={NombreVari}
                    onChange={(e) => setNombreVari(e.target.value)}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Valor variable"
                    aria-label="Valor variable"
                    value={NumeracionVari}
                    onChange={(e) => setNumeracionVari(e.target.value)}
                  />
                  <button
                    className="btn btn-secondary"
                    type="button"
                    id="button-addon2"
                    onClick={() => guardarVariable()}
                  >
                    Agregar
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-2">
              <div className="form-check mt-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={Form.isChecked}
                  onChange={handleOnChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexCheckIndeterminate"
                >
                  Agregar IVA
                </label>
              </div>
            </div>
            <div className="col-md-3">
              <label>Cantidad:</label>

              <Input
                type={"number"}
                placeholder="Cantidad"
                value={Form.cantidad_veces_pagos}
                onChange={(e) => {
                  functionVariable(
                    Form.costo_unitario,
                    e.target.value,
                    Form.x_cuantas_veces,
                    Form.cuantos_pagos
                  );
                }}
              />
            </div>
            <div className="col-md-3">
              <label>Unidad:</label>

              <Input
                placeholder="Unidad"
                value={Form.unidad}
                onChange={(e) => {
                  setForm({...Form, unidad: e.target.value});
                }}
              />
            </div>
            <div className="col-md-3">
              <label>X:</label>

              <Input
                type={"number"}
                placeholder="X"
                value={Form.x_cuantas_veces}
                onChange={(e) => {
                  functionVariable(
                    Form.costo_unitario,
                    Form.cantidad_veces_pagos,
                    e.target.value,
                    Form.cuantos_pagos
                  );
                }}
              />
            </div>
            <div className="col-md-3">
              <label>Costo:</label>

              <Input
                placeholder="Costo"
                value={Form.costo_unitario}
                disabled={Form.VariableID === "" ? false : true}
                onChange={(e) => {
                  functionVariable(
                    e.target.value,
                    Form.cantidad_veces_pagos,
                    Form.x_cuantas_veces,
                    Form.cuantos_pagos
                  );
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <label>Fecha primer pago:</label>

              <Input
                type="date"
                value={Form.fecha_inical_pagos}
                onChange={(e) => {
                  setForm({...Form, fecha_inical_pagos: e.target.value});
                }}
              />
            </div>
            <div className="col-md-3">
              <label>Opcion de pagos:</label>
              <Select
                value={Form.Opcion}
                onChange={(e) => {
                  setForm({
                    ...Form,
                    opcion_pagos: e.value,
                    Opcion: e,
                  });
                }}
                options={unidades}
                placeholder={"Opcion de pagos"}
              />
            </div>
            <div className="col-md-3">
              <label>Cuantos pagos:</label>

              <Input
                type={"number"}
                placeholder="Cuantos pagos"
                value={Form.cuantos_pagos}
                onChange={(e) => {
                  functionVariable(
                    Form.costo_unitario,
                    Form.cantidad_veces_pagos,
                    Form.x_cuantas_veces,
                    e.target.value
                  );
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <strong>Subtotal:</strong>

              <Input
                placeholder="Subtota"
                value={Form.subtotal}
                disabled={true}
                onChange={(e) => {
                  setForm({...Form, subtotal: e.target.value});
                }}
              />
            </div>
            <div className="col-md-3">
              {Form.isChecked === true ? (
                <>
                  <strong>Iva:</strong>

                  <Input
                    placeholder="Iva"
                    value={Form.iva}
                    disabled={true}
                    onChange={(e) => {
                      setForm({...Form, iva: e.target.value});
                    }}
                  />
                </>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-3">
              <strong>Total:</strong>

              <Input
                placeholder="Total"
                value={Form.total}
                disabled={true}
                onChange={(e) => {
                  setForm({...Form, total: e.target.value});
                }}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => guardar()}>Actualizar</Button>
          <Button color="danger" onClick={toggleEditarPre}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
