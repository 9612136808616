/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import useUsuarios from "../../hoock/useUsuarios";
import ModalAdd from "./Modals/ModalAdd";
import {
  Grid,
  GridColumn,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { filterBy } from "@progress/kendo-data-query";
import Link from "../../components/Form/Link";
import Title from "../../components/Form/Title";
import { hideLoader, showLoader, setTitle } from "../../reducers/loaderReducer";
import { useDispatch } from "react-redux";
import useLogin from "../../hoock/useLogin";
import Swal from "sweetalert2";

const PageTemplate = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        left: "10px",
      }}
    >
      Page {props.pageNum} of {props.totalPages}
    </div>
  );
};

const initialDataState = {
  skip: 0,
  take: 10,
};

const initialFilter = {
  logic: "and",
  filters: [],
};

const initialSort = [
  // {
  //   field: "modelo",
  //   dir: "desc",
  // },
  // {
  //   field: "serie",
  //   dir: "desc",
  // },
  // {
  //   field: "fecha_venta",
  //   dir: "desc",
  // },
  // {
  //   field: "Venta",
  //   dir: "desc",
  // },
];

export default function Index() {
  const { logout, token, user_rol } = useLogin();
  const dispatch = useDispatch();
  const { loading, error, message, success, Usuarios, UsuariosGetService, UsuariosDeleteService } = useUsuarios();
  const [modal, setModal] = useState(false);
  const [Update, setUpdate] = useState(false);
  const [Id, setId] = useState("");
  const [Nombre, setNombre] = useState("");
  const [Correo, setCorreo] = useState("");
  const [Rol, setRol] = useState("");
  const [Status, setStatus] = useState("");
  const [sort, setSort] = React.useState(initialSort);
  const [filter, setFilter] = React.useState(initialFilter);
  const [page, setPage] = React.useState(initialDataState);
  let i = 1;

  const toggle = () => {
    setModal(!modal);
    setUpdate(false);
    setId("");
    setNombre("");
    setCorreo("");
    setRol("");
    setStatus("");
  };

  useEffect(() => {
    dispatch(setTitle("Descargando usuarios"));
    dispatch(showLoader());
    UsuariosGetService(token);
    return () => { };
  }, []);

  useEffect(() => {
    if (Usuarios.length > 0) {
      dispatch(hideLoader());
      dispatch(setTitle(""));
    }
    return () => { };
  }, [Usuarios]);

  useEffect(() => {
    if (success === true) {
      UsuariosGetService(token);
    }
  }, [UsuariosGetService, success]);

  const pageChange = (event) => {
    setPage(event.page);
  };

  const formatNumber = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  const Eliminar = (e) => {
    const data = new FormData();
    data.append("id", e);
    UsuariosDeleteService(data, token);

    Swal.fire({
      title: "Espere por favor...",
      text: "Cargando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      icon: 'question',
      onOpen: () => {
        Swal.showLoading();
      }
    });
  }

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, success])

  return (
    <>
      <ModalAdd
        modal={modal}
        toggle={toggle}
        Nombre={Nombre}
        Correo={Correo}
        Rol={Rol}
        Status={Status}
        Update={Update}
        Id={Id}
        token={token}
        setNombre={setNombre}
        setCorreo={setCorreo}
        setRol={setRol}
        setStatus={setStatus}
        UsuariosGetService={UsuariosGetService}
      />
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Title title="Listado de Usuarios" className="text-left" />
          </div>
          <div className="col-md-6 text-end">
            <a className="btn btn-info" onClick={toggle}>
              Agregar usuario
            </a>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <Grid
              sortable={true}
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
              filterable={true}
              filter={filter}
              onFilterChange={(e) => setFilter(e.filter)}
              pageable={true}
              data={filterBy(
                Usuarios.slice(page.skip, page.take + page.skip),
                filter
              )}
              skip={page.skip}
              take={page.take}
              total={Usuarios.length}
              onPageChange={pageChange}
              style={{
                height: "420px",
              }}
            >
              <GridNoRecords>No hay informacion disponible</GridNoRecords>
              <GridColumn field="name" title="Nombre" />
              <GridColumn field="email" title="Correo" filter="text" />
              <GridColumn
                field="Roles"
                filter={false}
                filterable={false}
                cell={(a) => {
                  let roles = a.dataItem.rol;
                  return (
                    <td className={
                      roles == 'super administrador' &&
                      'text-primary'
                      || roles == 'administrador' &&
                      'text-info'
                      || roles == 'contabilidad' &&
                      'text-danger'
                      || roles == 'proveedor' &&
                      'text-success'
                      || roles == 'cliente' &&
                      'text-warning'
                    } style={{ textTransform: 'uppercase' }}>{roles}</td>
                  );
                }}
              />
              <GridColumn
                field="Status"
                filter={false}
                filterable={false}
                cell={(a) => {
                  let status = a.dataItem.status;
                  return (
                    <td className=" text-center">
                      {
                        status == 'Activo' &&
                        <div className="badge badge-outline-success text-success">Activo</div>
                        || status == 'Cancelado' &&
                        <div className="badge badge-outline-danger text-danger">Cancelado</div>
                      }
                    </td>
                  );
                }}
              />
              {
                user_rol === "super administrador" || user_rol === "cliente"
                  ?
                  <GridColumn
                    field="Editar"
                    filter={false}
                    filterable={false}
                    cell={(e) => {
                      let id = e.dataItem.id;
                      let name = e.dataItem.name;
                      let email = e.dataItem.email;
                      let rol = e.dataItem.rol;
                      let status = e.dataItem.status;
                      return (
                        <td style={{ marginTop: 10, textAlign: "center" }}>
                          <button
                            className="btn btn-warning btn-sm"
                            onClick={() => {
                              setId(id);
                              setNombre(name);
                              setCorreo(email);
                              setRol(rol);
                              setStatus(status);
                              setUpdate(true);
                              setModal(true);
                            }}
                          >
                            Editar
                          </button>
                        </td>
                      );
                    }}
                  />
                  :
                  ''
              }
              {
                user_rol === "super administrador" || user_rol === "cliente"
                  ?
                  <GridColumn
                    field="Eliminar"
                    filter={false}
                    filterable={false}
                    cell={(o) => {
                      let id = o.dataItem.id;
                      return (
                        <td style={{ marginTop: 10, textAlign: "center" }}>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => { Eliminar(id) }}
                          >
                            Eliminar
                          </button>
                        </td>
                      );
                    }}
                  />
                  :
                  ''
              }
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
