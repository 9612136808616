/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import useProyectos from "../../hoock/useProyectos";
import ModalAdd from "./Modals/ModalAdd";
import { Link as LinkReact } from "react-router-dom";
import {
  Grid,
  GridColumn,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import { filterBy } from "@progress/kendo-data-query";
import Title from "../../components/Form/Title";
import { hideLoader, showLoader, setTitle } from "../../reducers/loaderReducer";
import { useDispatch } from "react-redux";
import useLogin from "../../hoock/useLogin";
import Swal from "sweetalert2";

const initialDataState = {
  skip: 0,
  take: 10,
};

const initialFilter = {
  logic: "and",
  filters: [],
};

const initialSort = [
  // {
  //   field: "modelo",
  //   dir: "desc",
  // },
  // {
  //   field: "serie",
  //   dir: "desc",
  // },
  // {
  //   field: "fecha_venta",
  //   dir: "desc",
  // },
  // {
  //   field: "Venta",
  //   dir: "desc",
  // },
];

export default function Index() {
  const { logout, token, user_rol, cliente_id } = useLogin();
  const dispatch = useDispatch();
  const {
    loading,
    error,
    message,
    success,
    Proyectos,
    ProyectosGetService,
    ProyectosDeleteService,
  } = useProyectos();
  const [modal, setModal] = useState(false);
  const [Update, setUpdate] = useState(false);
  const [Id, setId] = useState("");
  const [Client, setClient] = useState("");
  const [Nombre, setNombre] = useState("");
  const [Empresa, setEmpresa] = useState("");
  const [FechaInicial, setFechaInicial] = useState("");
  const [FechaFinal, setFechaFinal] = useState("");
  const [NumeroUsers, setNumeroUsers] = useState("");
  const [TotalPersonal, setTotalPersonal] = useState("");
  const [Status, setStatus] = useState("");
  const [Productor, setProductor] = useState("");
  const [Director, setDirector] = useState("");
  const [Ejecutivo, setEjecutivo] = useState("");
  const [sort, setSort] = React.useState(initialSort);
  const [filter, setFilter] = React.useState(initialFilter);
  const [page, setPage] = React.useState(initialDataState);
  let i = 1;

  const toggle = () => {
    setModal(!modal);
    setUpdate(false);
    setId("");
    setClient("");
    setNombre("");
    setEmpresa("");
    setFechaInicial("");
    setFechaFinal("");
    setNumeroUsers("");
    setTotalPersonal("");
    setStatus("");
    setProductor("");
    setDirector("");
    setEjecutivo("");
  };

  useEffect(() => {
    dispatch(setTitle("Descargando proyectos"));
    dispatch(showLoader());
    ProyectosGetService(token);
    return () => {};
  }, []);

  useEffect(() => {
    if (Proyectos.length >= 0) {
      dispatch(hideLoader());
      dispatch(setTitle(""));
    }
    return () => {};
  }, [Proyectos]);

  useEffect(() => {
    if (success === true) {
      ProyectosGetService(token);
    }
  }, [ProyectosGetService, success]);

  const pageChange = (event) => {
    setPage(event.page);
  };

  const formatNumber = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  const Eliminar = (e) => {
    const data = new FormData();
    data.append("id", e);
    ProyectosDeleteService(data, token);

    Swal.fire({
      title: "Espere por favor...",
      text: "Cargando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      icon: "question",
      onOpen: () => {
        Swal.showLoading();
      },
    });
  };

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, success]);

  return (
    <>
      <ModalAdd
        modal={modal}
        toggle={toggle}
        Client={Client}
        Nombre={Nombre}
        Empresa={Empresa}
        FechaInicial={FechaInicial}
        FechaFinal={FechaFinal}
        NumeroUsers={NumeroUsers}
        TotalPersonal={TotalPersonal}
        Status={Status}
        Productor={Productor}
        Director={Director}
        Ejecutivo={Ejecutivo}
        Update={Update}
        Id={Id}
        token={token}
        user_role={user_rol}
        cliente_id={cliente_id}
        setClient={setClient}
        setNombre={setNombre}
        setEmpresa={setEmpresa}
        setFechaInicial={setFechaInicial}
        setFechaFinal={setFechaFinal}
        setNumeroUsers={setNumeroUsers}
        setTotalPersonal={setTotalPersonal}
        setStatus={setStatus}
        setProductor={setProductor}
        setDirector={setDirector}
        setEjecutivo={setEjecutivo}
        ProyectosGetService={ProyectosGetService}
      />
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Title title="Listado de Proyectos" className="text-left" />
          </div>
          {user_rol === "super administrador" || user_rol === "cliente" ? (
            <div className="col-md-6 text-end">
              <a className="btn btn-info" onClick={toggle}>
                <i className="mdi mdi-calendar-plus"></i> Agregar proyecto
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <Grid
              sortable={true}
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
              filterable={true}
              filter={filter}
              onFilterChange={(e) => setFilter(e.filter)}
              pageable={true}
              data={filterBy(
                Proyectos.slice(page.skip, page.take + page.skip),
                filter
              )}
              skip={page.skip}
              take={page.take}
              total={Proyectos.length}
              onPageChange={pageChange}
              style={{
                height: "420px",
              }}
            >
              <GridNoRecords>No hay informacion disponible</GridNoRecords>
              <GridColumn field="nombre" title="Nombre" width={200} />
              <GridColumn field="empresa" title="Empresa"  width={200}/>
              <GridColumn field="fecha_inical" title="Fecha inical" width={100} />
              <GridColumn field="fecha_final" title="Fecha final" width={100} />
              {user_rol === "super administrador" || user_rol === "cliente" ? (
                <GridColumn field="numero_usuarios" title="Numero usuarios" width={150} />
              ) : (
                ""
              )}
              {user_rol === "super administrador" || user_rol === "cliente" ? (
                <GridColumn field="total_personal" title="Total de personal" width={150} />
              ) : (
                ""
              )}
              <GridColumn field="productor" title="Productor" width={200} />
              <GridColumn field="director_de_produccion" title="Director de Produccion" width={150} />
              <GridColumn field="productor_ejecutivo" title="Productor Ejectivo" width={150} />
              {user_rol === "super administrador" || user_rol === "cliente" ? (
                <GridColumn
                  field="Presupuesto"
                  filter={false}
                  filterable={false}
                  width={150}
                  cell={(e) => {
                    let id = e.dataItem.id;
                    return (
                      <td style={{ marginTop: 10, textAlign: "center" }}>
                        <LinkReact
                          to={"/presupuestos/" + id}
                          className="btn btn-primary btn-sm"
                        >
                          <i className="mdi mdi-eye"></i> Presupuesto
                        </LinkReact>
                      </td>
                    );
                  }}
                />
              ) : (
                ""
              )}
              {user_rol === "super administrador" || user_rol === "cliente" ? (
                <GridColumn
                  field="Dashboard"
                  filter={false}
                  filterable={false}
                  width={150}
                  cell={(e) => {
                    let id = e.dataItem.id;
                    return (
                      <td style={{ marginTop: 10, textAlign: "center" }}>
                        <LinkReact
                          to={"/dashboard-cliente/" + id}
                          className="btn btn-info btn-sm"
                        >
                          <i className="mdi mdi-view-grid"></i> Dashboard
                        </LinkReact>
                      </td>
                    );
                  }}
                />
              ) : (
                ""
              )}
              {user_rol === "super administrador" || user_rol === "cliente" ? (
                <GridColumn
                  field="Editar"
                  filter={false}
                  filterable={false}
                  width={150}
                  cell={(e) => {
                    let id = e.dataItem.id;
                    let cliente = e.dataItem.nombre_comercial;
                    let nombre = e.dataItem.nombre;
                    let empresa = e.dataItem.empresa;
                    let fecha_inical = e.dataItem.fecha_inical;
                    let fecha_final = e.dataItem.fecha_final;
                    let numero_usuarios = e.dataItem.numero_usuarios;
                    let total_personal = e.dataItem.total_personal;
                    let status = e.dataItem.status;
                    let productor = e.dataItem.productor;
                    let director = e.dataItem.director_de_produccion;
                    let ejecutivo = e.dataItem.productor_ejecutivo  ;
                    return (
                      <td style={{ marginTop: 10, textAlign: "center" }}>
                        <button
                          className="btn btn-warning btn-sm"
                          onClick={() => {
                            setId(id);
                            setClient(cliente);
                            setNombre(nombre);
                            setEmpresa(empresa);
                            setFechaInicial(fecha_inical);
                            setFechaFinal(fecha_final);
                            setNumeroUsers(numero_usuarios);
                            setTotalPersonal(total_personal);
                            setStatus(status);
                            setProductor(productor);
                            setDirector(director);
                            setEjecutivo(ejecutivo);
                            setUpdate(true);
                            setModal(true);
                          }}
                        >
                          <i className="mdi mdi-grease-pencil"></i> Editar
                        </button>
                      </td>
                    );
                  }}
                />
              ) : (
                ""
              )}
              {user_rol === "super administrador" || user_rol === "cliente" ? (
                <GridColumn
                  field="Eliminar"
                  filter={false}
                  filterable={false}
                  width={150}
                  cell={(o) => {
                    let id = o.dataItem.id;
                    return (
                      <td style={{ marginTop: 10, textAlign: "center" }}>
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => {
                            Eliminar(id);
                          }}
                        >
                          <i className="mdi mdi-delete-forever"></i> Eliminar
                        </button>
                      </td>
                    );
                  }}
                />
              ) : (
                ""
              )}
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
