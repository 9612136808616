/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
} from "reactstrap";
import useCliente from "../../../hoock/useCliente";
import Swal from "sweetalert2";
import {
  EstadosGet,
  MunicipiosGet,
  CPGet,
  ColoniaGet,
} from "../../../service/estados";

export default function ModalDir({
  ModalCli,
  toggleCliente,
  token,
  user_id,
  Fotos,
  setFotos,
  NombreComercial,
  setNombreComercial,
  Telefono,
  setTelefono,
  RFC,
  setRFC,
  Tipo,
  setTipo,
  Calle,
  setCalle,
  Exterior,
  setExterior,
  Interior,
  setInterior,
  Colonia,
  setColonia,
  CP,
  setCP,
  MunicipioId,
  setMunicipioId,
  EstadoId,
  setEstadoId,
  Pais,
  setPais,
  PerfilCliGetService,
}) {
  const { error, message, success, ClienteUpdateService } = useCliente();

  useEffect(() => {
    if (success === true) {
      PerfilCliGetService(user_id, token);
    }
  }, [PerfilCliGetService, success]);

  const [estadoArray, setestadoArray] = useState([]);
  const [municipioArray, setmunicipioArray] = useState([]);
  const [cpArray, setcpArray] = useState([]);
  const [coloniaArray, setcoloniaArray] = useState([]);

  const createUser = () => {
    const data = new FormData();
    data.append("id", user_id);
    data.append("nombre_comercial", NombreComercial);
    data.append("telefono", Telefono);
    data.append("tipo", Tipo);
    data.append("RFC", RFC);
    data.append("calle", Calle);
    data.append("exterior", Exterior);
    data.append("inerior", Interior);
    data.append("colonia", Colonia);
    data.append("municipio", MunicipioId);
    data.append("estado", EstadoId);
    data.append("cp", CP);
    data.append("pais", Pais);

    ClienteUpdateService(data, token);

    Swal.fire({
      title: "Actualizando cliente",
      text: "Loading...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      icon: "question",
      onOpen: () => {
        Swal.showLoading();
      },
    });
  };

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (success) {
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
      toggleCliente();
    }
  }, [message, success]);

  useEffect(() => {
    EstadosGet().then((res) => {
      setestadoArray(res);
    });
    return () => {};
  }, []);

  useEffect(() => {
    if (EstadoId !== "" && EstadoId !== null) {
      MunicipiosGet({ id: EstadoId }).then((res) => {
        setmunicipioArray(res);
      });
    }
    return () => {};
  }, [EstadoId]);

  return (
    <>
      <Modal isOpen={ModalCli} toggle={toggleCliente} size="lg">
        <ModalHeader className="backend_titulo" toggle={toggleCliente}>
          Actualizar Cliente
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-6">
              <label>Nombre comercial:</label>
              
                <Input
                  type="text"
                  placeholder="NombreComercial"
                  value={NombreComercial}
                  onChange={(e) => setNombreComercial(e.target.value)}
                />
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Tipo:</label>
              
                <Input
                  type="text"
                  placeholder="Tipo"
                  value={Tipo}
                  onChange={(e) => setTipo(e.target.value)}
                />
              
            </div>
            <div className="col-md-6">
              <label>RFC:</label>
              
                <Input
                  type="text"
                  placeholder="RFC"
                  value={RFC}
                  onChange={(e) => setRFC(e.target.value)}
                />
              
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label>Telefono:</label>
              
                <Input
                  type="text"
                  placeholder="Telefono"
                  value={Telefono}
                  onChange={(e) => setTelefono(e.target.value)}
                />
              
            </div>
            <div className="col-md-6">
              <label>Calle:</label>
              
                <Input
                  type="text"
                  placeholder="Calle"
                  value={Calle}
                  onChange={(e) => setCalle(e.target.value)}
                />
              
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label>Exterior:</label>
              
                <Input
                  type="text"
                  placeholder="Exterior"
                  value={Exterior}
                  onChange={(e) => setExterior(e.target.value)}
                />
              
            </div>
            <div className="col-md-6">
              <label>Interior:</label>
              
                <Input
                  type="text"
                  placeholder="Interior"
                  value={Interior}
                  onChange={(e) => setInterior(e.target.value)}
                />
              
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label>EstadoId:</label>
              <select
                value={EstadoId}
                onChange={(e) => setEstadoId(e.target.value)}
                name="estado"
                className="form-control"
              >
                <option value="">Selecciona un estado</option>
                {estadoArray.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6">
              <label>MunicipioId:</label>
              <select
                value={MunicipioId}
                onChange={(e) => setMunicipioId(e.target.value)}
                className="form-control"
                name="municipio"
              >
                <option value="">Selecciona un municipio</option>
                {municipioArray.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label>CP:</label>
              
                <Input
                  type="text"
                  placeholder="CP"
                  value={CP}
                  onChange={(e) => setCP(e.target.value)}
                />
              
            </div>
            <div className="col-md-6">
              <label>Colonia:</label>
              
                <Input
                  type="text"
                  placeholder="Colonia"
                  value={Colonia}
                  onChange={(e) => setColonia(e.target.value)}
                />
              
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label>Pais:</label>
              
                <Input
                  type="text"
                  placeholder="Pais"
                  value={Pais}
                  onChange={(e) => setPais(e.target.value)}
                />
              
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="backend_boton" onClick={() => createUser()}>
            Actualizar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
