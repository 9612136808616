import { Button } from "@mui/material";
import React from "react";
export default function Input({ objValue, onChange, index, deleteField }) {
  const { label, type, value, disabled } = objValue;
  return (
    <div className="input-group">
      <label htmlFor={label}>{label}</label>
      <div className="input">
        <input
          type={type || "text"}
          id={label}
          value={value || ""}
          onChange={(e) => onChange(e, index)}
          className="form-control"
          placeholder={label}
        />
        {/* Add this */}
        
        <Button
          variant="contained"
          onClick={(e) => deleteField(e, index)}
          sx={{ ml: 1 }}
          color="error"
          disabled={disabled ? true : false}
        >
          X
        </Button>
      </div>
    </div>
  );
}
