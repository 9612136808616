import React from "react";
import { RotatingSquare, CirclesWithBar } from "react-loader-spinner";
import "../../style/Loader.scss";

const LoaderFullScreen = ({ hide, title = "Cargando información..." }) => {
  return (
    <div
      className={`loader d-flex flex-col justify-content-center ${
        hide ? "hide" : ""
      }`}
    >
      {title === "Descargando contabilidad" ? (
        <CirclesWithBar
          visible={hide ? false : true}
          type="ThreeDots"
          color="#f2f8ff"
          height={100}
          width={100}
        />
      ) : (
        <RotatingSquare
          visible={hide ? false : true}
          type="ThreeDots"
          color="#f2f8ff"
          height={100}
          width={100}
        />
      )}
      {!hide && <h3 className="text-white">{title}</h3>}
    </div>
  );
};

export default LoaderFullScreen;
