import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, Input } from 'reactstrap';
import useUsuarios from '../../../hoock/useUsuarios';
import Select from 'react-select';
import Swal from "sweetalert2";

const Roles = [
    { value: "super administrador", label: "Super administrador" },
    { value: "administrador", label: "Administrador" },
    { value: "contabilidad", label: "Contabilidad" },
    { value: "cliente", label: "Cliente" },
    { value: "proveedor", label: "Proveedor" },
];

const Estatus = [
    { value: "Activo", label: "Activo" },
    { value: "Cancelado", label: "Cancelado" }
];

export default function ModalAdd({
    modal,
    toggle,
    Nombre,
    Correo,
    Rol,
    Status,
    Update,
    Id,
    token,
    setNombre,
    setCorreo,
    setRol,
    setStatus,
    UsuariosGetService }) {

    const { loading, message, error, success, UsuariosAddService, UsuariosUpdateService } = useUsuarios();
    const [SelectedStatus, setSelectedStatus] = useState([]);
    const [SelectedRol, setSelectedRol] = useState([]);

    useEffect(() => {
        if (success === true) {
            UsuariosGetService(token)
        }
    }, [UsuariosGetService, success])

    const createUser = () => {
        if (Update) {
            const data = new FormData();
            data.append("id", Id);
            data.append("name", Nombre);
            data.append("email", Correo);
            data.append("rol", Rol);
            data.append("status", Status);

            UsuariosUpdateService(data, token);

            Swal.fire({
                title: "Actualizando usuario",
                text: "Loading...",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                icon: 'question',
                onOpen: () => {
                    Swal.showLoading();
                }
            });

        } else {
            const data = new FormData();
            data.append("name", Nombre);
            data.append("email", Correo);
            data.append("rol", Rol);
            data.append("status", Status);

            UsuariosAddService(data, token);

            Swal.fire({
                title: "Registrando usuario",
                text: "Loading...",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                icon: 'question',
                onOpen: () => {
                    Swal.showLoading();
                }
            });
        }
    }

    useEffect(() => {
        if (error) {
            Swal.close();
            Swal.fire({
                icon: "error",
                title: "Error",
                confirmButtonColor: "#3b82f6",
                text: message,
            });
        }
    }, [message, error])

    useEffect(() => {
        if (success) {
            Swal.close();
            Swal.fire({
                icon: "success",
                title: "Exito",
                confirmButtonColor: "#3b82f6",
                text: message,
            });
            setNombre('');
            setCorreo('');
            setRol('');
            setSelectedRol([]);
            toggle();
        }
    }, [message, success])

    return <>
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader className="backend_titulo" toggle={toggle}>Agregar usuario</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-12">
                      
                            <Input placeholder="nombre" value={Nombre} onChange={e => setNombre(e.target.value)} />
                        
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                      
                            <Input type='email' placeholder="Correo" value={Correo} onChange={e => setCorreo(e.target.value)} />
                        
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <Select
                            value={SelectedRol}
                            onChange={(e) => {
                                setRol(e.value);
                                setSelectedRol(e)
                            }}
                            options={Roles}
                            placeholder={Rol ? Rol : "Roles"}
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <Select
                            value={SelectedStatus}
                            onChange={(e) => {
                                setStatus(e.value);
                                setSelectedStatus(e)
                            }}
                            options={Estatus}
                            placeholder={Status ? Status : "Status"}
                        />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className="backend_boton" onClick={(() => createUser())}>Agregar</Button>{' '}
            </ModalFooter>
        </Modal>
    </>
}
