/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import useLogin from "../../hoock/useLogin";
import { useNavigate } from "react-router-dom";

export default function Index() {
  const navigate = useNavigate();
  const { login, error, message, isLoggedIn } = useLogin();
  const [form, setform] = useState({
    email: "",
    password: "",
  });
  const [loading, setloading] = useState(false)

  const handleChange = (e) => {
    setform({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const iniciarSesion = (e) => {
    e.preventDefault();
    setloading(true)
    if (form.email === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El campo email es obligatorio!",
      });
      setloading(false)

      return;
    }

    if (form.password === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El campo password es obligatorio!",
      });
      setloading(false)

      return;
    }

    login(form);
  };

  // useeffect error
  useEffect(() => {
    if (error) {
      setloading(false)
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message,
      });
    }
    return () => { };
  }, [error]);

  useEffect(() => {
    if (isLoggedIn) {
      setloading(false)
      navigate("/");
    }

    return () => { };
  }, [isLoggedIn]);

  return (
    <section className="vh-100" style={{ backgroundColor: "#9A616D" }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-10">
            <div className="card" style={{ borderRadius: "1rem" }}>
              <div className="row g-0">
                <div className="col-md-6 col-lg-5 d-none d-md-block">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
                    alt="login form"
                    className="img-fluid"
                    style={{ borderRadius: "1rem 0 0 1rem" }}
                  />
                </div>
                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                  <div className="card-body p-4 p-lg-5 text-black">
                    <form onSubmit={iniciarSesion}>
                      <div className="d-flex align-items-center mb-3 pb-1">
                        <i
                          className="fas fa-cubes fa-2x"
                          style={{ color: "#ff6219" }}
                        ></i>
                        <span className="h1 fw-bold mb-0">
                          Inicio de sesión
                        </span>
                      </div>
                      <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="email">
                          Correo electronico
                        </label>
                        <input
                          type="email"
                          id="email"
                          className="form-control form-control-lg"
                          value={form.email}
                          name="email"
                          onChange={handleChange}
                        />
                        
                      </div>

                      <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="password">
                          Contraseña
                        </label>
                        <input
                          type="password"
                          id="password"
                          className="form-control form-control-lg"
                          value={form.password}
                          name="password"
                          onChange={handleChange}
                        />
                       
                      </div>

                      <div className="pt-1 mb-4">
                        <button
                          className="btn btn-dark btn-lg btn-block"
                          type="submit"
                        >
                          {loading ? "Iniciando sesion..." : "Iniciar sesión"}
                        </button>
                      </div>

                      <a className="small text-muted" href="#!">
                        ¿Se te olvidó tu contraseña?
                      </a>
                      <p className="mb-5 pb-lg-2" style={{ color: "#393f81" }}>
                        ¿No tienes una cuenta?{" "}
                        <a href="/register" style={{ color: "#393f81" }}>
                          Registrarse aquí
                        </a>
                      </p>
                      <a href="/" className="small text-muted">
                        Términos de Uso.
                      </a>
                      <a href="/" className="small text-muted">
                        Política de privacidad
                      </a>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
