import React from "react";

export default function Pagination({ count, page, handlePageChange, ...rest }) {
  return (
    <nav aria-label="Page navigation example" {...rest}>
      <ul class="pagination justify-content-center">
        <li class="page-item ">
          <button
            disabled={page === 1 ? true : false}
            onClick={() => {
              handlePageChange(page - 1, 1);
            }}
            class="page-link"
          >
            Anterior
          </button>
        </li>
        {[...Array(count)].map((value, index) => {
          return index + 1 === page ? (
            <li key={index} class="page-item active">
              <button
                onClick={() => {
                  handlePageChange(index + 1);
                }}
                class="page-link"
              >
                {index + 1}
              </button>
            </li>
          ) : (
            <li class="page-item" key={index}>
              <button
                onClick={() => {
                  handlePageChange(index + 1);
                }}
                class="page-link"
              >
                {index + 1}
              </button>
            </li>
          );
        })}
        <li class="page-item">
          <button
            class="page-link"
            onClick={() => {
              handlePageChange(page + 1, 0);
            }}
            disabled={page === count ? true : false}
          >
            Siguiente
          </button>
        </li>
      </ul>
    </nav>
  );
}
