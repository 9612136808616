/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Editor } from "@tinymce/tinymce-react";
import { ProyectosGet } from "../../service/proyectos";
import useLogin from "../../hoock/useLogin";
import {
  ContratosGet,
  ContratosAdd,
  ContratosUpdate,
  ContratosDelete,
} from "../../service/contratos";
import Add from "./Modal/Add";
import Update from "./Modal/Update";
import Swal from "sweetalert2";
import { ConfiguracionGet } from "../../service/configuracion";

export default function Contratos() {
  const { token, user_rol } = useLogin();
  const editorRef = React.useRef(null);
  const editorActualizarRef = React.useRef(null);
  const [arrayContratosProd, setarrayContratosProd] = React.useState([]);
  const [openAgregar, setopenAgregar] = React.useState(false);
  const [openActualizar, setopenActualizar] = React.useState(false);
  const [arrayProyectos, setarrayProyectos] = React.useState([]);
  const [form, setform] = React.useState({
    plantilla_nombre: "",
    plantilla_descripcion: "",
    plantilla_estado: "",
    proyecto_id: "",
    plantilla_value: "",
    estatus: "En proceso",
  });
  const [proveedores, setproveedores] = React.useState([]);

  const toggleAgregar = () => setopenAgregar(!openAgregar);
  const toggleActualizar = () => {
    setopenActualizar(!openActualizar);
  };

  React.useEffect(() => {
    ProyectosGet(token).then((res) => {
      setarrayProyectos(res);
    });

    ContratosGet(token).then((res) => {
      setarrayContratosProd(res?.data ?? []);
    });

    ConfiguracionGet(token).then((response) => {
      if (response.success) {
        const { data } = response;
        data.preguntas_json = JSON.parse(data.preguntas);
        setproveedores(data.preguntas_json);
      }
    });

    return () => {};
  }, []);

  React.useEffect(() => {
    if (openActualizar) {
      setTimeout(() => {
        editorActualizarRef.current.setContent(form.plantilla_value);
      }, 1000);
    }
    return () => {};
  }, [openActualizar]);

  return (
    <div>
      <h5>Plantillas de contratos</h5>
      <div className="d-flex justify-content-end">
        <button className="btn btn-dark btn-sm" onClick={() => toggleAgregar()}>
          Agregar
        </button>
      </div>
      <div className="row">
        <div className="col-md-12">
          <table className="table table-sm table-hover">
            <thead>
              <tr>
                <th>Plantilla</th>
                <th>Proyecto</th>
                {/*<th>Estado</th>*/}
                <th>Estatus</th>
                <th colSpan={4}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {arrayContratosProd.map((contrato, index) => (
                <tr key={index}>
                  <td>{contrato.plantilla_nombre}</td>
                  <td>{contrato.proyecto_nombre}</td>
                  <td>{contrato.plantilla_estado}</td>
                  {/*<td>
                    {contrato.estatus === "Cancelado" && (
                      <span className="badge bg-danger">
                        {contrato.estatus}
                      </span>
                    )}
                    {contrato.estatus === "En proceso" && (
                      <span className="badge bg-warning">
                        {contrato.estatus}
                      </span>
                    )}
                    {contrato.estatus === "Validado" && (
                      <span className="badge bg-success">
                        {contrato.estatus}
                      </span>
                    )}
                  </td>*/}
                  <td className="d-flex gap-3">
                    <button
                      className="btn btn-dark btn-sm"
                      onClick={() => {
                        window.open(
                          `http://localhost:8000/api/generar-contrato/${contrato.id}`,
                          "_blank",
                          "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes,top=100,left=250,width=1200,height=800"
                        );
                      }}
                    >
                      Ver
                    </button>
                    <button
                      className="btn btn-dark btn-sm"
                      onClick={() => {
                        setform(contrato);
                        toggleActualizar();
                      }}
                    >
                      Editar
                    </button>
                    <button
                      className="btn btn-dark btn-sm"
                      onClick={() => {
                        ContratosDelete(
                          {
                            id: contrato.id,
                          },
                          token
                        ).then((res) => {
                          if (res.success) {
                            ContratosGet(token).then((res) => {
                              setarrayContratosProd(res?.data ?? []);
                            });
                          }
                        });
                      }}
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        size="xl"
        isOpen={openAgregar}
        toggle={() => {
          setform({
            plantilla_numero: "",
            plantilla_nombre: "",
            plantilla_descripcion: "",
            plantilla_estado: "",
            proyecto_id: "",
            proveedor_id: "",
            plantilla_value: "",
            estatus: "En proceso",
          });
          toggleAgregar();
        }}
      >
        <ModalHeader
          className="backend_titulo"
          toggle={() => {
            setform({
              plantilla_numero: "",
              plantilla_nombre: "",
              plantilla_descripcion: "",
              plantilla_estado: "",
              proyecto_id: "",
              proveedor_id: "",
              plantilla_value: "",
              estatus: "En proceso",
            });
            toggleAgregar();
          }}
        >
          Agregar contrato
        </ModalHeader>
        <ModalBody>
          <Add
            form={form}
            setform={setform}
            arrayProyectos={arrayProyectos}
            editorRef={editorRef}
            proveedores={proveedores}
          />
          <Editor
            apiKey="xqn5tb9aw7fug1tej7majvkl1bxpqkvjxx06jwfbc5zaw5ex"
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue="<p></p>"
            init={{
              height: 300,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            className="backend_boton"
            onClick={() => {
              form.plantilla_value = editorRef.current.getContent();
              ContratosAdd(form, token).then((res) => {
                if (res.success) {
                  ContratosGet(token).then((res) => {
                    setarrayContratosProd(res?.data ?? []);
                  });
                }
              });
              setform({
                plantilla_nombre: "",
                plantilla_descripcion: "",
                plantilla_estado: "",
                proyecto_id: "",
                proveedor_id: "",
                plantilla_value: "",
                estatus: "En proceso",
              });
              setopenAgregar(false);
            }}
          >
            Agregar
          </Button>{" "}
        </ModalFooter>
      </Modal>

      <Modal
        size="xl"
        isOpen={openActualizar}
        toggle={() => {
          setform({
            plantilla_nombre: "",
            plantilla_descripcion: "",
            plantilla_estado: "",
            proyecto_id: "",
            proveedor_id: "",
            plantilla_value: "",
            estatus: "En proceso",
          });
          toggleActualizar();
        }}
      >
        <ModalHeader
          className="backend_titulo"
          toggle={() => {
            setform({
              plantilla_nombre: "",
              plantilla_descripcion: "",
              plantilla_estado: "",
              proyecto_id: "",
              proveedor_id: "",
              plantilla_value: "",
              estatus: "En proceso",
            });
            toggleActualizar();
          }}
        >
          Actualizar contrato
        </ModalHeader>
        <ModalBody>
          <Update
            form={form}
            setform={setform}
            arrayProyectos={arrayProyectos}
            editorActualizarRef={editorActualizarRef}
            user_rol={user_rol}
            proveedores={proveedores}
          />
          <Editor
            apiKey="xqn5tb9aw7fug1tej7majvkl1bxpqkvjxx06jwfbc5zaw5ex"
            onInit={(evt, editor) => (editorActualizarRef.current = editor)}
            initialValue="<p></p>"
            init={{
              height: 300,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            className="backend_boton"
            onClick={() => {
              form.plantilla_value = editorActualizarRef.current.getContent();
              ContratosUpdate(form, token).then((res) => {
                if (res.success) {
                  ContratosGet(token).then((res) => {
                    setarrayContratosProd(res?.data ?? []);
                  });
                  toggleActualizar();
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Algo salio mal!",
                  });
                }
              });
            }}
          >
            Actualizar
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
}
