import { useCallback, useState } from 'react';
import { VariableGet, VariableAdd, VariableUpdate, VariableDelete } from '../service/variables';

export default function useUsers() {
    const [Variables, setVariables] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const VariableGetService = useCallback((user_id, token) => {
        VariableGet(user_id, token).then(response => {
            setVariables(response)
        })
    }, []);
    /*
     * Agregar Variable
     */
    const VariableAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        VariableAdd(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let nombre = errores['nombre'];
                let cantidad = errores['cantidad'];
                if (nombre != null) {
                    setState({ loading: false, error: true, message: nombre[0], success: false })
                }
                if (cantidad != null) {
                    setState({ loading: false, error: true, message: cantidad[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Variable registrado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al registrar Variable',
                        success: false
                    })
                }
            }
        })
    }, [])

    /*
     * Actualizar Variable
     */
    const VariableUpdateService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        VariableUpdate(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let nombre = errores['nombre'];
                let cantidad = errores['cantidad'];
                if (nombre != null) {
                    setState({ loading: false, error: true, message: nombre[0], success: false })
                }
                if (cantidad != null) {
                    setState({ loading: false, error: true, message: cantidad[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Variable actualizado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al actualizar Variable',
                        success: false
                    })
                }
            }
        })
    }, [])
    /*
     * Eliminar Variable
     */
    const VariableDeleteService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        VariableDelete(
            data, token
        ).then(response => {
            if (response.guardado === true) {
                setState({
                    loading: false,
                    error: false,
                    message: 'Variable eliminado con exito',
                    success: true
                })
            } else {
                setState({
                    loading: false,
                    error: true,
                    message: 'Error al eliminar Variable',
                    success: false
                })
            }
        })
    }, [])

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Variables,
        VariableGetService,
        VariableAddService,
        VariableUpdateService,
        VariableDeleteService
    }
}