import { createSlice } from "@reduxjs/toolkit";

export const loaderReducer = createSlice({
  name: "loader",
  initialState: {
    hide: true,
    title: "",
  },
  reducers: {
    showLoader: (state) => {
      state.hide = false;
    },
    hideLoader: (state) => {
      state.hide = true;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
  },
});

export const { showLoader, hideLoader, setTitle } = loaderReducer.actions;

export const getValues = (state) => state.loader;

export default loaderReducer.reducer;
