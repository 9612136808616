import { useCallback, useState } from "react";
import {
  ProveedorAdd,
  ProveedorDelete,
  ProveedorGet,
  ProveedorUpdate,
  ProveedorCFDIGet
} from "../service/proveedor";

export default function useProveedor() {
  const [Proveedores, setProveedores] = useState([]);
  const [CFDI, setCFDI] = useState([]);
  const [State, setState] = useState({
    loading: false,
    error: false,
    message: "",
    success: false,
  });

  const ProveedorGetService = useCallback((user_id, token) => {
    ProveedorGet(user_id, token).then((response) => {
      setProveedores(response);
    });
  }, []);

  const ProveedorCFDIGetService = useCallback((token) => {
    ProveedorCFDIGet(token).then((response) => {
      setCFDI(response);
    });
  }, []);

  /*
   * Agregar Proveedor
   */
  const ProveedorAddService = useCallback((data, token) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
    });
    ProveedorAdd(data, token).then((response) => {
      if (response.success) {
        setState({
          loading: false,
          error: false,
          message: "Proveedor registrado con exito",
          success: true,
        });
      } else {
        setState({
          loading: false,
          error: true,
          message: response.data,
          success: false,
        });
      }
    });
  }, []);

  /*
   * Actualizar Proveedor
   */
  const ProveedorUpdateService = useCallback((data, token) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
    });
    ProveedorUpdate(data, token).then((response) => {
      if (response.success) {
        setState({
          loading: false,
          error: false,
          message: "Proveedor actualizado con exito",
          success: true,
        });
      } else {
        setState({
          loading: false,
          error: true,
          message: response.data,
          success: false,
        });
      }
    });
  }, []);
  /*
   * Eliminar Proveedor
   */
  const ProveedorDeleteService = useCallback((data, token) => {
    setState({
      loading: true,
      error: false,
      message: "",
      success: false,
    });
    ProveedorDelete(data, token).then((response) => {
      if (response.success) {
        setState({
          loading: false,
          error: false,
          message: "Proveedor eliminado con exito",
          success: true,
        });
      } else {
        setState({
          loading: false,
          error: true,
          message: "Error al eliminar Proveedor",
          success: false,
        });
      }
    });
  }, []);

  return {
    loading: State.loading,
    error: State.error,
    message: State.message,
    success: State.success,
    Proveedores,
    CFDI,
    ProveedorGetService,
    ProveedorAddService,
    ProveedorUpdateService,
    ProveedorDeleteService,
    ProveedorCFDIGetService
  };
}
