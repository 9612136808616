import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/Login";
import Proyectos from "../pages/proyectos";
import DashboardCliente from "../pages/dashboard/cliente";
import Presupuestos from "../pages/presupuestos";
import Padre from "../pages/padre";
import Variables from "../pages/variables";
import Clientes from "../pages/clientes";
import ProveedoresContrato from "../pages/proveedores";
import Proveedores from "../pages/proveedores_sin";
import Hijos from "../pages/hijos";
import Niveles from "../pages/niveles";
import Contabilidad from "../pages/Contabilidad";
import Usuarios from "../pages/usuarios";
import Fiscal from "../pages/fiscal";
import Perfil from "../pages/perfil";
import OrdenesPago from "../pages/ordenesPago";
import FechasPago from "../pages/fechas";
import Contratos from "../pages/contratos/contratos";
import CreateFormulario from "../pages/configuracion/create-formulario";
import UpdateFormulario from "../pages/configuracion/update-formulario";
import ViewFormulario from "../pages/configuracion/view-formulario";

import FechasPagoOrden from "../pages/fechas/fechas";
import PrivateRoute from "./PrivateRoute";
const Register = lazy(() => import("../pages/Register"));

export default function Root() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/register"
        element={
          <Suspense fallback={<br />}>
            <Register />
          </Suspense>
        }
      />
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/dashboard" element={<Padre />} />
        <Route path="/dashboard-cliente/:id" element={<DashboardCliente />} />
        <Route path="/proyectos" element={<Proyectos />} />
        <Route path="/presupuestos/:id" element={<Presupuestos />} />
        <Route path="/hijos" element={<Hijos />} />
        <Route path="/variables" element={<Variables />} />
        <Route path="/niveles" element={<Niveles />} />
        <Route path="/contabilidad" element={<Contabilidad />} />
        <Route path="/clientes" element={<Clientes />} />
        <Route path="/proveedores-contrato" element={<ProveedoresContrato />} />
        <Route path="/proveedores" element={<Proveedores />} />
        <Route path="/usuarios" element={<Usuarios />} />
        <Route path="/fiscal" element={<Fiscal />} />
        <Route path="/perfil" element={<Perfil />} />
        <Route path="/ordenes-pago" element={<OrdenesPago />} />
        <Route path="/fechas-pago" element={<FechasPago />} />
        <Route path="/fechas-pago/:id" element={<FechasPagoOrden />} />
        <Route path="/contratos" element={<Contratos />} />
        <Route path="/configuracion/create-formulario" element={<CreateFormulario />} />
        <Route path="/configuracion/update-formulario/:uuid" element={<UpdateFormulario />} />
        <Route path="/configuracion/view-formulario/:uuid" element={<ViewFormulario />} />
      </Route>
    </Routes>
  );
}
