const ENDPOINT = process.env.REACT_APP_API_URL_DEVELOP;

export async function ProveedorGet(users_id, token) {
  return fetch(`${ENDPOINT}ProveedoresGet/${users_id}`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      'Authorization': `${token}`
    },
    method: "get",
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function ProveedorCFDIGet(token) {
  return fetch(`${ENDPOINT}ProveedoresCFDIGet`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      'Authorization': `${token}`
    },
    method: "get",
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function ProveedorAdd(data, token) {
  return fetch(`${ENDPOINT}ProveedoresAdd`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      'Authorization': `${token}`
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}


export async function ProveedorUpdate(data, token) {
  return fetch(`${ENDPOINT}ProveedoresUpdate`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      'Authorization': `${token}`
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function ProveedorDelete(data, token) {
  return fetch(`${ENDPOINT}ProveedoresDelete`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      'Authorization': `${token}`
    },
    method: "post",
    body: data,
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}
