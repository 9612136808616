import React from "react";

export default function TableHeader({
  handlePageSizeChange,
  pageSize,
  pageSizes,
  setbuscador,
  buscador,
  ...rest
}) {
  return (
    <thead {...rest} className="table-dark">
      <tr>
        <th scope="col" colSpan={3}>
          <div className="flex justify-start">
            <div className="w-1/3">
              <input
                class="form-control mr-sm-2"
                type="search"
                value={buscador}
                onChange={(e) => setbuscador(e.target.value)}
                placeholder={"Buscar"}
              />
            </div>
          </div>
        </th>
        <th scope="col" colSpan={28}>
          <select onChange={handlePageSizeChange} value={pageSize}>
            {pageSizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </th>
      </tr>
      <tr>
        <th scope="col">UUID</th>
        <th scope="col">Tipo de comprobante</th>
        <th scope="col">Estatus</th>
        <th scope="col">Fecha de emisión</th>
        <th scope="col">Fecha de timbrado</th>
        <th scope="col">RFC emisor</th>
        <th scope="col">Nombre emisor</th>
        <th scope="col">Regimen emisor</th>
        <th scope="col">RFC receptor</th>
        <th scope="col">Nombre receptor</th>
        <th scope="col">Uso CFDI</th>
        <th scope="col">Forma de pago</th>
        <th scope="col">Metodo de pago</th>
        <th scope="col">Version</th>
        <th scope="col">Exportacion</th>
        <th scope="col">Lugar de expedicion</th>
        <th scope="col">Clave Producto Servicio</th>
        <th scope="col">Clave Unidad</th>
        <th scope="col">Unidad</th>
        <th scope="col">Descripcion</th>
        <th scope="col">Valor Unitario</th>
        <th scope="col">Importe</th>
        <th scope="col">Moneda</th>
        <th scope="col">Subtotal</th>
        <th scope="col">IVA</th>
        <th scope="col">Retencion ISR</th>
        <th scope="col">Retencion IVA</th>
        <th scope="col">Total</th>
        <th scope="col">XML</th>
        <th scope="col">Solicitud de cancelacion</th>
        <th scope="col">Acuse de cancelacion</th>
      </tr>
    </thead>
  );
}
