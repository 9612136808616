import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, Input } from 'reactstrap';
import usePresupuesto from '../../../hoock/usePresupuesto';
import eliminar from "../../../style/assets/img/delete.gif";
import Swal from "sweetalert2";


export default function ModalAdd({
    ModalEliminar,
    toggleEliminar,
    IDCuenta,
    Cuenta,
    NomCuenta,
    CuentaTipo,
    token,
    PresupuestosGetService }) {

    const { loading, error, message, success, CuentaDeleteService } = usePresupuesto();
    let i = 1;

    useEffect(() => {
        if (success == true) {
            PresupuestosGetService(token)
            toggleEliminar();
        }
    }, [PresupuestosGetService, success])

    const ElimiarCuenta = () => {
        const data = new FormData();
        data.append("id", IDCuenta);
        data.append("tipo", CuentaTipo);

        CuentaDeleteService(data, token);

        Swal.fire({
            title: "Eliminando cuenta",
            text: "Loading...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            icon: 'question',
            onOpen: () => {
                Swal.showLoading();
            }
        });
    }

    useEffect(() => {
        if (error) {
            Swal.close();
            Swal.fire({
                icon: "error",
                title: "Error",
                confirmButtonColor: "#3b82f6",
                text: message,
            });
        }
    }, [message, error])

    useEffect(() => {
        if (success) {
            Swal.close();
            Swal.fire({
                icon: "success",
                title: "Exito",
                confirmButtonColor: "#3b82f6",
                text: message,
            });
            toggleEliminar();
        }
    }, [message, success])


    return <>
        <Modal isOpen={ModalEliminar} toggle={toggleEliminar}>
            <ModalHeader className="backend_titulo" toggle={toggleEliminar}>Eliminar Cuenta Presupuestal [ {Cuenta} ]</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <img style={{ width: 90 }} className="d-block mx-auto" src={eliminar} />
                        <h3>Esta seguro de eliminar la cuenta "{NomCuenta}"</h3>
                        <p>Esta operación es irreversible, los hijos seran eliminados</p>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={(() => ElimiarCuenta())}>Eliminar</Button>{' '}
                <Button color="danger" onClick={toggleEliminar}>Cancel</Button>{' '}
            </ModalFooter>
        </Modal>
    </>
}
