import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
} from "reactstrap";
import Select from "react-select";
import useHijos from "../../../hoock/useHijos";

const unidades = [
  { value: "unico", label: "Unico" },
  { value: "semanal", label: "Semanal" },
  { value: "quincenal", label: "Quincenal" },
  { value: "mensual", label: "Mensual" },
  { value: "bimestral", label: "Bimestral" },
  { value: "trimestral", label: "Trimestral" },
  { value: "semestral", label: "Semestral" },
  { value: "anual", label: "Anual" },
];

const status = [
  { value: "si", label: "Si" },
  { value: "no", label: "No" },
];

const sub = [
  { value: "Si", label: "Si" },
  { value: "No", label: "No" },
];

export default function ModalAdd({
  modal,
  toggle,
  PadreID,
  HijoID,
  NivelID,
  VariableID,
  Nombre,
  Cuenta,
  Cantidad,
  Unidades,
  X,
  Fecha,
  Opcion,
  Cuantos,
  Estatus,
  Costo,
  Subtota,
  Iva,
  Total,
  Subpadre,
  Update,
  Id,
  setPadreID,
  setHijoID,
  setNivelID,
  setVariableID,
  setNombre,
  setCuenta,
  setCantidad,
  setUnidades,
  setX,
  setFecha,
  setOpcion,
  setCuantos,
  setEstatus,
  setCosto,
  setSubtota,
  setIva,
  setTotal,
  setSubpadre,
  HijosGetService,
}) {
  const {
    loading,
    error,
    message,
    success,
    VariableAddService,
    HijosAddService,
    HijosUpdateService,
    Padres,
    PadresGetService,
    Niveles,
    CuentaPre,
    setCuentaPre,
    HijosCuentaService,
    PadreCuentaService,
    NivelesGetService,
    Hijo,
    HijosHijosService,
    Variables,
    VariableGetService,
  } = useHijos();
  const [SelectedPadre, setSelectedPadre] = useState([]);
  const [SelectedHijo, setSelectedHijo] = useState([]);
  const [SelectedNivel, setSelectedNivel] = useState([]);
  const [SelectedOpcion, setSelectedOpcion] = useState([]);
  const [SelectedStatus, setSelectedStatus] = useState([]);
  const [SelectedVariable, setSelectedVariable] = useState([]);
  const [SelectedSub, setSelectedSub] = useState([
    { value: "Si", label: "Si" },
  ]);
  const [MostrarVariable, setMostrarVariable] = useState(false);
  const [NombreVari, setNombreVari] = useState("");
  const [NumeracionVari, setNumeracionVari] = useState(0);
  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    if (success == true) {
      HijosGetService();
    }
  }, [HijosGetService, success]);

  useEffect(() => {
    PadresGetService();
  }, [PadresGetService]);

  useEffect(() => {
    NivelesGetService();
  }, [NivelesGetService]);

  useEffect(() => {
    VariableGetService();
  }, [VariableGetService]);

  const guardar = () => {
    let sta = "";

    if (VariableID == "") {
      sta = "editable";
    } else {
      sta = "variable";
    }

    if (Update) {
      const data = new FormData();
      data.append("id", Id);
      data.append("nombre", Nombre);
      data.append("padre_id", PadreID);
      data.append("hijos_id", HijoID);
      data.append("nivel_id", NivelID);
      data.append("cuenta_presupuental", CuentaPre);
      data.append("cantidad", Cantidad);
      data.append("unidad", Unidades);
      data.append("x", X);
      data.append("fecha_pago", Fecha);
      data.append("opcion", Opcion);
      data.append("cuantos", Cuantos);
      data.append("status", sta);
      data.append("variable", VariableID);
      data.append("costo", Costo);
      data.append("subtotal", Subtota);
      data.append("iva", Iva);
      data.append("con_iva", isChecked);
      data.append("total", Total);
      data.append("subpadre", Subpadre);

      HijosUpdateService(data);

      toggle();
    } else {
      const data = new FormData();
      data.append("nombre", Nombre);
      data.append("padre_id", PadreID);
      data.append("hijos_id", HijoID);
      data.append("nivel_id", NivelID);
      data.append("cuenta_presupuental", CuentaPre);
      data.append("cantidad", Cantidad);
      data.append("unidad", Unidades);
      data.append("x", X);
      data.append("fecha_pago", Fecha);
      data.append("opcion", Opcion);
      data.append("cuantos", Cuantos);
      data.append("status", sta);
      data.append("variable", VariableID);
      data.append("costo", Costo);
      data.append("subtotal", Subtota);
      data.append("iva", Iva);
      data.append("con_iva", isChecked);
      data.append("total", Total);
      data.append("subpadre", Subpadre);

      HijosAddService(data);

      toggle();
    }
  };

  const guardarNuevo = () => {
    let sta = "";

    if (VariableID == "") {
      sta = "editable";
    } else {
      sta = "variable";
    }

    const data = new FormData();
    data.append("nombre", Nombre);
    data.append("padre_id", PadreID);
    data.append("hijos_id", HijoID);
    data.append("nivel_id", NivelID);
    data.append("cuenta_presupuental", CuentaPre);
    data.append("cantidad", Cantidad);
    data.append("unidad", Unidades);
    data.append("x", X);
    data.append("fecha_pago", Fecha);
    data.append("opcion", Opcion);
    data.append("cuantos", Cuantos);
    data.append("status", sta);
    data.append("variable", VariableID);
    data.append("costo", Costo);
    data.append("subtotal", Subtota);
    data.append("iva", Iva);
    data.append("con_iva", isChecked);
    data.append("total", Total);
    data.append("subpadre", Subpadre);

    HijosAddService(data);

    let cu = Cuenta.split(".");
    let num = cu[0];
    let dec = parseFloat(cu[1]) + 1;

    setVariableID("");
    setNombre("");
    setCuenta(num + "." + dec);
    setCantidad(0);
    setUnidades("");
    setX(0);
    setFecha("");
    setSelectedOpcion([]);
    setOpcion([]);
    setCuantos("");
    setEstatus("");
    setCosto(0);
    setSubtota(0);
    setIva(0);
    setTotal(0);
    setSubpadre("");
  };

  const precios = (e) => {
    setCosto(e);
    setSubtota(e);
    setIva(e * 0.16);
    setTotal(parseFloat(e) + e * 0.16);
  };

  const functionVariable = (precio, cantidad, x) => {
    setCosto(precio);
    setCantidad(cantidad);
    setX(x);

    let sub = cantidad * x * precio;

    setSubtota(sub);
    setIva(sub * 0.16);
    setTotal(Math.round(sub * 1.16));
  };

  const guardarVariable = () => {
    const data = new FormData();
    data.append("nombre", NombreVari);
    data.append("cantidad", NumeracionVari);

    VariableAddService(data);
  };

  useEffect(() => {
    if (success === true) {
      VariableGetService();
      setNombreVari("");
      setNumeracionVari(0);
      setMostrarVariable(false);
    }
  }, [VariableGetService, success]);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader className="backend_titulo" toggle={toggle}>
          Agregar Hijo
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-4">
              <label>Padre:</label>
              <Select
                value={SelectedPadre}
                onChange={(e) => {
                  setPadreID(e.value);
                  setSelectedPadre(e);
                  HijosHijosService({ id: e.value });
                  setNivelID(parseFloat(e.nivel_id) + 1);
                  PadreCuentaService({ id: e.value });
                }}
                options={Padres}
                placeholder={PadreID ? PadreID : "Padres"}
              />
            </div>
            <div className="col-md-4">
              <label>Hijo:</label>
              <Select
                value={SelectedHijo}
                onChange={(e) => {
                  setHijoID(e.value);
                  setSelectedHijo(e);
                  HijosCuentaService({ id: e.value });
                  setNivelID(parseFloat(e.nivel_id) + 1);
                }}
                options={Hijo}
                placeholder={HijoID ? HijoID : "Hijos"}
              />
            </div>
            <div className="col-md-4">
              <label>Subpadre:</label>
              <Select
                value={SelectedSub}
                defaultValue={{ label: "Si", value: "Si" }}
                onChange={(e) => {
                  setSubpadre(e.value);
                  setSelectedSub(e);
                }}
                options={sub}
                placeholder={Subpadre ? Subpadre : "Subpadre"}
              />
            </div>
            <div className="col-md-12">
              <hr />
            </div>
            <div className="col-md-6">
              <label>Descripcion:</label>
             
                <Input
                  placeholder="Nombre"
                  value={Nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              
            </div>
            <div className="col-md-6">
              <label>Cuenta Presupuestal:</label>
             
                <Input
                  placeholder="Cuenta Presupuestal"
                  disabled={true}
                  value={CuentaPre}
                  onChange={(e) => setCuenta(e.target.value)}
                />
              
            </div>
            {Subpadre == "No" ? (
              <>
                <div className="col-md-12">
                  <hr />
                </div>
                <div className="col-md-6">
                  <label>Nombre de la Variable:</label>
                  <Select
                    value={SelectedVariable}
                    onChange={(e) => {
                      setVariableID(e.value);
                      setSelectedVariable(e);
                      functionVariable(e.precio, Cantidad, X);
                    }}
                    options={Variables}
                    placeholder={VariableID ? VariableID : "Variable"}
                  />
                </div>
                <div className="col-md-6">
                  <button
                    className="btn btn-info mt-4"
                    onClick={() => setMostrarVariable(!MostrarVariable)}
                  >
                    Agregar variable
                  </button>
                  {MostrarVariable == true ? (
                    <div className="input-group mb-3 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nombre variable"
                        aria-label="Nombre variable"
                        value={NombreVari}
                        onChange={(e) => setNombreVari(e.target.value)}
                      />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Valor variable"
                        aria-label="Valor variable"
                        value={NumeracionVari}
                        onChange={(e) => setNumeracionVari(e.target.value)}
                      />
                      <button
                        className="btn btn-secondary"
                        type="button"
                        id="button-addon2"
                        onClick={() => guardarVariable()}
                      >
                        Agregar
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-12">
                  <div className="form-check mt-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleOnChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckIndeterminate"
                    >
                      Agregar IVA
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <hr />
                </div>
                <div className="col-md-4">
                  <label>Cantidad:</label>
                 
                    <Input
                      type={"number"}
                      placeholder="Cantidad"
                      value={Cantidad}
                      onChange={(e) => {
                        setCantidad(e.target.value);
                        functionVariable(Costo, e.target.value, X);
                      }}
                    />
                  
                </div>
                <div className="col-md-4">
                  <label>Unidad:</label>
                 
                    <Input
                      placeholder="Unidad"
                      value={Unidades}
                      onChange={(e) => setUnidades(e.target.value)}
                    />
                  
                </div>
                <div className="col-md-4">
                  <label>X:</label>
                 
                    <Input
                      type={"number"}
                      placeholder="X"
                      value={X}
                      onChange={(e) => {
                        setX(e.target.value);
                        functionVariable(Costo, Cantidad, e.target.value);
                      }}
                    />
                  
                </div>
                <div className="col-md-3">
                  <label>Costo:</label>
                 
                    <Input
                      placeholder="Costo"
                      value={Costo}
                      disabled={VariableID == "" ? false : true}
                      onChange={(e) =>
                        functionVariable(e.target.value, Cantidad, X)
                      }
                    />
                  
                </div>
                <div className="col-md-3">
                  <label>Subtotal:</label>
                 
                    <Input
                      placeholder="Subtota"
                      value={Subtota}
                      disabled={true}
                      onChange={(e) => setSubtota(e.target.value)}
                    />
                  
                </div>
                <div className="col-md-3">
                  {isChecked == true ? (
                    <>
                      <label>Iva:</label>
                     
                        <Input
                          placeholder="Iva"
                          value={Iva}
                          disabled={true}
                          onChange={(e) => setIva(e.target.value)}
                        />
                      
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-3">
                  <label>Total:</label>
                 
                    <Input
                      placeholder="Total"
                      value={Total}
                      disabled={true}
                      onChange={(e) => setTotal(e.target.value)}
                    />
                  
                </div>
                <div className="col-md-12">
                  <hr />
                </div>
                <div className="col-md-4">
                  <label>Fecha primer pago:</label>
                 
                    <Input
                      type="date"
                      value={Fecha}
                      onChange={(e) => setFecha(e.target.value)}
                    />
                  
                </div>
                <div className="col-md-4">
                  <label>Opcion de pagos:</label>
                  <Select
                    value={SelectedOpcion}
                    onChange={(e) => {
                      setOpcion(e.value);
                      setSelectedOpcion(e);
                    }}
                    options={unidades}
                    placeholder={Opcion ? Opcion : "Opcion de pagos"}
                  />
                </div>
                <div className="col-md-4">
                  <label>Cuantos pagos:</label>
                 
                    <Input
                      type={"number"}
                      placeholder="Cuantos pagos"
                      value={Cuantos}
                      onChange={(e) => setCuantos(e.target.value)}
                    />
                  
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="backend_boton" onClick={() => guardar()}>
            {Update ? "Actualizar" : "Guardar y Cerrar"}
          </Button>{" "}
          {Update ? (
            ""
          ) : (
            <Button className="backend_boton" onClick={() => guardarNuevo()}>
              Guardar y Crear Nuevo
            </Button>
          )}
          <Button color="danger" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
