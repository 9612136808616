import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
} from "reactstrap";
import useVariable from "../../../hoock/useVariable";
import Swal from "sweetalert2";

export default function ModalAdd({
  modal,
  toggle,
  Nombre,
  Numeracion,
  Update,
  Id,
  token,
  setNombre,
  setNumeracion,
  VariableGetService,
  cliente_id,
}) {
  const {
    error,
    message,
    loading,
    success,
    VariableAddService,
    VariableUpdateService,
  } = useVariable();

  useEffect(() => {
    if (success === true) {
      VariableGetService(cliente_id, token);
    }
  }, [VariableGetService, success]);

  const createUser = () => {
    if (Update) {
      const data = new FormData();
      data.append("id", Id);
      data.append("nombre", Nombre);
      data.append("cantidad", Numeracion);
      data.append("cliente_id", cliente_id);

      VariableUpdateService(data, token);

      Swal.fire({
        title: "Actualizando variable",
        text: "Loading...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: "question",
        onOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      const data = new FormData();
      data.append("nombre", Nombre);
      data.append("cantidad", Numeracion);
      data.append("cliente_id", cliente_id);

      VariableAddService(data, token);

      Swal.fire({
        title: "Registrando variable",
        text: "Loading...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: "question",
        onOpen: () => {
          Swal.showLoading();
        },
      });
    }
  };

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
      setNombre("");
      setNumeracion("");
      toggle();
    }
  }, [message, success]);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader className="backend_titulo" toggle={toggle}>
          Agregar Variable
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
            
                <Input
                  placeholder="nombre"
                  value={Nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
            
                <Input
                  type="number"
                  placeholder="Cantidad"
                  value={Numeracion}
                  onChange={(e) => setNumeracion(e.target.value)}
                />
              
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="backend_boton" onClick={() => createUser()}>
            Agregar
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
}
