/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ContratosGet } from "../../../service/contratos";
import {
  ProveedoresNuevosAdd,
  ProveedoresNuevosUpdate,
} from "../../../service/proveedorNuevos";
import Swal from "sweetalert2";

export default function ModalAdd({
  modal,
  toggle,
  Update,
  getInformacion,
  Id,
  token,
  form,
  setform,
  cliente_id,
}) {
  const [contratos, setcontratos] = useState([]);
  const [contratosValue, setcontratosValue] = useState([]);

  useEffect(() => {
    ContratosGet(token).then((response) => {
      if (response.success) {
        setcontratos(response.data);
      }
    });
  }, []);

  const saveInformacion = (e) => {
    const data = {
      respuestas: JSON.stringify(form),
      cliente_id: cliente_id,
      contratos_id: contratosValue,
      id: Update ? Id : "",
    };

    if (Update) {
      ProveedoresNuevosUpdate(data, token).then((response) => {
        if (response.success) {
          getInformacion();
          toggle();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Algo salio mal!",
            footer: "<a href>Why do I have this issue?</a>",
          });
        }
      });
    } else {
      ProveedoresNuevosAdd(data, token).then((response) => {
        if (response.success) {
          getInformacion();
          toggle();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Algo salio mal!",
            footer: "<a href>Why do I have this issue?</a>",
          });
        }
      });
    }
  };
  return (
    <>
      <Modal size="md" isOpen={modal} toggle={toggle}>
        <ModalHeader className="backend_titulo" toggle={toggle}>
          Agregar Proveedor
        </ModalHeader>
        <ModalBody>
          <div className="row">
            {form.map((item, index) => (
              <div className="col-6">
                {item.type === "select" ? (
                  <div className="input-group">
                    <label htmlFor={item.label}>{item.label}</label>
                    <div className="input">
                      <select
                        type={item.type || "text"}
                        id={item.label}
                        className="form-control"
                        value={item.value}
                        onChange={(e) => {
                          const newForm = [...form];
                          newForm[index].value = e.target.value;
                          setform(newForm);
                        }}
                      >
                        <option value="">Seleccione una opción</option>
                        {item.opciones.map((opcion, index) => (
                          <option key={index} value={opcion}>
                            {opcion}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : (
                  <div className="input-group">
                    <label htmlFor={item.label}>{item.label}</label>
                    <div className="input">
                      <input
                        type={item.type || "text"}
                        id={item.label}
                        className="form-control"
                        value={item.value}
                        onChange={(e) => {
                          const newForm = [...form];
                          newForm[index].value = e.target.value;
                          setform(newForm);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}

            <div className="col-6">
              <label className="mt-3" htmlFor="contratos">
                Contratos
              </label>
              <select
                className="form-control"
                onChange={(e) => {
                  setcontratosValue(e.target.value);
                }}
                value={contratosValue}
              >
                <option value="">Seleccione un contrato</option>
                {contratos.map((item) => (
                  <option value={item.id}>{item.plantilla_nombre}</option>
                ))}
              </select>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="backend_boton" onClick={() => saveInformacion()}>
            Guardar información
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
}
