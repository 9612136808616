/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
} from "reactstrap";
import usePresupuesto from "../../../hoock/usePresupuesto";
import Swal from "sweetalert2";

export default function ModalAdd({
  ModalEditarCampo,
  toggleEditar,
  Campo,
  setCampo,
  ValorCampo,
  setValorCampo,
  IDCampo,
  setIDCampo,
  Tipo,
  setTipo,
  token,
  id,
  PresupuestosGetService,
}) {
  const {error, message, success, PresupuestosPadreUpdateService} =
    usePresupuesto();

  useEffect(() => {
    if (success == true) {
      PresupuestosGetService(id, token);
      toggleEditar();
    }
  }, [PresupuestosGetService, success]);

  const EditarCampo = () => {
    const data = new FormData();
    data.append("id", IDCampo);
    data.append("ValorCampo", ValorCampo);
    data.append("Campo", Campo);
    data.append("Tipo", Tipo);

    PresupuestosPadreUpdateService(data, token);

    Swal.fire({
      title: "Actualizando cuenta padre",
      text: "Loading...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      icon: "question",
      onOpen: () => {
        Swal.showLoading();
      },
    });
  };

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
      setValorCampo("");
      toggleEditar();
    }
  }, [message, success]);

  return (
    <>
      <Modal isOpen={ModalEditarCampo} toggle={toggleEditar}>
        <ModalHeader className="backend_titulo" toggle={toggleEditar}>
          Editar
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <label>{Campo}</label>

              <Input
                placeholder="nombre"
                value={ValorCampo}
                onChange={(e) => setValorCampo(e.target.value)}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => EditarCampo()}>
            Actualizar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
