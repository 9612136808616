const ENDPOINT = process.env.REACT_APP_API_URL_DEVELOP;

export async function ConfiguracionGet(token) {
  return fetch(`${ENDPOINT}ConfiguracionGet`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `${token}`,
    },
    method: "get",
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function ConfiguracionGetUUID(uuid, token) {
  return fetch(`${ENDPOINT}ConfiguracionGetUuid/${uuid}`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `${token}`,
    },
    method: "get",
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function ConfiguracionAdd(data, token) {
  return fetch(`${ENDPOINT}ConfiguracionAdd`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
    method: "post",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function ConfiguracionUpdate(data, token) {
  return fetch(`${ENDPOINT}ConfiguracionUpdate`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
    method: "post",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function ConfiguracionDelete(data, token) {
  return fetch(`${ENDPOINT}ConfiguracionDelete`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `${token}`,
    },
    method: "post",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}
