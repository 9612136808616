/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import useOrdenesPago from "../../hoock/useOrdenesPago";
import { Link as LinkReact } from "react-router-dom";
// import ModalAdd from "./Modals/ModalAdd";
import {
  Grid,
  GridColumn,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { filterBy } from "@progress/kendo-data-query";
import Link from "../../components/Form/Link";
import Title from "../../components/Form/Title";
import { hideLoader, showLoader, setTitle } from "../../reducers/loaderReducer";
import { useDispatch } from "react-redux";
import useLogin from "../../hoock/useLogin";
import Swal from "sweetalert2";

const PageTemplate = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        left: "10px",
      }}
    >
      Page {props.pageNum} of {props.totalPages}
    </div>
  );
};

const initialDataState = {
  skip: 0,
  take: 10,
};

const initialFilter = {
  logic: "and",
  filters: [],
};

const initialSort = [
  // {
  //   field: "modelo",
  //   dir: "desc",
  // },
  // {
  //   field: "serie",
  //   dir: "desc",
  // },
  // {
  //   field: "fecha_venta",
  //   dir: "desc",
  // },
  // {
  //   field: "Venta",
  //   dir: "desc",
  // },
];

export default function Index() {
  const { user_id, logout, token, cliente_id, user_rol } = useLogin();
  const dispatch = useDispatch();
  const { loading, error, message, success, Ordenes, OrdenesPagoGetService } =
    useOrdenesPago();
  const [modal, setModal] = useState(false);
  const [Update, setUpdate] = useState(false);
  const [Id, setId] = useState("");
  const [Nombre, setNombre] = useState("");
  const [Numeracion, setNumeracion] = useState("");
  const [sort, setSort] = React.useState(initialSort);
  const [filter, setFilter] = React.useState(initialFilter);
  const [page, setPage] = React.useState(initialDataState);
  let i = 1;

  const toggle = () => {
    setModal(!modal);
    setUpdate(false);
    setId("");
    setNombre("");
    setNumeracion("");
  };

  useEffect(() => {
    dispatch(setTitle("Descargando Ordenes de pago"));
    dispatch(showLoader());
    OrdenesPagoGetService(cliente_id, token);
    return () => {};
  }, []);

  useEffect(() => {
    if (Ordenes.length >= 0) {
      dispatch(hideLoader());
      dispatch(setTitle(""));
    }
    return () => {};
  }, [Ordenes]);

  useEffect(() => {
    if (success === true) {
      OrdenesPagoGetService(cliente_id, token);
    }
  }, [OrdenesPagoGetService, success]);

  const pageChange = (event) => {
    setPage(event.page);
  };

  const formatNumber = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, success]);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Title title="Listado de Ordenes de pago" className="text-left" />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <Grid
              sortable={true}
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
              filterable={true}
              filter={filter}
              onFilterChange={(e) => setFilter(e.filter)}
              pageable={true}
              data={filterBy(
                Ordenes.slice(page.skip, page.take + page.skip),
                filter
              )}
              skip={page.skip}
              take={page.take}
              total={Ordenes.length}
              onPageChange={pageChange}
              style={{
                height: "420px",
              }}
            >
              <GridNoRecords>No hay informacion disponible</GridNoRecords>
              <GridColumn field="nombre" title="Proyecto" />
              <GridColumn field="proveedor" title="Proveedor" />
              <GridColumn
                field="cuenta_presupuental"
                title="Cuenta Presupuestal"
              />
              <GridColumn
                field="cuantos_pagos"
                title="Cuanto pagos"
                filter="text"
              />
              <GridColumn
                field="montos"
                title="Monto"
                filter="text"
                format="{0:c}"
              />
              <GridColumn
                field="Ver Ordenes"
                filter={false}
                filterable={false}
                cell={(e) => {
                  let proveedores_id = e.dataItem.proveedores_id;
                  return (
                    <td style={{ marginTop: 10, textAlign: "center" }}>
                      <LinkReact to={'/fechas-pago/'+proveedores_id} className="btn btn-warning btn-sm">
                        Ver Ordenes
                      </LinkReact>
                    </td>
                  );
                }}
              />
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
