import React from "react";
import Link from "../../Form/Link";

export default function TableBody({ contabilidad = [] }) {

  const ObtenerTasaOCuota = (retencion = []) => {
    let retencion2 = JSON.parse(retencion);
    let tasaOCuota = "";
    retencion2.map((element) => {
      if (element.Impuesto === "001" && element.TasaOCuota != undefined) {
        tasaOCuota = element.TasaOCuota;
      }
    });
    return tasaOCuota;
  };
  const ObtenerISR = (retencion = []) => {
    let retencion2 = JSON.parse(retencion);
    let Importe = "";
    retencion2.map((element) => {
      if (element.Impuesto === "001" && element.TasaOCuota != undefined) {
        Importe = element.Importe;
      }
    });
    return Importe;
  };
  const ObtenerIVA = (retencion = []) => {
    let retencion2 = JSON.parse(retencion);
    let Importe = "";
    retencion2.map((element) => {
      if (element.Impuesto === "002" && element.TasaOCuota != undefined) {
        Importe = element.TasaOCuota;
      }
    });
    return Importe;
  };

  const formatNumber = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  return (
    <tbody>
      {contabilidad.map((item, index) => (
        <tr key={index}>
          <td>{item.uuid}</td>
          <td>{item.tipo_comprobante}</td>
          <td>
            {item.status === "Vigente" ? (
              <span className="badge bg-success">{item.status}</span>
            ) : (
              <span className="badge bg-danger">{item.status}</span>
            )}
          </td>
          <td>{item.fecha_emision}</td>
          <td>{item.fecha_timbrado}</td>
          <td>{item.rfc_proveedor}</td>
          <td>{item.nombre_proveedor}</td>
          <td>{item.regimen_fiscal_proveedor}</td>
          <td>{item.rfccliente}</td>
          <td>{item.nombrecliente}</td>
          <td>{item.uso_CFDI}</td>
          <td>{item.forma_pago}</td>
          <td>{item.metodo_pago}</td>
          <td>{item.version}</td>
          <td>{item.Exportacion}</td>
          <td>{item.LugarExpedicion}</td>
          <td>{item.ClaveProdServ}</td>
          <td>{item.ClaveUnidad}</td>
          <td>{item.Unidad}</td>
          <td>{item.Descripcion_}</td>
          <td>{item.ValorUnitario}</td>
          <td>{item.Importe}</td>
          <td>{item.Moneda}</td>
          <td>${formatNumber(item.SubTotal)}</td>
          {
            item.tipo_comprobante == 'Ingreso'
              ?
              <>
                <td>
                  {
                    ObtenerTasaOCuota(item.retenciones) != []
                      ?
                      '$' + ObtenerTasaOCuota(item.retenciones)
                      :
                      ''
                  }
                </td>
                <td>
                  {
                    ObtenerISR(item.retenciones) != []
                      ?
                      '$' + ObtenerISR(item.retenciones)
                      :
                      ''
                  }
                </td>
                <td>
                  {
                    ObtenerIVA(item.retenciones) != []
                      ?
                      '$' + ObtenerIVA(item.retenciones)
                      :
                      ''
                  }
                </td>
              </>
              :
              <>
                <td></td>
                <td></td>
                <td></td>
              </>
          }
          <td>{item.Total}</td>
          <td>
            {
              item.documento != null
                ?
                <Link className="btn btn-success" icon="bi bi-download" target="_blank" href={'http://127.0.0.1:8000/' + item.carpeta + '/' + item.documento} />
                :
                ''
            }
          </td>
          <td>
            {
              item.SolicitudesCancelacion != null
                ?
                <Link className="btn btn-danger" icon="bi bi-download" target="_blank" href={'http://127.0.0.1:8000/' + item.carpeta + '/' + item.SolicitudesCancelacion} />
                :
                ''
            }
          </td>
          <td>
            {
              item.AcusesCancelacion != null
                ?
                <Link className="btn btn-danger" icon="bi bi-download" target="_blank" href={'http://127.0.0.1:8000/' + item.carpeta + '/' + item.AcusesCancelacion} />
                :
                ''
            }
          </td>
        </tr>
      ))}
    </tbody>
  );
}
