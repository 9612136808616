import { useCallback, useState } from 'react';
import { FechasGetGet, FechasOrdenAdd } from '../service/fechas';

export default function useUsers() {
    const [Fecha, setFecha] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const FechasGetService = useCallback((user_id, token) => {
        FechasGetGet(user_id, token).then(response => {
            setFecha(response)
        })
    }, []);

    const FechasOrdenAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        FechasOrdenAdd(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores !== undefined) {
                let facturas = errores['facturas'];
                if (facturas != null) {
                    setState({ loading: false, error: true, message: facturas[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Orde de Pago creada con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al registrar orden de pago',
                        success: false
                    })
                }
            }
        })
    }, [])

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Fecha,
        FechasGetService,
        FechasOrdenAddService,
    }
}