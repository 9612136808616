import React from "react";

export default function Update({
  form,
  setform,
  arrayProyectos,
  editorActualizarRef,
  user_rol,
  proveedores
}) {
  return (
    <div className="row">
      <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="">Nombre</label>
          <input
            type="text"
            className="form-control"
            value={form.plantilla_nombre}
            onChange={(e) =>
              setform({ ...form, plantilla_nombre: e.target.value })
            }
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="">Descripción</label>
          <input
            type="text"
            className="form-control"
            value={form.plantilla_descripcion}
            onChange={(e) =>
              setform({ ...form, plantilla_descripcion: e.target.value })
            }
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="">Estado</label>
          <select
            name=""
            id=""
            className="form-control"
            value={form.plantilla_estado}
            onChange={(e) =>
              setform({ ...form, plantilla_estado: e.target.value })
            }
          >
            <option value="">Seleccione</option>
            <option value="Activo">Activo</option>
            <option value="Inactivo">Inactivo</option>
          </select>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label htmlFor="">Proyecto</label>
          <select
            name=""
            id=""
            className="form-control"
            value={form.proyecto_id}
            onChange={(e) => setform({ ...form, proyecto_id: e.target.value })}
          >
            <option value="">Seleccione</option>
            {arrayProyectos.map((res) => {
              return <option value={res.id}>{res.nombre}</option>;
            })}
          </select>
        </div>
      </div>
      <div className="col-md-6 d-flex justify-content-between align-items-center">
        <div className="form-group">
          <label htmlFor="">Proveedor</label>
          <select
            name=""
            id=""
            className="form-control"
            style={{
              width: 200,
            }}
            value={form.proveedor_id}
            onChange={(e) => setform({ ...form, proveedor_id: e.target.value })}
          >
            <option value="">Seleccione</option>
            {proveedores.map((res) => {
              return <option value={"[" + res.label + "]"}>{res.label}</option>;
            })}
          </select>
        </div>
        <button
          className="btn btn-dark btn-sm"
          onClick={() => {
            editorActualizarRef.current.insertContent(form.proveedor_id);
          }}
        >
          Agregar
        </button>
      </div>
      {user_rol === "super administrador" ? (
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="">Estatus</label>
            <select
              name=""
              id=""
              className="form-control"
              style={{
                width: 200,
              }}
              value={form.estatus}
              onChange={(e) => setform({ ...form, estatus: e.target.value })}
            >
              <option value="">Seleccione</option>
              <option value="Cancelado">Cancelado</option>
              <option value="En proceso">En proceso</option>
              <option value="Validado">Validado</option>
            </select>
          </div>
        </div>
      ) : null}
    </div>
  );
}
