import React, { useState, useEffect } from 'react';
import useHijos from '../../hoock/useHijos';
import ModalAdd from './Modals/ModalAdd';

export default function Index() {
    const { loading, error, message, success, Hijos, HijosGetService, HijosDeleteService } = useHijos();
    const [modal, setModal] = useState(false);
    const [Update, setUpdate] = useState(false);
    const [Id, setId] = useState('');
    const [PadreID, setPadreID] = useState('');
    const [HijoID, setHijoID] = useState('');
    const [NivelID, setNivelID] = useState('');
    const [VariableID, setVariableID] = useState('');
    const [Nombre, setNombre] = useState('');
    const [Cuenta, setCuenta] = useState('');
    const [Cantidad, setCantidad] = useState(0);
    const [Unidades, setUnidades] = useState('');
    const [X, setX] = useState(0);
    const [Fecha, setFecha] = useState('');
    const [Opcion, setOpcion] = useState([])
    const [Cuantos, setCuantos] = useState(0);
    const [Estatus, setEstatus] = useState('');
    const [Costo, setCosto] = useState(0);
    const [Subtota, setSubtota] = useState(0);
    const [Iva, setIva] = useState(0);
    const [Total, setTotal] = useState(0);
    const [Subpadre, setSubpadre] = useState('Si');

    const toggle = () => {
        setModal(!modal);
        setUpdate(false);
        setId('');
        setPadreID('');
        setHijoID('');
        setNivelID('');
        setVariableID('');
        setNombre('');
        setCuenta('');
        setCantidad(0);
        setUnidades('');
        setX(0);
        setFecha();
        setOpcion([]);
        setCuantos('')
        setEstatus('');
        setCosto('');
        setSubtota(0);
        setIva(0);
        setTotal(0);
        setSubpadre('');
    }

    let i = 1;
    useEffect(() => {
        HijosGetService()
    }, [HijosGetService])

    useEffect(() => {
        if (success == true) {
            HijosGetService()
        }
    }, [HijosGetService, success])

    const formatNumber = (number) => new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number)

    return <>
        <ModalAdd modal={modal}
            toggle={toggle}
            PadreID={PadreID}
            HijoID={HijoID}
            NivelID={NivelID}
            VariableID={VariableID}
            Nombre={Nombre}
            Cuenta={Cuenta}
            Cantidad={Cantidad}
            Unidades={Unidades}
            X={X}
            Fecha={Fecha}
            Opcion={Opcion}
            Cuantos={Cuantos}
            Estatus={Estatus}
            Costo={Costo}
            Subtota={Subtota}
            Iva={Iva}
            Total={Total}
            Subpadre={Subpadre}
            Update={Update}
            Id={Id}
            setPadreID={setPadreID}
            setHijoID={setHijoID}
            setNivelID={setNivelID}
            setVariableID={setVariableID}
            setNombre={setNombre}
            setCuenta={setCuenta}
            setCantidad={setCantidad}
            setUnidades={setUnidades}
            setX={setX}
            setFecha={setFecha}
            setOpcion={setOpcion}
            setCuantos={setCuantos}
            setEstatus={setEstatus}
            setCosto={setCosto}
            setSubtota={setSubtota}
            setIva={setIva}
            setTotal={setTotal}
            setSubpadre={setSubpadre}
            HijosGetService={HijosGetService}
        />
        <section style={{ paddingTop: 50, paddingBottom: 50 }}>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className="col-md-6">
                                <h2 className="text-black backend_titulo">Listado de Hijos</h2>
                            </div>
                            <div className="col-md-6 text-end">
                                <a className="btn btn-info" onClick={toggle}>Agregar Hijo</a>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr className="text-black">
                                                <th>#</th>
                                                <th>Cuenta Raiz</th>
                                                <th>Cuenta Padre</th>
                                                <th>Nombre</th>
                                                <th>Cuenta Presupuestal</th>
                                                <th>Cantidad</th>
                                                <th>Unidad</th>
                                                <th>X</th>
                                                <th>Costo</th>
                                                <th>Subtotal</th>
                                                <th>IVA</th>
                                                <th>Total</th>
                                                <th>Subpadre</th>
                                                <th>Editar</th>
                                                <th>Editar</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Hijos.map(({ id, nombre, nombre_padre, nombre_hijo, hijo, nivel_id, variables_id, cuenta_presupuental, cantidad_veces_pagos, unidad, x_cuantas_veces, fecha_inical_pagos, status, costo_unitario, subtotal, iva, total, subpadre }) => {
                                                    return <tr className="text-black" key={id}>
                                                        <td>{i++}</td>
                                                        <td className='text-uppercase fw-bold '>{nombre_padre}</td>
                                                        <td className='text-uppercase fw-bold text-danger'>{nombre_hijo}</td>
                                                        <td>{nombre}</td>
                                                        <td className='text-end'>{cuenta_presupuental}</td>
                                                        {
                                                            subpadre == 'No'
                                                                ?
                                                                <>
                                                                    <td className='text-center'>{cantidad_veces_pagos}</td>
                                                                    <td>{unidad}</td>
                                                                    <td className='text-center'>{x_cuantas_veces}</td>
                                                                    <td className='text-end'>${formatNumber(costo_unitario)}</td>
                                                                    <td className='text-end'>${formatNumber(subtotal)}</td>
                                                                    <td className='text-end'>${formatNumber(iva)}</td>
                                                                    <td className='text-end'>${formatNumber(total)}</td>
                                                                    <td className='text-center'>
                                                                        <div className={
                                                                            subpadre == 'Si'
                                                                            ?
                                                                            'badge bg-info text-wrap'
                                                                            :
                                                                            'badge bg-secondary text-wrap'
                                                                        }>
                                                                           {subpadre}
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <button className="btn btn-warning btn-sm"
                                                                            onClick={() => {
                                                                                setId(id);
                                                                                setNombre(nombre);
                                                                                setPadreID(nombre_padre);
                                                                                setHijoID(hijo);
                                                                                setNivelID(nivel_id);
                                                                                setVariableID(variables_id);
                                                                                setCuenta(cuenta_presupuental);
                                                                                setCantidad(cantidad_veces_pagos);
                                                                                setUnidades(unidad);
                                                                                setX(x_cuantas_veces);
                                                                                setFecha(fecha_inical_pagos);
                                                                                setEstatus(status);
                                                                                setCosto(costo_unitario);
                                                                                setSubtota(subtotal);
                                                                                setIva(iva);
                                                                                setTotal(total);
                                                                                setSubpadre(subpadre)
                                                                                setUpdate(true);
                                                                                setModal(true);
                                                                            }}>
                                                                            Editar
                                                                        </button>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <button className="btn btn-danger btn-sm" onClick={() => {
                                                                            HijosDeleteService({ id })
                                                                        }}>Eliminar
                                                                        </button>
                                                                    </td>
                                                                </>
                                                                :
                                                                <>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td className='text-center'>
                                                                        <div className={
                                                                            subpadre == 'Si'
                                                                            ?
                                                                            'badge bg-info text-wrap'
                                                                            :
                                                                            'badge bg-secondary text-wrap'
                                                                        }>
                                                                           {subpadre}
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <button className="btn btn-warning btn-sm"
                                                                            onClick={() => {
                                                                                setId(id);
                                                                                setNombre(nombre);
                                                                                setPadreID(nombre_padre);
                                                                                setHijoID(hijo);
                                                                                setNivelID(nivel_id);
                                                                                setVariableID(variables_id);
                                                                                setCuenta(cuenta_presupuental);
                                                                                setCantidad(cantidad_veces_pagos);
                                                                                setUnidades(unidad);
                                                                                setX(x_cuantas_veces);
                                                                                setFecha(fecha_inical_pagos);
                                                                                setEstatus(status);
                                                                                setCosto(costo_unitario);
                                                                                setSubtota(subtotal);
                                                                                setIva(iva);
                                                                                setTotal(total);
                                                                                setSubpadre(subpadre)
                                                                                setUpdate(true);
                                                                                setModal(true);
                                                                            }}>
                                                                            Editar
                                                                        </button>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <button className="btn btn-danger btn-sm" onClick={() => {
                                                                            HijosDeleteService({ id })
                                                                        }}>Eliminar
                                                                        </button>
                                                                    </td>
                                                                </>
                                                        }
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}