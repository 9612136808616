/* eslint-disable no-unused-vars */
import { useCallback, useState } from 'react';
import { OrdenesPagoGet } from '../service/ordenesPago';

export default function useUsers() {
    const [Ordenes, setOrdenes] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const OrdenesPagoGetService = useCallback((user_id, token) => {
        OrdenesPagoGet(user_id, token).then(response => {
            setOrdenes(response)
        })
    }, []);

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Ordenes,
        OrdenesPagoGetService,
    }
}