import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Trans } from "react-i18next";
import Image192 from "../../style/assets/images/imagen192.png";
import useLogin from "../../hoock/useLogin";
import ItemsSidebar from "./ItemsSidebar";
import { urlAbogado, urlCli, urlConta, urlPaths, urlProv } from "./utils/urls";

export default function Sidebar() {
  const { pathname } = useLocation();
  const { user, user_rol, imagen } = useLogin();
  const [Collapse, setCollapse] = useState(null);

  const ENDPOINT = "http://127.0.0.1:8000/";

  const CollapseShow = (key) => {
    if (Collapse === key) {
      setCollapse(null);
      console.log(Collapse);
    } else {
      setCollapse(key);
    }
  };

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
        <a className="sidebar-brand brand-logo" href="index.html">
          <img src={Image192} alt="logo" />
        </a>
        <a className="sidebar-brand brand-logo-mini" href="index.html">
          <img src={Image192} alt="logo" />
        </a>
      </div>
      <ul className="nav">
        <li className="nav-item profile">
          <div className="profile-desc">
            <div className="profile-pic">
              <div className="count-indicator">
                {user_rol === "proveedor" && (
                  <>
                    <img
                      className="img-xs rounded-circle"
                      src={ENDPOINT + "images/fotos/PROVEEDOR/" + imagen}
                      alt={user}
                    />
                    <span className="count bg-success"></span>
                  </>
                )}
                {user_rol === "cliente" && (
                  <>
                    {imagen ? (
                      <img
                        className="img-xs rounded-circle"
                        src={ENDPOINT + "images/fotos/CLIENTE/" + imagen}
                        alt={user}
                      />
                    ) : (
                      <img
                        className="img-xs rounded-circle"
                        src={`https://ui-avatars.com/api/?name=${user}&background=7BB428&color=fff&size=128`}
                        alt={user}
                      />
                    )}
                    <span className="count bg-success"></span>
                  </>
                )}
                {user_rol !== "proveedor" && user_rol !== "cliente" && (
                  <>
                    <img
                      className="img-xs rounded-circle"
                      src={require("../../assets/images/faces/perfil.png")}
                      alt={user}
                    />
                    <span className="count bg-success"></span>
                  </>
                )}
              </div>
              <div className="profile-name">
                <h5
                  className="mb-0 font-weight-normal text-white"
                  style={{ fontSize: 12 }}
                >
                  {user}
                </h5>
                <span style={{ textTransform: "capitalize" }}>{user_rol}</span>
              </div>
            </div>
          </div>
        </li>
        <li className="nav-item nav-category">
          <span className="nav-link text-white">
            <Trans>Barra de navegación</Trans>
          </span>
        </li>
        {(user_rol === "super administrador" &&
          urlPaths.map((item, index) => (
            <li
              className={`nav-item menu-items ${
                pathname === item.path ? "active" : ""
              }`}
              key={index}
            >
              <Link
                className="nav-link"
                to={item.path}
                style={{ marginLeft: -20 }}
                onClick={() => {
                  CollapseShow(index);
                }}
              >
                <div className="nav-link menu-expanded" data-toggle="collapse">
                  <span className="menu-icon">
                    <i className={item.icon}></i>
                  </span>
                  <span className="menu-title">{item.state}</span>
                </div>
              </Link>
              <div
                className={Collapse === index ? "collapse show" : "collapse"}
              >
                <ul className="nav flex-column sub-menu">
                  {item?.subMenu?.map((item2, index2) => (
                    <li className="nav-item">
                      <Link className="nav-link" to={item2.path}>
                        {item2.state}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          ))) ||
          (user_rol === "administrador" &&
            urlPaths.map((item, index) => (
              <ItemsSidebar item={item} index={index} pathname={pathname} />
            ))) ||
          (user_rol === "contabilidad" &&
            urlConta.map((item, index) => (
              <ItemsSidebar item={item} index={index} pathname={pathname} />
            ))) ||
          (user_rol === "cliente" &&
            urlCli.map((item, index) => (
              <ItemsSidebar item={item} index={index} pathname={pathname} />
            ))) ||
          (user_rol === "proveedor" &&
            urlProv.map((item, index) => (
              <ItemsSidebar item={item} index={index} pathname={pathname} />
            )))}

        {user_rol === "abogado" &&
          urlAbogado.map((item, index) => (
            <ItemsSidebar item={item} index={index} pathname={pathname} />
          ))}
      </ul>
    </nav>
  );
}
