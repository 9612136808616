const ENDPOINT = process.env.REACT_APP_API_URL_DEVELOP;

export async function NivelGet(token) {
    return fetch(`${ENDPOINT}NivelesGet`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function NivelAdd(data, token) {
    return fetch(`${ENDPOINT}NivelesAdd`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function NivelUpdate(data, token) {
    return fetch(`${ENDPOINT}NivelesUpdate`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function NivelDelete(data, token) {
    return fetch(`${ENDPOINT}NivelesDelete`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}
