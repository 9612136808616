/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {InputGroup, Input} from "reactstrap";
import Select from "react-select";
import useHijos from "../../hoock/useHijos";
import Swal from "sweetalert2";

const unidades = [
  {value: "unico", label: "Unico"},
  {value: "semanal", label: "Semanal"},
  {value: "quincenal", label: "Quincenal"},
  {value: "mensual", label: "Mensual"},
  {value: "bimestral", label: "Bimestral"},
  {value: "trimestral", label: "Trimestral"},
  {value: "semestral", label: "Semestral"},
  {value: "anual", label: "Anual"},
];

export default function Ventas({
  item,
  index,
  Arreglo,
  templete,
  Numerica,
  token,
  cliente_id,
  settemplete,
  setArreglo,
  setNumerica,
  setIsChecked,
  PadreID,
  HijoID,
}) {
  const {
    error,
    success,
    message,
    CuentaPre,
    setCuentaPre,
    PadreCuentaService,
    HijosCuentaService,
    Variables,
    VariableGetService,
    VariableAddService,
    Proveedores,
    ProveedorGetService,
  } = useHijos();
  const [SelectedVariable, setSelectedVariable] = useState([]);
  const [SelectedOpcion, setSelectedOpcion] = useState([]);
  const [NombreVari, setNombreVari] = useState("");
  const [NumeracionVari, setNumeracionVari] = useState("");

  useEffect(() => {
    if (index == 0) {
      if (PadreID > 0 && HijoID == null) {
        PadreCuentaService({id: PadreID, token});
      } else {
        HijosCuentaService({id: HijoID, token});
      }
    }
  }, [PadreID, HijoID]);

  useEffect(() => {
    if (index == 0) {
      ChangeInfo(CuentaPre, "CuentaPre");
      setNumerica(CuentaPre);
    }
  }, [CuentaPre]);

  useEffect(() => {
    VariableGetService(token);
  }, [VariableGetService]);

  useEffect(() => {
    ProveedorGetService(cliente_id, token);
  }, [ProveedorGetService]);

  const ChangeInfo = (value, name) => {
    const newArreglo = Arreglo.map((item, i) => {
      if (i === index) {
        return {...item, [name]: value};
      } else {
        return item;
      }
    });

    setArreglo(newArreglo);
  };

  const ChangeData = (value, name) => {
    if (name === "VariableID") {
      functionVariable(value.precio, item.Cantidad, item.X, item.Cuantos, [
        value,
        name,
      ]);
    } else {
      const newArreglo = Arreglo.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [name]: value,
          };
        } else {
          return item;
        }
      });
      setArreglo(newArreglo);
    }
  };

  const NuevaSerie = () => {
    const separar = Numerica.split(".");
    const decimal = parseInt(separar[separar.length - 1]) + 1;
    separar[separar.length - 1] = decimal;
    const v = separar.toString();
    const valor = v.replace(/,/g, ".");

    setArreglo([
      ...Arreglo,
      {
        NombreH: "",
        CuentaPre: valor,
        VariableID: "",
        ProveedorID: "",
        Cantidad: "",
        X: "",
        Unidades: "",
        MostrarVariable: false,
        NombreVari: "",
        NumeracionVari: "",
        IsChecked: true,
        Fecha: "",
        Opcion: "",
        Cuantos: "",
        Costo: "",
        Subtota: "",
        Iva: "",
        Total: "",
      },
    ]);
    setNumerica(valor);
  };
  const EliminarSerie = (id) => {
    const items = Arreglo.filter((item, index) => index !== id);
    setArreglo(items);

    const separar = Numerica.split(".");
    const decimal = parseInt(separar[separar.length - 1]) - 1;
    separar[separar.length - 1] = decimal;
    const v = separar.toString();
    const valor = v.replace(/,/g, ".");

    setNumerica(valor);
  };

  const functionVariable = (
    precio,
    cantidad,
    x,
    cuantosPagos,
    options = null
  ) => {
    let sub;
    if (cuantosPagos) {
      sub = cantidad * x * precio * cuantosPagos;
    } else {
      sub = cantidad * x * precio;
    }

    // validar si checkbox esta activo
    if (item.IsChecked === true) {
      ChangeInfo(sub * 1.16, "Total");
    } else {
      ChangeInfo(sub, "Total");
    }

    if (options) {
      const newArreglo = Arreglo.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            Costo: precio,
            Cantidad: cantidad,
            X: x,
            Subtota: sub,
            Iva: sub * 0.16,
            Total: Math.round(sub * 1.16),
            Cuantos: cuantosPagos,
            [options[1]]: options[0],
          };
        } else {
          return item;
        }
      });
      setArreglo(newArreglo);
    } else {
      const newArreglo = Arreglo.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            Costo: precio,
            Cantidad: cantidad,
            X: x,
            Subtota: sub,
            Iva: sub * 0.16,
            Total: Math.round(sub * 1.16).toFixed(2),
            Cuantos: cuantosPagos,
          };
        } else {
          return item;
        }
      });
      setArreglo(newArreglo);
    }
  };

  const guardarVariable = () => {
    const data = new FormData();
    data.append("nombre", NombreVari);
    data.append("cantidad", NumeracionVari);

    VariableAddService(data, token);
    Swal.fire({
      title: "Registrando variable",
      text: "Loading...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      icon: "question",
      onOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const handleOnChange = () => {
    ChangeInfo(!item.IsChecked, "IsChecked");
  };

  useEffect(() => {
    // validar si item.Subtota es undefined
    if (item.Subtota === undefined) {
      return;
    } else {
      // validar si checkbox esta activo
      if (item.IsChecked === true) {
        ChangeInfo(parseFloat(item.Subtota * 1.16).toFixed(2), "Total");
      } else {
        ChangeInfo(item.Subtota, "Total");
      }
    }
  }, [item.IsChecked, item.Subtota]);

  useEffect(() => {
    if (success === true) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
      VariableGetService();
      setNombreVari("");
      setNumeracionVari("");
    }
  }, [VariableGetService, success]);

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <label>Proveedor:</label>
          <Select
            value={item.ProveedorID}
            onChange={(e) => {
              ChangeData(e, "ProveedorID");
            }}
            options={Proveedores}
            placeholder={"Proveedor"}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <label>Descripcion:</label>

          <Input
            placeholder="Nombre"
            value={item.NombreH}
            onChange={(e) => ChangeInfo(e.target.value, "NombreH")}
          />
        </div>
        <div className="col-md-3">
          <label>Cuenta Presupuestal:</label>

          <Input
            placeholder="Cuenta Presupuestal"
            disabled={true}
            value={item.CuentaPre}
          />
        </div>
        <div className="col-md-4">
          <label>Nombre de la Variable:</label>
          <div className="row">
            <div className="col-md-6">
              <Select
                value={item.VariableID}
                onChange={(e) => {
                  ChangeData(e, "VariableID");
                }}
                options={Variables}
                placeholder={"Variable"}
              />
            </div>
            <div className="col-md-6">
              <button
                className="btn btn-info btn-sm"
                onClick={(e) =>
                  ChangeInfo(!item.MostrarVariable, "MostrarVariable")
                }
              >
                <i className="mdi mdi-plus-circle"></i> Agregar variable
              </button>
            </div>
          </div>
          {item.MostrarVariable === true ? (
            <div className="mb-3 mt-3">
              <input
                type="text"
                className="form-control"
                placeholder="Nombre variable"
                aria-label="Nombre variable"
                value={NombreVari}
                onChange={(e) => setNombreVari(e.target.value)}
              />
              <input
                type="text"
                className="form-control"
                placeholder="Valor variable"
                aria-label="Valor variable"
                value={NumeracionVari}
                onChange={(e) => setNumeracionVari(e.target.value)}
              />
              <button
                className="btn btn-secondary"
                type="button"
                id="button-addon2"
                onClick={() => guardarVariable()}
              >
                Agregar
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-2">
          <div className="form-check mt-4">
            <input
              className="form-check-input"
              type="checkbox"
              checked={item.IsChecked}
              onChange={handleOnChange}
            />
            <label
              className="form-check-label"
              htmlFor="flexCheckIndeterminate"
            >
              Agregar IVA
            </label>
          </div>
        </div>
        <div className="col-md-3">
          <label>Cantidad:</label>

          <Input
            type={"number"}
            placeholder="Cantidad"
            value={item.Cantidad}
            onChange={(e) => {
              functionVariable(
                item.Costo,
                e.target.value,
                item.X,
                item.Cuantos
              );
            }}
          />
        </div>
        <div className="col-md-3">
          <label>Unidad:</label>

          <Input
            placeholder="Unidad"
            value={item.Unidades}
            onChange={(e) => ChangeInfo(e.target.value, "Unidades")}
          />
        </div>
        <div className="col-md-3">
          <label>X:</label>

          <Input
            type={"number"}
            placeholder="X"
            value={item.X}
            onChange={(e) => {
              functionVariable(
                item.Costo,
                item.Cantidad,
                e.target.value,
                item.Cuantos
              );
            }}
          />
        </div>
        <div className="col-md-3">
          <label>Costo:</label>

          <Input
            placeholder="Costo"
            value={item.Costo}
            disabled={item.VariableID === "" ? false : true}
            onChange={(e) => {
              functionVariable(
                e.target.value,
                item.Cantidad,
                item.X,
                item.Cuantos
              );
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <label>Fecha primer pago:</label>

          <Input
            type="date"
            value={item.Fecha}
            onChange={(e) => ChangeInfo(e.target.value, "Fecha")}
          />
        </div>
        <div className="col-md-3">
          <label>Opcion de pagos:</label>
          <Select
            value={item.Opcion}
            onChange={(e) => {
              ChangeData(e, "Opcion");
            }}
            options={unidades}
            placeholder={"Opcion de pagos"}
          />
        </div>
        <div className="col-md-3">
          <label>Cuantos pagos:</label>

          <Input
            type={"number"}
            placeholder="Cuantos pagos"
            value={item.Cuantos}
            onChange={(e) => {
              functionVariable(
                item.Costo,
                item.Cantidad,
                item.X,
                e.target.value
              );
            }}
          />
        </div>
        <div className="col-md-3 text-end">
          <button
            className="btn btn-info btn-sm mt-4"
            style={{marginRight: 10}}
            onClick={() => NuevaSerie()}
          >
            <i className="mdi mdi-plus-circle"></i>
          </button>
          {index >= 1 ? (
            <button
              className="btn btn-danger btn-sm mt-4"
              onClick={() => EliminarSerie(index)}
            >
              <i className="mdi mdi-close-circle"></i>
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <strong>Subtotal:</strong>

          <Input
            placeholder="Subtota"
            value={item.Subtota}
            disabled={true}
            onChange={(e) => ChangeInfo(e.target.value, "Subtota")}
          />
        </div>
        <div className="col-md-3">
          {item.IsChecked === true ? (
            <>
              <strong>Iva:</strong>

              <Input
                placeholder="Iva"
                value={item.Iva}
                disabled={true}
                onChange={(e) => ChangeInfo(e.target.value, "Iva")}
              />
            </>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-3">
          <strong>Total:</strong>

          <Input
            placeholder="Total"
            value={item.Total}
            disabled={true}
            onChange={(e) => ChangeInfo(e.target.value, "Total")}
          />
        </div>
        <div className="col-md-12 mb-5 mt-5">
          <hr />
        </div>
      </div>
    </>
  );
}
