/* eslint-disable eqeqeq */
import { useCallback, useState } from 'react';
import { PadresGet, NivelesGet, VariableGet, ProveedorGet, PadreCuenta, hijosCuenta, HijosHijosAdd, HijosGet, HijosSubAdd, HijosHijosAdd_, HijosHijosUpdate_ , HijosAdd, HijosUpdate, HijosDelete, HijosDateGet, PresupuestoHijosGet } from '../service/hijos';
import { VariableAdd } from '../service/variables';

export default function useUsers() {
    const [Padres, setPadres] = useState([]);
    const [Niveles, setNiveles] = useState([]);
    const [Variables, setVariables] = useState([]);
    const [Proveedores, setProveedores] = useState([]);
    const [Hijo, setHijo] = useState([]);
    const [Hijos, setHijos] = useState([]);
    const [CuentaPre, setCuentaPre] = useState([]);
    const [Fechas, setFechas] = useState([]);
    const [Presupuesto, setPresupuesto] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const PadresGetService = useCallback((token) => {
        PadresGet(token).then(response => {
            setPadres(response)
        })
    }, []);

    const NivelesGetService = useCallback((token) => {
        NivelesGet(token).then(response => {
            setNiveles(response)
        })
    }, []);

    const HijosGetService = useCallback((token) => {
        HijosGet(token).then(response => {
            setHijos(response)
        })
    }, []);

    const VariableGetService = useCallback((token) => {
        VariableGet(token).then(response => {
            setVariables(response)
        })
    }, []);

    const ProveedorGetService = useCallback((id, token) => {
        ProveedorGet(id, token).then(response => {
            setProveedores(response)
        })
    }, []);
    /*
     * Hijos de Padres
     */
    const HijosHijosService = useCallback(({
        id, token
    }) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        HijosHijosAdd({
            id, token
        }).then(response => {
            setHijo(response)
        })
    }, [])
    /*
     * Cuenta Presupuestal
     */
    const PadreCuentaService = useCallback(({
        id, token
    }) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        PadreCuenta({
            id, token
        }).then(response => {
            if (response.hijos == 'no') {
                const entero = response.padre[0].cuenta_presupuental;
                const decimal = 1;
                setCuentaPre(entero + '.' + decimal)
            } else {
                    const separar = response?.padre[0]?.cuenta_presupuental.split('.');
                    const decimal = parseInt(separar[separar?.length - 1]) + 1;
                    separar[separar?.length - 1] = decimal;
                    const v = separar?.toString();
                    const valor = v?.replace(/,/g, '.');
                    setCuentaPre(valor)
            }
        })
    }, [])

    const HijosCuentaService = useCallback(({
        id, token
    }) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        hijosCuenta({
            id, token
        }).then(response => {
            if (response.hijo == 'no') {
                const entero = response.hijos[0].cuenta_presupuental;
                const decimal = 1;
                setCuentaPre(entero + '.' + decimal)
            }else{
                const separar = response?.hijos[0]?.cuenta_presupuental.split('.');
                const decimal = parseInt(separar[separar.length - 1]) + 1;
                separar[separar.length - 1] = decimal;
                const v = separar.toString();
                const valor = v.replace(/,/g, '.');
                setCuentaPre(valor)
            }
            
        })
    }, [])
    /*
     * Variables
     */
    const VariableAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        VariableAdd(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let nombre = errores['nombre'];
                let cantidad = errores['cantidad'];
                if (nombre != null) {
                    setState({ loading: false, error: true, message: nombre[0], success: false })
                }
                if (cantidad != null) {
                    setState({ loading: false, error: true, message: cantidad[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Variable registrado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al registrar Variable',
                        success: false
                    })
                }
            }
        })
    }, [])
    /*
     * Agregar Subpadre
     */
    const HijosSubAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        HijosSubAdd(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let nombre = errores['nombre'];
                let padre_id = errores['padre_id'];
                let subpadre = errores['subpadre'];
                let cuenta_presupuental = errores['cuenta_presupuental'];
                if (nombre != null) { setState({ loading: false, error: true, message: nombre[0], success: false }) }
                if (padre_id != null) { setState({ loading: false, error: true, message: padre_id[0], success: false }) }
                if (subpadre != null) { setState({ loading: false, error: true, message: subpadre[0], success: false }) }
                if (cuenta_presupuental != null) { setState({ loading: false, error: true, message: cuenta_presupuental[0], success: false }) }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Subpadre registrado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al registrar Subpadre',
                        success: false
                    })
                }
            }
        })
    }, [])
    /*
     * Agregar Hijo_
     */
    const HijosHijosAdd_Service = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        HijosHijosAdd_(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let PadreID = errores['PadreID'];
                let HijoID = errores['HijoID'];
                let nivel_id = errores['nivel_id'];
                let subpadre = errores['subpadre'];
                if (PadreID != null) { setState({ loading: false, error: true, message: PadreID[0], success: false }) }
                if (HijoID != null) { setState({ loading: false, error: true, message: HijoID[0], success: false }) }
                if (nivel_id != null) { setState({ loading: false, error: true, message: nivel_id[0], success: false }) }
                if (subpadre != null) { setState({ loading: false, error: true, message: subpadre[0], success: false }) }

            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Hijo registrado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al registrar Hijo',
                        success: false
                    })
                }
            }
        })
    }, [])
    /*
     * Actualizar Hijo_
     */
    const HijosHijosUpdate_Service = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        HijosHijosUpdate_(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let nombre = errores['nombre'];
                let con_iva = errores['con_iva'];
                let cantidad_veces_pagos = errores['cantidad_veces_pagos'];
                let unidad = errores['unidad'];
                let x_cuantas_veces = errores['x_cuantas_veces'];
                let costo_unitario = errores['costo_unitario'];
                let subtotal = errores['subtotal'];
                let total = errores['total'];

                if (nombre != null) { setState({ loading: false, error: true, message: nombre[0], success: false }) }
                if (con_iva != null) { setState({ loading: false, error: true, message: con_iva[0], success: false }) }
                if (cantidad_veces_pagos != null) { setState({ loading: false, error: true, message: cantidad_veces_pagos[0], success: false }) }
                if (unidad != null) { setState({ loading: false, error: true, message: unidad[0], success: false }) }
                if (x_cuantas_veces != null) { setState({ loading: false, error: true, message: x_cuantas_veces[0], success: false }) }
                if (costo_unitario != null) { setState({ loading: false, error: true, message: costo_unitario[0], success: false }) }
                if (subtotal != null) { setState({ loading: false, error: true, message: subtotal[0], success: false }) }
                if (total != null) { setState({ loading: false, error: true, message: total[0], success: false }) }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Hijo actualizado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al actualizar Hijo',
                        success: false
                    })
                }
            }
        })
    }, [])
    /*
     * Agregar Hijos
     */
    const HijosAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        HijosAdd(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let nombre = errores['nombre'];
                let email = errores['email'];
                let password = errores['password'];
                let tipo_Hijos = errores['tipo_Hijos'];
                if (nombre != null) {
                    setState({ loading: false, error: true, message: nombre[0], success: false })
                }
                if (email != null) {
                    setState({ loading: false, error: true, message: email[0], success: false })
                }
                if (password != null) {
                    setState({ loading: false, error: true, message: password[0], success: false })
                }
                if (tipo_Hijos != null) {
                    setState({ loading: false, error: true, message: tipo_Hijos[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Hijos registrado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al registrar Hijos',
                        success: false
                    })
                }
            }
        })
    }, [])

    /*
     * Actualizar Hijos
     */
    const HijosUpdateService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        HijosUpdate(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let nombre = errores['nombre'];
                let email = errores['email'];
                let tipo_Hijos = errores['tipo_Hijos'];
                if (nombre != null) {
                    setState({ loading: false, error: true, message: nombre[0], success: false })
                }
                if (email != null) {
                    setState({ loading: false, error: true, message: email[0], success: false })
                }
                if (tipo_Hijos != null) {
                    setState({ loading: false, error: true, message: tipo_Hijos[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Hijos actualizado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al actualizar Hijos',
                        success: false
                    })
                }
            }
        })
    }, [])
    /*
     * Eliminar Hijos
     */
    const HijosDeleteService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        HijosDelete(
            data, token
        ).then(response => {
            if (response.guardado === true) {
                setState({
                    loading: false,
                    error: false,
                    message: 'Hijos eliminado con exito',
                    success: true
                })
            } else {
                setState({
                    loading: false,
                    error: true,
                    message: 'Error al eliminar Hijos',
                    success: false
                })
            }
        })
    }, [])

    const HijosDateGetService = useCallback((id, token) => {
        HijosDateGet(id, token).then(response => {
            setFechas(response)
        })
    }, []);

    const PresupuestoHijosGetService = useCallback((id, token) => {
        PresupuestoHijosGet(id, token).then(response => {
            setPresupuesto(response)
        })
    }, []);

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Variables,
        Proveedores,
        Padres,
        Niveles,
        Hijo,
        Hijos,
        CuentaPre,
        Fechas,
        Presupuesto,
        setCuentaPre,
        VariableGetService,
        ProveedorGetService,
        PadresGetService,
        NivelesGetService,
        HijosGetService,
        HijosHijosService,
        HijosCuentaService,
        PadreCuentaService,
        VariableAddService,
        HijosSubAddService,
        HijosHijosAdd_Service,
        HijosHijosUpdate_Service,
        HijosAddService,
        HijosUpdateService,
        HijosDeleteService,
        HijosDateGetService,
        PresupuestoHijosGetService
    }
}