/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import useFechas from "../../hoock/useFechas";
// import ModalAdd from "./Modals/ModalAdd";
import {
  Grid,
  GridColumn,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { filterBy } from "@progress/kendo-data-query";
import Link from "../../components/Form/Link";
import Title from "../../components/Form/Title";
import { hideLoader, showLoader, setTitle } from "../../reducers/loaderReducer";
import { useDispatch } from "react-redux";
import useLogin from "../../hoock/useLogin";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const PageTemplate = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        left: "10px",
      }}
    >
      Page {props.pageNum} of {props.totalPages}
    </div>
  );
};

const initialDataState = {
  skip: 0,
  take: 10,
};

const initialFilter = {
  logic: "and",
  filters: [],
};

const initialSort = [
  // {
  //   field: "modelo",
  //   dir: "desc",
  // },
  // {
  //   field: "serie",
  //   dir: "desc",
  // },
  // {
  //   field: "fecha_venta",
  //   dir: "desc",
  // },
  // {
  //   field: "Venta",
  //   dir: "desc",
  // },
];

// arreglo de productos
const Prducts = [
  {
    id: "1",
    cantidad: "1",
    descripcion: "Contador de producción en set",
    partida: "1003",
    importe: 10000,
  },
  {
    id: "2",
    cantidad: "1",
    descripcion: "Contador de producción en set",
    partida: "1002",
    importe: 20000,
  },
];

export default function Index() {
  const { user_id, logout, token, proveedor_id, user_rol } = useLogin();
  const dispatch = useDispatch();
  const { loading, error, message, success, Fecha, FechasGetService } =
    useFechas();
  const [modal, setModal] = useState(false);
  const [Update, setUpdate] = useState(false);
  const [Id, setId] = useState("");
  const [Nombre, setNombre] = useState("");
  const [Numeracion, setNumeracion] = useState("");
  const [sort, setSort] = React.useState(initialSort);
  const [filter, setFilter] = React.useState(initialFilter);
  const [page, setPage] = React.useState(initialDataState);
  let i = 1;

  const toggle = () => {
    setModal(!modal);
    setUpdate(false);
    setId("");
    setNombre("");
    setNumeracion("");
  };

  useEffect(() => {
    dispatch(setTitle("Descargando Fechas de pago"));
    dispatch(showLoader());
    FechasGetService(proveedor_id, token);
    return () => {};
  }, []);

  useEffect(() => {
    if (Fecha.length >= 0) {
      dispatch(hideLoader());
      dispatch(setTitle(""));
    }
    return () => {};
  }, [Fecha]);

  useEffect(() => {
    if (success === true) {
      FechasGetService(proveedor_id, token);
    }
  }, [FechasGetService, success]);

  const pageChange = (event) => {
    setPage(event.page);
  };

  const formatNumber = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, success]);

  const generatePDF = () => {
    const doc = new jsPDF();

    var img = new Image();
    img.src = "/logo-login.jpg";
    //img.src = "/incidencias/images/Logo_Horizontal_ANAM.png";
    doc.addImage(img, "png", 10, 10, 80, 20);
    doc.setFontSize(12);
    doc.text(110, 10, "SUMO CINEMA DIGITAL COLOR, S.A. DE C.V.");
    doc.setFontSize(10);
    doc.text(110, 15, "RFC: SCD110511T22");
    doc.text(
      110,
      20,
      "Campeche 295-A, Col. Hipódromo Condesa, Alcaldía Cuauhtémoc. C.P. 06170",
      { align: "left", lineHeightFactor: 1, maxWidth: 100 }
    );
    doc.text(110, 29, "LOS HERMANOS SALVADOR");
    doc.text(110, 33, "(Producción de Sula Films, S.A. DE C.V.)");
    doc.text(110, 38, "SOLICITUD DE PAGO No. 6");
    doc.text(110, 43, "FECHA DE ELABORACION: 08-09-2021 7:21 PM");

    autoTable(doc, { html: "#my-pagos" });
    autoTable(doc, {
      theme: "grid",
      body: [
        [
          "PERIODO DE PAGO",
          "DEL 26 AL 30 DE ABRIL",
          "SEMANA",
          "4",
          "ETAPA",
          "Rodaje",
        ],
        [
          { content: "FECHA DE PAGO", colSpan: 1 },
          { content: "29-04-202", colSpan: 1 },
          { content: "FORMA DE PAGO", colSpan: 1 },
          { content: "03 - Transferencia electrónica de fondos", colSpan: 3 },
        ],
      ],

      startY: 50,
    });

    autoTable(doc, { html: "#my-departamento" });
    autoTable(doc, {
      theme: "plain",
      body: [
        [
          "DEPARTAMENTO PRESTADOR DEL SERVICIO ",
          "ELYSIAN SYS MEXICO, SA DE CV",
        ],
        ["PAGO A FAVOR DE:", "ELYSIAN SYS MÉXICO S.A. DE C.V."],
        ["R. F. C.", "ESM170628CK1"],
        ["BANCO", "INBURSA"],
        ["CUENTA - CLABE", "036180500422014822"],
      ],

      startY: 73,
    });

    autoTable(doc, { html: "#my-table" });
    autoTable(doc, {
      theme: "striped",
      head: [["CANTIDAD", "DESCRIPCIÓN", "PARTIDA P", "IMPORTE"]],
      body: Prducts.map((product) => {
        return [
          product.cantidad,
          product.descripcion,
          { content: product.partida, styles: { halign: "center" } },
          { content: "$" + formatNumber(product.importe), styles: { halign: "right" } }
        ];
      }),
      foot: [
        [
          "",
          "",
          "SUBTOTAL",
          { content: "$" + formatNumber("12760"), styles: { halign: "right" } },
        ],
        [
          "",
          "",
          "IVA",
          { content: "$" + formatNumber("2552"), styles: { halign: "right" } },
        ],
        [
          "",
          "",
          "TOTAL A PAGAR",
          { content: "$" + formatNumber("15312"), styles: { halign: "right" } },
        ],
        ["", "", "FOLIO FISCAL", "7204a394-cdb2-4352-92a4-3b7b26028341"],
      ],

      startY: 115,
    });

    autoTable(doc, { html: "#my-productor" });
    autoTable(doc, {
      theme: "grid",
      body: [
        ["PRODUCTOR", "DIRECCIÓN DE PRODUCCIÓN", "PRODUCTOR EJECUTIVO"],
        ["Alejandro Sugich", "Paola Méndez", "Julieta Perales"],
      ],
    });

    autoTable(doc, { html: "#my-nota" });
    autoTable(doc, {
      theme: "plain",
      body: [
        [
          "Nota",
          "El pago se hará a la cuenta bancaria indicada en esta solicitud de pago, por lo que se solicita a la empresa proveedora de servicios, que en caso de no ser la misma cuenta bancaria, se indique la cuenta a la que se hará el depósito.",
        ],
      ],
    });

    // doc.text(
    //   15,
    //   200,
    //   "Nota: ",
    //   { align: "left", lineHeightFactor: 1, maxWidth: 180 }
    // );

    // doc.save("demo.pdf");
    window.open(
      doc.output("bloburl"),
      "_blank",
      "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes,top=100,left=250,width=1200,height=800"
    );
  };

  return (
    <>
      {/* <ModalAdd
        modal={modal}
        toggle={toggle}
        Nombre={Nombre}
        Numeracion={Numeracion}
        Update={Update}
        Id={Id}
        token={token}
        setNombre={setNombre}
        setNumeracion={setNumeracion}
        FechasGetService={FechasGetService}
        proveedor_id={proveedor_id}
      /> */}
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Title title="Listado de Fechas de pago" className="text-left" />
          </div>
          {/* <div className="col-md-6 text-end">
            <a className="btn btn-info" onClick={toggle}>
              Agregar variable
            </a>
          </div> */}
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <Grid
              sortable={true}
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
              filterable={true}
              filter={filter}
              onFilterChange={(e) => setFilter(e.filter)}
              pageable={true}
              data={filterBy(
                Fecha.slice(page.skip, page.take + page.skip),
                filter
              )}
              skip={page.skip}
              take={page.take}
              total={Fecha.length}
              onPageChange={pageChange}
              style={{
                height: "420px",
              }}
            >
              <GridNoRecords>No hay informacion disponible</GridNoRecords>
              <GridColumn field="proyecto" title="Proyecto" />
              <GridColumn field="cuenta_padre" title="Cuenta Padre" />
              <GridColumn
                field="cuenta_presupuental"
                title="Cuenta Presupuestal"
              />
              <GridColumn field="fecha" title="Fechas de pagos" filter="text" />
              <GridColumn
                field="monto"
                title="Monto"
                filter="text"
                format="{0:c}"
              />
              <GridColumn
                field="Status"
                filter={false}
                filterable={false}
                cell={(a) => {
                  let status = a.dataItem.status;
                  return (
                    <td className=" text-center">
                      {(status == "Pagado" && (
                        <div className="badge badge-outline-success text-success">
                          Pagado
                        </div>
                      )) ||
                        (status == "Pendiente" && (
                          <div className="badge badge-outline-warning text-warning">
                            Pendiente
                          </div>
                        ))}
                    </td>
                  );
                }}
              />
              <GridColumn
                field="Orden PDF"
                filter={false}
                filterable={false}
                cell={(e) => {
                  let status = e.dataItem.status;
                  return (
                    <td className=" text-center">
                      <a
                        onClick={() => {
                          generatePDF();
                        }}
                        className="btn btn-danger btn-sm"
                      >
                        PDF
                      </a>
                    </td>
                  );
                }}
              />
              <GridColumn
                field="Agregar Factura"
                filter={false}
                filterable={false}
                cell={(e) => {
                  let status = e.dataItem.status;
                  return (
                    <td className=" text-center">
                      <a
                        onClick={() => {
                          generatePDF();
                        }}
                        className="btn btn-info btn-sm"
                      >
                        Factura
                      </a>
                    </td>
                  );
                }}
              />
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
