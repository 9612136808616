/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Input from "../../../components/Form/Input";
import useCliente from "../../../hoock/useCliente";
import Swal from "sweetalert2";

export default function ModalAdd({
  modal,
  toggle,
  Update,
  Id,
  token,
  ClienteGetService,
  form,
  setform,
}) {
  const { message, error, success, ClienteAddService, ClienteUpdateService } = useCliente();

  useEffect(() => {
    if (success === true) {
      ClienteGetService(token);
      toggle();
    }
  }, [ClienteGetService, success]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: JSON.stringify(message),
      });
    }
    return () => { };
  }, [error]);

  const createCliente = () => {
    if (Update) {
      const data = new FormData();
      data.append("id", Id);
      data.append("nombre_comercial", form.nombre_comercial);
      data.append("telefono", form.telefono);
      data.append("tipo", form.tipo);
      data.append("RFC", form.RFC);
      data.append("calle", form.calle);
      data.append("exterior", form.exterior);
      data.append("inerior", form.inerior);
      data.append("colonia", form.colonia);
      data.append("municipio", form.municipio);
      data.append("estado", form.estado);
      data.append("cp", form.cp);
      data.append("clave", form.clave);

      ClienteUpdateService(data, token);

      Swal.fire({
        title: "Actualizando cliente",
        text: "Loading...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: 'question',
        onOpen: () => {
          Swal.showLoading();
        }
      });

    } else {
      const data = new FormData();
      data.append("nombre_comercial", form.nombre_comercial);
      data.append("telefono", form.telefono);
      data.append("tipo", form.tipo);
      data.append("RFC", form.RFC);
      data.append("calle", form.calle);
      data.append("exterior", form.exterior);
      data.append("inerior", form.inerior);
      data.append("colonia", form.colonia);
      data.append("municipio", form.municipio);
      data.append("estado", form.estado);
      data.append("cp", form.cp);
      data.append("clave", form.clave);

      ClienteAddService(data, token);

      Swal.fire({
        title: "Registrando cliente",
        text: "Loading...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: 'question',
        onOpen: () => {
          Swal.showLoading();
        }
      });
    }
  };

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error])

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
      toggle();
    }
  }, [message, success])

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader className="backend_titulo" toggle={toggle}>
          Agregar Cliente
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-4">
              <Input
                label={"Nombre comercial"}
                placeholder="Ingrese nombre comercial"
                value={form.nombre_comercial}
                type={"text"}
                name={"nombre_comercial"}
                onChange={(e) =>
                  setform({ ...form, nombre_comercial: e.target.value })
                }
              />
            </div>
            <div className="col-md-4">
              <Input
                label={"Telefono"}
                placeholder="Ingrese telefono"
                value={form.telefono}
                type={"number"}
                name={"telefono"}
                onChange={(e) => setform({ ...form, telefono: e.target.value })}
              />
            </div>
            <div className="col-md-4">
              <Input
                label={"Tipo"}
                placeholder="Ingrese tipo"
                value={form.tipo}
                type={"text"}
                name={"tipo"}
                onChange={(e) => setform({ ...form, tipo: e.target.value })}
              />
            </div>
            <div className="col-md-4">
              <Input
                label={"RFC"}
                placeholder="Ingrese RFC"
                value={form.RFC}
                type={"text"}
                name={"RFC"}
                onChange={(e) => setform({ ...form, RFC: e.target.value.toUpperCase() })}
              />
            </div>
            <div className="col-md-4">
              <Input
                label={"Calle"}
                placeholder="Ingrese calle"
                value={form.calle}
                type={"text"}
                name={"calle"}
                onChange={(e) => setform({ ...form, calle: e.target.value })}
              />
            </div>
            <div className="col-md-4">
              <Input
                label={"Exterior"}
                placeholder="Ingrese exterior"
                value={form.exterior}
                type={"number"}
                name={"exterior"}
                onChange={(e) => setform({ ...form, exterior: e.target.value })}
              />
            </div>
            <div className="col-md-4">
              <Input
                label={"Interior"}
                placeholder="Ingrese interior"
                value={form.inerior}
                type={"number"}
                name={"inerior"}
                onChange={(e) => setform({ ...form, inerior: e.target.value })}
              />
            </div>
            <div className="col-md-4">
              <Input
                label={"Colonia"}
                placeholder="Ingrese colonia"
                value={form.colonia}
                type={"text"}
                name={"colonia"}
                onChange={(e) => setform({ ...form, colonia: e.target.value })}
              />
            </div>
            <div className="col-md-4">
              <Input
                label={"Municipio"}
                placeholder="Ingrese municipio"
                value={form.municipio}
                type={"text"}
                name={"municipio"}
                onChange={(e) =>
                  setform({ ...form, municipio: e.target.value })
                }
              />
            </div>
            <div className="col-md-4">
              <Input
                label={"Estado"}
                placeholder="Ingrese estado"
                value={form.estado}
                type={"text"}
                name={"estado"}
                onChange={(e) => setform({ ...form, estado: e.target.value })}
              />
            </div>
            <div className="col-md-4">
              <Input
                label={"CP"}
                placeholder="Ingrese CP"
                value={form.cp}
                type={"number"}
                name={"cp"}
                onChange={(e) => setform({ ...form, cp: e.target.value })}
              />
            </div>
            <div className="col-md-4">
              <Input
                label={"Clave"}
                placeholder="Ingrese clave"
                value={form.clave}
                type={"text"}
                name={"clave"}
                onChange={(e) => setform({ ...form, clave: e.target.value })}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="backend_boton" onClick={() => createCliente()}>
            Agregar
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
}
