const urlPaths = [
  {
    state: "Proyectos",
    icon: "mdi mdi-account-multiple",
    subMenu: [
      {
        path: "/proyectos",
        state: "Listado de proyectos",
        icon: "mdi mdi-account-multiple",
      },
      {
        path: "/variables",
        state: "Variables",
        icon: "mdi mdi-account-switch",
      },
      {
        path: "/clientes",
        state: "Clientes",
        icon: "mdi mdi-account-multiple",
      },
      {
        path: "/proveedores",
        state: "Proveedores",
        icon: "mdi mdi-account-multiple",
      },
    ],
  },

  {
    state: "Contabilidad",
    icon: "mdi mdi-chart-pie",
    subMenu: [
      {
        path: "/contabilidad",
        state: "Descargar Facturas",
        icon: "mdi mdi-chart-pie",
      },
      {
        path: "/reporte-facturas",
        state: "Reporte de Facturas",
        icon: "mdi mdi-format-line-spacing",
      },
      { path: "/facturar", state: "Facturar", icon: "mdi mdi-chart-pie" },
      {
        path: "/fiscal",
        state: "Subir Factura",
        icon: "mdi mdi-account-multiple",
      },
    ],
  },
  { path: "/usuarios", state: "Usuarios", icon: "mdi mdi-account-multiple" },
];

const urlConta = [
  {
    path: "/reporte-facturas",
    state: "Reporte de Facturas",
    icon: "mdi mdi-format-line-spacing",
  },
  {
    path: "/ordenes-pago",
    state: "Ordenes de pago",
    icon: "mdi mdi-format-list-bulleted-type",
  },
  { path: "/clientes", state: "Clientes", icon: "mdi mdi-account-multiple" },
];

const urlCli = [
  { path: "/proyectos", state: "Proyectos", icon: "mdi mdi-speedometer" },
  // { path: "/dashboard", state: "Presupuestos", icon: "mdi mdi-speedometer" },
  { path: "/variables", state: "Variables", icon: "mdi mdi-autorenew" },
  // {
  //   path: "/proveedores-contrato",
  //   state: "Proveedores contrato",
  //   icon: "mdi mdi-account-multiple",
  // },
  { path: "/proveedores", state: "Proveedores", icon: "mdi mdi-account-multiple" },
  { path: "/usuarios", state: "Usuarios", icon: "mdi mdi-account-multiple" },
  {
    path: "/ordenes-pago",
    state: "Ordenes de pago",
    icon: "mdi mdi-format-list-bulleted-type",
  },
  {
    path: "/contratos",
    state: "Contratos",
    icon: "mdi mdi-account-multiple",
  },
];

const urlProv = [
  {
    path: "/proyectos",
    state: "Proyectos",
    icon: "mdi mdi-speedometer",
  },
  {
    path: "/fechas-pago",
    state: "Fechas de pago",
    icon: "mdi mdi-account-switch",
  },
  { path: "/facturar", state: "Facturar", icon: "mdi mdi-chart-pie" },
  {
    path: "/fiscal",
    state: "Subir Facturas",
    icon: "mdi mdi-account-multiple",
  },
];

const urlAbogado = [
  {
    path: "/contratos",
    state: "Contratos",
    icon: "mdi mdi-account-multiple",
  },
];

export { urlPaths, urlConta, urlCli, urlProv, urlAbogado };
