const ENDPOINT = process.env.REACT_APP_API_URL_DEVELOP;

export async function VariableGet(token) {
    return fetch(`${ENDPOINT}VariablesHijosGet`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`

        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function ProveedorGet(id, token) {
    return fetch(`${ENDPOINT}ProveedorClienteGet/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`

        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function NivelesGet(token) {
    return fetch(`${ENDPOINT}NivelesHijosGet`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`

        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function PadresGet(token) {
    return fetch(`${ENDPOINT}PadresHijosGet`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`

        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function HijosGet(token) {
    return fetch(`${ENDPOINT}HijosGet`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`

        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function PadreCuenta({
    id, token
}) {
    return fetch(`${ENDPOINT}padreCuenta`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'post',
        body: JSON.stringify({
            id
        })
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function hijosCuenta({
    id, token
}) {
    return fetch(`${ENDPOINT}hijosCuenta`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'post',
        body: JSON.stringify({
            id
        })
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function HijosHijosAdd({
    id, token
}) {
    return fetch(`${ENDPOINT}HijosHijosGet`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'post',
        body: JSON.stringify({
            id
        })
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function HijosSubAdd(data, token) {
    return fetch(`${ENDPOINT}HijosSubAdd`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`

        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function HijosHijosAdd_(data, token) {
    return fetch(`${ENDPOINT}HijosHijosAdd`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`

        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function HijosHijosUpdate_(data, token) {
    return fetch(`${ENDPOINT}HijosHijosUpdate`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`

        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function HijosAdd(data, token) {
    return fetch(`${ENDPOINT}HijosAdd`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`

        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function HijosUpdate(data, token) {
    return fetch(`${ENDPOINT}HijosUpdate`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`

        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function HijosDelete(data, token) {
    return fetch(`${ENDPOINT}HijosDelete`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`

        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function HijosDateGet(id, token) {
    console.log(id);
    return fetch(`${ENDPOINT}HijosDate/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`

        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function PresupuestoHijosGet(id, token) {
    return fetch(`${ENDPOINT}PresupuestoHijosGet/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`

        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}