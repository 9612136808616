import { useCallback, useState } from 'react';
import { ProyectosGet, ProyectoGet, ClientesGet, ProyectosAdd, ProyectosUpdate, ProyectosDelete } from '../service/proyectos';

export default function useUsers() {
    const [Proyectos, setProyectos] = useState([]);
    const [Proyecto, setProyecto] = useState([]);
    const [Clientes, setClientes] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const ProyectosGetService = useCallback((token) => {
        ProyectosGet(token).then(response => {
            setProyectos(response ?? []);
        })
    }, []);

    const ProyectoGetService = useCallback((id, token) => {
        ProyectoGet(id, token).then(response => {
            setProyecto(response ?? []);
        })
    }, []);

    const ClientesGetService = useCallback((token) => {
        ClientesGet(token).then(response => {
            setClientes(response)
        })
    }, []);

    /*
     * Agregar Variable
     */
    const ProyectosAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        ProyectosAdd(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let clientes_id = errores['clientes_id'];
                let nombre = errores['nombre'];
                let empresa = errores['empresa'];
                let fecha_inicial = errores['fecha_inicial'];
                let fecha_final = errores['fecha_final'];
                let numero_users = errores['numero_users'];
                let total_personal = errores['total_personal'];
                let status = errores['status'];

                if (clientes_id != null) { setState({ loading: false, error: true, message: clientes_id[0], success: false }) }
                if (nombre != null) { setState({ loading: false, error: true, message: nombre[0], success: false }) }
                if (empresa != null) { setState({ loading: false, error: true, message: empresa[0], success: false }) }
                if (fecha_inicial != null) { setState({ loading: false, error: true, message: fecha_inicial[0], success: false }) }
                if (fecha_final != null) { setState({ loading: false, error: true, message: fecha_final[0], success: false }) }
                if (numero_users != null) { setState({ loading: false, error: true, message: numero_users[0], success: false }) }
                if (total_personal != null) { setState({ loading: false, error: true, message: total_personal[0], success: false }) }
                if (status != null) { setState({ loading: false, error: true, message: status[0], success: false }) }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Proyecto registrado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al registrar proyecto',
                        success: false
                    })
                }
            }
        })
    }, [])

    /*
     * Actualizar Variable
     */
    const ProyectosUpdateService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        ProyectosUpdate(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let clientes_id = errores['clientes_id'];
                let nombre = errores['nombre'];
                let empresa = errores['empresa'];
                let fecha_inicial = errores['fecha_inicial'];
                let fecha_final = errores['fecha_final'];
                let numero_users = errores['numero_users'];
                let total_personal = errores['total_personal'];
                let status = errores['status'];

                if (clientes_id != null) { setState({ loading: false, error: true, message: clientes_id[0], success: false }) }
                if (nombre != null) { setState({ loading: false, error: true, message: nombre[0], success: false }) }
                if (empresa != null) { setState({ loading: false, error: true, message: empresa[0], success: false }) }
                if (fecha_inicial != null) { setState({ loading: false, error: true, message: fecha_inicial[0], success: false }) }
                if (fecha_final != null) { setState({ loading: false, error: true, message: fecha_final[0], success: false }) }
                if (numero_users != null) { setState({ loading: false, error: true, message: numero_users[0], success: false }) }
                if (total_personal != null) { setState({ loading: false, error: true, message: total_personal[0], success: false }) }
                if (status != null) { setState({ loading: false, error: true, message: status[0], success: false }) }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Proyecto actualizado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al actualizar proyecto',
                        success: false
                    })
                }
            }
        })
    }, [])
    /*
     * Eliminar Variable
     */
    const ProyectosDeleteService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        ProyectosDelete(
            data, token
        ).then(response => {
            if (response.guardado === true) {
                setState({
                    loading: false,
                    error: false,
                    message: 'Proyecto eliminado con exito',
                    success: true
                })
            } else {
                setState({
                    loading: false,
                    error: true,
                    message: 'Error al eliminar proyecto',
                    success: false
                })
            }
        })
    }, [])

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Proyectos,
        Proyecto,
        Clientes,
        ProyectosGetService,
        ProyectoGetService,
        ClientesGetService,
        ProyectosAddService,
        ProyectosUpdateService,
        ProyectosDeleteService
    }
}