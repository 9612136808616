/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Title from "../../components/Form/Title";
import Input from "../../components/Form/Input";
import ReactSelect from "../../components/Form/ReactSelect";
import Button from "../../components/Form/Button";
import Link from "../../components/Form/Link";
import Table from "../../components/HOC´s/Contabilidad/Table";
import toast from "react-hot-toast";
import { facturasGet } from "../../service/contabilidad";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader, setTitle } from "../../reducers/loaderReducer";
import Pagination from "../../components/HOC´s/Contabilidad/Pagination";

export default function Index() {
  const dispatch = useDispatch();
  const [form, setform] = useState({
    rfc: "",
    razon_social: "",
    cp: "",
    clave: "",
  });

  const [form2, setform2] = useState({
    passCer: "",
    login: "",
    pass: "",
    archivoKey64: "",
    archivoCer64: "",
  });

  const pageSizes = [25, 50, 100];
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [offset, setOffset] = useState(0);
  const [buscador, setbuscador] = useState("");
  const [array_Completo, setarray_Completo] = useState([]);
  const [contabilidad, setcontabilidad] = useState([]);
  const [DocumentoKey, setDocumentoKey] = useState("");
  const [DocumentosCer, setDocumentosCer] = useState("");

  const buscarContabilidad = () => {};

  const dateSliceData = (data) => {
    const slice = data.slice(offset, offset + pageSize);
    setcontabilidad(slice);
    setCount(Math.ceil(data.length / pageSize));
  };

  const dataSlicePage = (pageSize2) => {
    const slice = array_Completo.slice(offset, offset + pageSize2);
    setcontabilidad(slice);
    setCount(Math.ceil(array_Completo.length / pageSize2));
  };

  const dataSlice = (offset2) => {
    const slice = array_Completo.slice(offset2, offset2 + pageSize);
    setcontabilidad(slice);
    setCount(Math.ceil(array_Completo.length / pageSize));
  };

  const handlePageChange = (value, tipo) => {
    if (tipo === 0) {
      setOffset(offset + pageSize);
      setPage(value);
      dataSlice(offset + pageSize);
    } else if (tipo === 1) {
      setOffset(offset - pageSize);
      setPage(value);
      dataSlice(offset - pageSize);
    }
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
    setOffset(0);
    dataSlicePage(event.target.value);
  };

  useEffect(() => {
    if (array_Completo.length > 0) {
      const value = filter();
      dateSliceData(value);
    }
    return () => {};
  }, [buscador]);

  const filter = () => {
    const text = buscador;
    const data = array_Completo;
    const newData = data.filter(function ({ uuid = "" }) {
      const itemId = uuid?.toString()?.toUpperCase() ?? "";
      const campo = itemId;
      const textData = text.toUpperCase();
      return campo.indexOf(textData) > -1;
    });
    return newData;
  };

  const changeKey = (event) => {
    setDocumentoKey(event.target.files[0]);
  };

  const changeCer = (event) => {
    setDocumentosCer(event.target.files[0]);
  };

  return (
    <div>
      <Title title="Descargar facturas por periodo" />
      <div className="row">
        <div className="col-md-12">
          <Input
            type="text"
            label="RFC"
            placeholder="RFC"
            name="rfc"
            value={form.rfc}
            onChange={(e) => {
              setform({ ...form, rfc: e.target.value.toUpperCase() });
            }}
          />
          <Input
            type="text"
            label="Razón social"
            placeholder="Razón social"
            name="razon_social"
            value={form.razon_social}
            onChange={(e) => {
              setform({ ...form, razon_social: e.target.value });
            }}
          />
          <Input
            type="text"
            label="Código postal"
            placeholder="Código postal"
            name="cp"
            value={form.cp}
            onChange={(e) => {
              setform({ ...form, cp: e.target.value });
            }}
          />
          <Input
            type="text"
            label="Clave"
            placeholder="Clave"
            name="clave"
            value={form.clave}
            onChange={(e) => {
              setform({ ...form, clave: e.target.value });
            }}
          />
        </div>
        <div className="col-md-12">
          <Input
            type="text"
            label="Usuario"
            placeholder="Usuario"
            name="login"
            value={form2.login}
            onChange={(e) => {
              setform2({ ...form2, login: e.target.value });
            }}
            disabled
          />
          <Input
            type="password"
            label="Contraseña"
            placeholder="Contraseña"
            name="pass"
            value={form2.pass}
            onChange={(e) => {
              setform2({ ...form2, pass: e.target.value });
            }}
            disabled
          />
          <Input
            type="password"
            label="Contraseña del certificado"
            placeholder="Contraseña del certificado"
            name="passCer"
            value={form2.passCer}
            onChange={(e) => {
              setform2({ ...form2, passCer: e.target.value });
            }}
            disabled
          />
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              <Input
                type="file"
                label="Archivo .key"
                name="archivoKey64"
                onChange={changeKey}
                disabled
              />
            </div>
            <div className="col-md-6">
              <Input
                type="file"
                label="Archivo .cer"
                name="archivoCer64"
                onChange={changeCer}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end gap-4 flex-wrap">
        <Button
          type="button"
          label="Buscar"
          onClick={() => {
            buscarContabilidad();
          }}
        />
      </div>

      <Table
        contabilidad={contabilidad}
        pageSizes={pageSizes}
        pageSize={pageSize}
        handlePageSizeChange={handlePageSizeChange}
        buscador={buscador}
        setbuscador={setbuscador}
      />
      <Pagination
        className="mt-3"
        count={count}
        page={page}
        handlePageChange={handlePageChange}
      />
    </div>
  );
}
