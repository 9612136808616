import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
} from "reactstrap";
import useProyectos from "../../../hoock/useProyectos";
import Select from "react-select";
import Swal from "sweetalert2";

const Estatus = [
  { value: "Activo", label: "Activo" },
  { value: "Pendiente", label: "Pendiente" },
  { value: "Cancelado", label: "Cancelado" },
];

export default function ModalAdd({
  modal,
  toggle,
  Client,
  Nombre,
  Empresa,
  FechaInicial,
  FechaFinal,
  NumeroUsers,
  TotalPersonal,
  Status,
  Productor,
  Director,
  Ejecutivo,
  Update,
  Id,
  token,
  user_role,
  cliente_id,
  setClient,
  setNombre,
  setEmpresa,
  setFechaInicial,
  setFechaFinal,
  setNumeroUsers,
  setTotalPersonal,
  setStatus,
  setProductor,
  setDirector,
  setEjecutivo,
  ProyectosGetService,
}) {
  const {
    error,
    message,
    loading,
    success,
    ProyectosAddService,
    ProyectosUpdateService,
    Clientes,
    ClientesGetService,
  } = useProyectos();
  const [SelectedStatus, setSelectedStatus] = useState([]);
  const [SelectedCliente, setSelectedCliente] = useState([]);

  useEffect(() => {
    if (success === true) {
      ProyectosGetService(token);
    }
  }, [ProyectosGetService, success]);

  useEffect(() => {
    ClientesGetService(token);
  }, [ClientesGetService]);

  const createUser = () => {
    if (Update) {
      const data = new FormData();
      data.append("id", Id);
      data.append("clientes_id", user_role === "cliente" ? cliente_id : Client);
      data.append("nombre", Nombre);
      data.append("empresa", Empresa);
      data.append("fecha_inicial", FechaInicial);
      data.append("fecha_final", FechaFinal);
      data.append("numero_users", NumeroUsers);
      data.append("total_personal", TotalPersonal);
      data.append("status", Status);
      data.append("productor", Productor);
      data.append("director_de_produccion", Director);
      data.append("productor_ejecutivo", Ejecutivo);

      ProyectosUpdateService(data, token);

      Swal.fire({
        title: "Actualizando proyecto",
        text: "Loading...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: "question",
        onOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      const data = new FormData();
      data.append("clientes_id", user_role === "cliente" ? cliente_id : Client);
      data.append("nombre", Nombre);
      data.append("empresa", Empresa);
      data.append("fecha_inicial", FechaInicial);
      data.append("fecha_final", FechaFinal);
      data.append("numero_users", NumeroUsers);
      data.append("total_personal", TotalPersonal);
      data.append("status", Status);
      data.append("productor", Productor);
      data.append("director_de_produccion", Director);
      data.append("productor_ejecutivo", Ejecutivo);

      ProyectosAddService(data, token);

      Swal.fire({
        title: "Registrando proyecto",
        text: "Loading...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: "question",
        onOpen: () => {
          Swal.showLoading();
        },
      });
    }
  };

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
      setNombre("");
      toggle();
    }
  }, [message, success]);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader className="backend_titulo" toggle={toggle}>
          {Update ? "Actualizar proyecto" : "Agregar proyecto"}
        </ModalHeader>
        <ModalBody>
          {user_role === "cliente" ? null : (
            <div className="row">
              <div className="col-md-12">
                <label>Clientes</label>
                <Select
                  value={SelectedCliente}
                  onChange={(e) => {
                    setClient(e.value);
                    setSelectedCliente(e);
                  }}
                  options={Clientes}
                  placeholder={Client ? Client : "Clientes"}
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-md-6">
              <label>Nombre del proyecto</label>
             
                <Input
                  placeholder="Nombre"
                  value={Nombre}
                  onChange={(e) =>
                    setNombre(e.target.value.toLocaleUpperCase())
                  }
                />
              
            </div>
            <div className="col-md-6">
              <label>Empresa</label>
             
                <Input
                  placeholder="Empresa"
                  value={Empresa}
                  onChange={(e) => setEmpresa(e.target.value)}
                />
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Fecha Inicial</label>
             
                <Input
                  type="date"
                  placeholder="Fecha inicial"
                  value={FechaInicial}
                  onChange={(e) => setFechaInicial(e.target.value)}
                />
              
            </div>
            <div className="col-md-6">
              <label>Fecha Final</label>
             
                <Input
                  type="date"
                  placeholder="Fecha final"
                  value={FechaFinal}
                  onChange={(e) => setFechaFinal(e.target.value)}
                />
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Numero de usuarios</label>
             
                <Input
                  placeholder="Numero de usuarios"
                  value={NumeroUsers}
                  onChange={(e) => setNumeroUsers(e.target.value)}
                />
              
            </div>
            <div className="col-md-6">
              <label>Total de personal</label>
             
                <Input
                  placeholder="Total de personal"
                  value={TotalPersonal}
                  onChange={(e) => setTotalPersonal(e.target.value)}
                />
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Productor</label>
             
                <Input
                  placeholder="Total de personal"
                  value={Productor}
                  onChange={(e) => setProductor(e.target.value)}
                />
              
            </div>
            <div className="col-md-4">
              <label>Director de Produccion</label>
             
                <Input
                  placeholder="Total de personal"
                  value={Director}
                  onChange={(e) => setDirector(e.target.value)}
                />
              
            </div>
            <div className="col-md-4">
              <label>Productor Ejecutivo</label>
             
                <Input
                  placeholder="Total de personal"
                  value={Ejecutivo}
                  onChange={(e) => setEjecutivo(e.target.value)}
                />
              
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Estatus</label>
              <Select
                value={SelectedStatus}
                onChange={(e) => {
                  setStatus(e.value);
                  setSelectedStatus(e);
                }}
                options={Estatus}
                placeholder={Status ? Status : "Status"}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="backend_boton" onClick={() => createUser()}>
            {Update ? "Actualizar" : "Agregar"}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
}
