import React from "react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

export default function Table(props) {
  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover">
        <TableHeader key={"contabilidad"} {...props} />
        <TableBody contabilidad={props.contabilidad} />
      </table>
    </div>
  );
}
