import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, Input } from 'reactstrap';
import usePerfil from '../../../hoock/usePerfil';
import Swal from "sweetalert2";

export default function ModalDir({
    ModalPassword, togglePassword,
    Password,
    token, user_id, user_rol,
    setPassword, PerfilProveGetService, PerfilCliGetService }) {

    const { error, message, success, PasswordUpdateService } = usePerfil();

    useEffect(() => {
        if (success === true && user_rol === 'cliente') {
            PerfilCliGetService(user_id, token)
        }
    }, [PerfilCliGetService, success])

    useEffect(() => {
        if (success === true && user_rol === 'proveedor') {
            PerfilProveGetService(user_id, token)
        }
    }, [PerfilProveGetService, success])


    const createUser = () => {
        const data = new FormData();
        data.append("user_id", user_id);
        data.append("password", Password);
        PasswordUpdateService(data, token);
    }

    useEffect(() => {
        if (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                confirmButtonColor: "#3b82f6",
                text: message,
            });
        }
    }, [message, error])

    useEffect(() => {
        if (success) {
            Swal.fire({
                icon: "success",
                title: "Exito",
                confirmButtonColor: "#3b82f6",
                text: message,
            });
            togglePassword();
        }
    }, [message, success])

    return <>
        <Modal isOpen={ModalPassword} toggle={togglePassword} size='lg'>
            <ModalHeader className="backend_titulo" toggle={togglePassword}>Actualizar Contraseña</ModalHeader>
            <ModalBody>
                <div className='row'>
                    <div className="col-md-6">
                        <label>Password:</label>
                       
                            <Input type='password' placeholder="Password" value={Password} onChange={e => setPassword(e.target.value)} />
                        
                    </div>
                </div>

            </ModalBody>
            <ModalFooter>
                <Button className="backend_boton" onClick={(() => createUser())}>Actualizar</Button>
            </ModalFooter>
        </Modal>
    </>
}
