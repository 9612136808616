/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
} from "reactstrap";
import usePresupuesto from "../../../hoock/usePresupuesto";
import Swal from "sweetalert2";

export default function ModalAdd({
  ModalAportacion,
  toggleAportacion,
  Monto,
  setMonto,
  token,
  IdAportacion,
  id,
  PresupuestosGetService,
}) {
  const {error, message, success, PresupuestosAportacionUpdateService} =
    usePresupuesto();

  useEffect(() => {
    if (success == true) {
      PresupuestosGetService(id, token);
      toggleAportacion();
    }
  }, [PresupuestosGetService, success]);

  const AgregarAportacion = () => {
    const data = new FormData();
    data.append("id", IdAportacion);
    data.append("Monto", Monto);

    PresupuestosAportacionUpdateService(data, token);

    Swal.fire({
      title: "Agregando aportacion a presupuesto",
      text: "Loading...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      icon: "question",
      onOpen: () => {
        Swal.showLoading();
      },
    });
  };

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
      setMonto("");
      toggleAportacion();
    }
  }, [message, success]);

  return (
    <>
      <Modal isOpen={ModalAportacion} toggle={toggleAportacion}>
        <ModalHeader className="backend_titulo" toggle={toggleAportacion}>
          Agregar Aportación
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <label>Monto de aportación</label>

              <Input
                placeholder="nombre"
                value={Monto}
                onChange={(e) => setMonto(e.target.value)}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => AgregarAportacion()}>
            Agregar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
