import { useCallback, useState } from 'react';
import { UsuarioGet, UsuarioAdd, UsuarioUpdate, UsuarioDelete } from '../service/usuarios';

export default function useUsers() {
    const [Usuarios, setUsuarios] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const UsuariosGetService = useCallback((token) => {
        UsuarioGet(token).then(response => {
            setUsuarios(response)
        })
    }, []);
    /*
     * Agregar usuario
     */
    const UsuariosAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        UsuarioAdd(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores !== undefined) {
                let nombre = errores['nombre'];
                let email = errores['email'];
                let password = errores['password'];
                let rol = errores['rol'];
                let status = errores['status'];
                if (nombre != null) {
                    setState({ loading: false, error: true, message: nombre[0], success: false })
                }
                if (email != null) {
                    setState({ loading: false, error: true, message: email[0], success: false })
                }
                if (password != null) {
                    setState({ loading: false, error: true, message: password[0], success: false })
                }
                if (rol != null) {
                    setState({ loading: false, error: true, message: rol[0], success: false })
                }
                if (status != null) {
                    setState({ loading: false, error: true, message: status[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Usuario registrado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al registrar usuario',
                        success: false
                    })
                }
            }
        })
    }, [])

    /*
     * Actualizar usuario
     */
    const UsuariosUpdateService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        UsuarioUpdate(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores !== undefined) {
                let nombre = errores['nombre'];
                let email = errores['email'];
                let rol = errores['rol'];
                let status = errores['status'];
                if (nombre != null) {
                    setState({ loading: false, error: true, message: nombre[0], success: false })
                }
                if (email != null) {
                    setState({ loading: false, error: true, message: email[0], success: false })
                }
                if (rol != null) {
                    setState({ loading: false, error: true, message: rol[0], success: false })
                }
                if (status != null) {
                    setState({ loading: false, error: true, message: status[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Usuario actualizado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al actualizar usuario',
                        success: false
                    })
                }
            }
        })
    }, [])
    /*
     * Eliminar usuario
     */
    const UsuariosDeleteService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        UsuarioDelete(
            data, token
        ).then(response => {
            if (response.guardado === true) {
                setState({
                    loading: false,
                    error: false,
                    message: 'Usuario eliminado con exito',
                    success: true
                })
            } else {
                setState({
                    loading: false,
                    error: true,
                    message: 'Error al eliminar usuario',
                    success: false
                })
            }
        })
    }, [])

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Usuarios,
        UsuariosGetService,
        UsuariosAddService,
        UsuariosUpdateService,
        UsuariosDeleteService
    }



}