/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Title from "../../components/Form/Title";
import { ValidarCartaFiscal, ValidarXML } from "../../service/carta-fiscal";
import useUser from "../../hoock/useLogin";
import { Fragment } from "react";
import Button from "../../components/Form/Button";

export default function Index() {
  const { user_id, token } = useUser();
  const [xml, setxml] = useState();
  const [carta_fiscal, setcarta_fiscal] = useState();
  const [file, setfile] = useState();
  const [error, seterror] = useState();

  const validar = () => {
    const formData = new FormData();
    formData.append("xml", xml);
    formData.append("carta_fiscal", carta_fiscal ?? "");
    formData.append("file", file ?? "");
    ValidarXML(formData, token)
      .then((response) => {
        //console.log(response);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  useEffect(() => {
    ValidarCartaFiscal(
      {
        fecha: new Date().toLocaleDateString(),
        user_id,
      },
      token
    )
      .then((response) => {
        //console.log(response);
      })
      .catch((error) => {
        seterror(error);
      });
    return () => {};
  }, []);

  return (
    <div>
      <Title title="Subir facturas" />
      <div className="row">
        <div className="col-md-12 d-flex justify-content-end">
          <h5>{new Date().toLocaleDateString()}</h5>
        </div>
      </div>
      <div className="row">
        {error && (
          <Fragment>
            <div className="col-md-4">
              <label>Carta fiscal</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => {
                  setcarta_fiscal(e.target.files[0]);
                }}
              />
            </div>
            <div className="col-md-4">
              <label>3B</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => {
                  setfile(e.target.files[0]);
                }}
              />
            </div>
          </Fragment>
        )}
        <div className="col-md-4">
          <label>XML</label>
          <input
            accept=".xml"
            type="file"
            className="form-control"
            onChange={(e) => {
              setxml(e.target.files[0]);
            }}
          />
        </div>
        <div className="col-md-4">
          <label>PDF</label>
          <input
            accept=".pdf"
            type="file"
            className="form-control"
            onChange={(e) => {
              setxml(e.target.files[0]);
            }}
          />
        </div>
        <div className="col-md-4 d-flex align-items-end">
          <Button
            type="button"
            label="Buscar"
            onClick={() => {
              validar();
            }}
          />
        </div>
      </div>
    </div>
  );
}
