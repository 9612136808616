import React from "react";
export default function Input({
  objValue,
  onChange,
  index,
  deleteField,
  opciones = [],
}) {

  const { label, value } = objValue;
  return (
    <div className="input-group">
      <label htmlFor={label}>{label}</label>
      <div className="input">
        <select
          id={label}
          value={value || ""}
          onChange={(e) => onChange(e, index)}
          className="form-control"
        >
          {opciones.map((option) => (
            <option value={option}>{option}</option>
          ))}
        </select>
        <div onClick={(e) => deleteField(e, index)}>X</div>
      </div>
    </div>
  );
}
