const ENDPOINT = process.env.REACT_APP_API_URL_DEVELOP;

export async function PerfilCliGet(id, token) {
    return fetch(`${ENDPOINT}PerfilCliGet/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'get'
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function PerfilProfeGet(id, token) {
    return fetch(`${ENDPOINT}PerfilProfeGet/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'get'
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function updateFotoProveedor(data, token) {
    return fetch(`${ENDPOINT}updateFotoProveedor`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'post',
        body: JSON.stringify(data)
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function updateFotoCliente(data, token) {
    return fetch(`${ENDPOINT}updateFotoCliente`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'post',
        body: JSON.stringify(data)
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function PasswordUpdate(data, token) {
    return fetch(`${ENDPOINT}PasswordUpdate`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'post',
        body: data
    }).then(res => res.json()).then(response => {
        return response;
    });
}