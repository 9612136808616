/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Title from "../../components/Form/Title";
import { hideLoader, showLoader, setTitle } from "../../reducers/loaderReducer";
import { useDispatch } from "react-redux";
import useLogin from "../../hoock/useLogin";
import usePerfil from "../../hoock/usePerfil";
import ModalPass from "./Modals/ModalPassword";
import ModalCliente from "./Modals/ModalCli";
import ModalProveedor from "./Modals/ModalProv";
import { updateFotoCliente, updateFotoProveedor } from "../../service/perfil";
import Swal from "sweetalert2";

export default function Index({ children }) {
  const { token, user_id, user_rol, user, user_email } = useLogin();
  const {
    loading,
    error,
    message,
    success,
    Fotos,
    setFotos,
    id,
    NombreComercial,
    setNombreComercial,
    Telefono,
    setTelefono,
    Email,
    setEmail,
    RFC,
    setRFC,
    Tipo,
    setTipo,
    Calle,
    setCalle,
    Exterior,
    setExterior,
    Interior,
    setInterior,
    Colonia,
    setColonia,
    CP,
    setCP,
    Municipio,
    setMunicipio,
    Estado,
    setEstado,
    Pais,
    setPais,
    NombreP,
    setNombreP,
    ApellidoPP,
    setApellidoPP,
    ApellidoMP,
    setApellidoMP,
    TelefonoP,
    setTelefonoP,
    CorreoP,
    setCorreoP,
    RFCP,
    setRFCP,
    PerfilProveGetService,
    PerfilCliGetService,
    EstadoId,
    setEstadoId,
    MunicipioId,
    setMunicipioId,
  } = usePerfil();
  const [ModalPassword, setModalPassword] = useState(false);
  const [ModalCli, setModalCli] = useState(false);
  const [ModalProv, setModalProv] = useState(false);
  const [Password, setPassword] = useState("");
  const [newFoto, setnewFoto] = useState();

  const ENDPOINT = "http://127.0.0.1:8000/";

  useEffect(() => {
    if (user_rol == "cliente") {
      PerfilCliGetService(user_id, token);
      return () => {};
    } else if (user_rol == "proveedor") {
      PerfilProveGetService(user_id, token);
      return () => {};
    } else {
      PerfilProveGetService(user_id, token);
      return () => {};
    }
  }, [user_id]);

  const togglePassword = () => {
    setModalPassword(!ModalPassword);
    setPassword("");
  };

  const toggleCliente = () => {
    setModalCli(!ModalCli);
  };

  const toggleProveedor = () => {
    setModalProv(!ModalProv);
  };

  const handleFileChange = () => {
    const file = document.getElementById("file").files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setnewFoto(reader.result);
    };
  };

  const handleFileUpload = () => {
    if (newFoto) {
      if (user_rol === "proveedor") {
        updateFotoProveedor(
          {
            id: user_id,
            foto: newFoto,
          },
          token
        ).then(() => {
          Swal.fire({
            title: "¡Listo!",
            text: "Foto actualizada con exito",
            icon: "success",
            confirmButtonText: "OK",
          });
        });
      } else {
        updateFotoCliente(
          {
            id: user_id,
            foto: newFoto,
          },
          token
        ).then(() => {
          Swal.fire({
            title: "¡Listo!",
            text: "Se actualizo su foto",
            icon: "success",
            confirmButtonText: "OK",
          });
        });
      }
    }
  };

  return (
    <>
      <ModalPass
        ModalPassword={ModalPassword}
        togglePassword={togglePassword}
        token={token}
        user_id={user_id}
        user_rol={user_rol}
        Password={Password}
        setPassword={setPassword}
        PerfilProveGetService={PerfilProveGetService}
        PerfilCliGetService={PerfilCliGetService}
      />

      <ModalCliente
        ModalCli={ModalCli}
        toggleCliente={toggleCliente}
        token={token}
        user_id={user_id}
        Fotos={Fotos}
        setFotos={setFotos}
        NombreComercial={NombreComercial}
        setNombreComercial={setNombreComercial}
        Telefono={Telefono}
        setTelefono={setTelefono}
        Email={Email}
        setEmail={setEmail}
        RFC={RFC}
        setRFC={setRFC}
        Tipo={Tipo}
        setTipo={setTipo}
        Calle={Calle}
        setCalle={setCalle}
        Exterior={Exterior}
        setExterior={setExterior}
        Interior={Interior}
        setInterior={setInterior}
        Colonia={Colonia}
        setColonia={setColonia}
        CP={CP}
        setCP={setCP}
        Municipio={Municipio}
        setMunicipio={setMunicipio}
        Estado={Estado}
        setEstado={setEstado}
        Pais={Pais}
        setPais={setPais}
        PerfilCliGetService={PerfilCliGetService}
        EstadoId={EstadoId}
        setEstadoId={setEstadoId}
        MunicipioId={MunicipioId}
        setMunicipioId={setMunicipioId}
      />

      <ModalProveedor
        ModalProv={ModalProv}
        toggleProveedor={toggleProveedor}
        token={token}
        user_id={user_id}
        id={id}
        Fotos={Fotos}
        NombreP={NombreP}
        setNombreP={setNombreP}
        ApellidoPP={ApellidoPP}
        setApellidoPP={setApellidoPP}
        ApellidoMP={ApellidoMP}
        setApellidoMP={setApellidoMP}
        TelefonoP={TelefonoP}
        setTelefonoP={setTelefonoP}
        CorreoP={CorreoP}
        setCorreoP={setCorreoP}
        RFCP={RFCP}
        setRFCP={setRFCP}
        PerfilProveGetService={PerfilProveGetService}
      />
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Title title="Perfil" className="text-left" />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-6 col-xl-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex py-4">
                  <div className="preview-list w-100">
                    {!newFoto ? (
                      user_rol === "cliente" ||
                      (user_rol === "proveedor" && Fotos !== null) ? (
                        <img
                          src={
                            ENDPOINT +
                            "images/fotos/" +
                            user_rol.toUpperCase() +
                            "/" +
                            Fotos
                          }
                          className="img-thumbnail rounded mx-auto d-block"
                          style={{ width: 250 }}
                          alt={user}
                        />
                      ) : (
                        <img
                          src={require("../../assets/images/faces/perfil.png")}
                          className="img-thumbnail rounded mx-auto d-block"
                          style={{ width: 250 }}
                          alt={user}
                        />
                      )
                    ) : (
                      <img
                        src={newFoto}
                        className="img-thumbnail rounded mx-auto d-block"
                        style={{ width: 250 }}
                        alt={user}
                      />
                    )}
                    <br />
                    <br />
                    <div className="preview-item p-0">
                      <div className="preview-item-content d-flex flex-grow">
                        <div className="flex-grow">
                          <div className="d-flex d-md-block d-xl-flex justify-content-between">
                            <h6
                              className="preview-subject"
                              style={{ color: "#fff" }}
                            >
                              {user}
                            </h6>
                            <p className="text-muted text-small">
                              Rol: {user_rol.toUpperCase()}
                            </p>
                          </div>
                          <div className="form-group">
                            <input
                              type="file"
                              className="form-control-file"
                              id="file"
                              onChange={handleFileChange}
                            />
                          </div>
                          <button
                            className="btn btn-success"
                            onClick={handleFileUpload}
                          >
                            <i className="mdi mdi-upload"></i>Actualizar foto
                          </button>
                          {user_rol === "cliente" ||
                          user_rol === "proveedor" ? (
                            <button
                              className="btn btn-warning"
                              onClick={
                                user_rol === "cliente"
                                  ? toggleCliente
                                  : toggleProveedor
                              }
                            >
                              <i className="mdi mdi-border-color"></i>Editar
                            </button>
                          ) : (
                            ""
                          )}
                          <br />
                          <br />
                          <button
                            className="btn btn-info"
                            onClick={togglePassword}
                          >
                            <i className="mdi mdi-account-key"></i>Cambiar
                            password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(user_rol === "proveedor" && (
            <div className="col-md-12 col-xl-6 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <div className="list-wrapper">
                    <ul className="d-flex flex-column todo-list">
                      <li>
                        <div className="form-check" style={{ width: "100%" }}>
                          <label htmlFor="" className="form-check-label">
                            <div className="row">
                              <div className="col-md-6">Nombre</div>
                              <div className="col-md-6">{NombreP}</div>
                            </div>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check" style={{ width: "100%" }}>
                          <label htmlFor="" className="form-check-label">
                            <div className="row">
                              <div className="col-md-6">Apellido Paterno</div>
                              <div className="col-md-6">{ApellidoPP}</div>
                            </div>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check" style={{ width: "100%" }}>
                          <label htmlFor="" className="form-check-label">
                            <div className="row">
                              <div className="col-md-6">Apellido Materno</div>
                              <div className="col-md-6">{ApellidoMP}</div>
                            </div>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check" style={{ width: "100%" }}>
                          <label htmlFor="" className="form-check-label">
                            <div className="row">
                              <div className="col-md-6">Telefono</div>
                              <div className="col-md-6">{TelefonoP}</div>
                            </div>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check" style={{ width: "100%" }}>
                          <label htmlFor="" className="form-check-label">
                            <div className="row">
                              <div className="col-md-6">Correo</div>
                              <div className="col-md-6">{CorreoP}</div>
                            </div>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="form-check" style={{ width: "100%" }}>
                          <label htmlFor="" className="form-check-label">
                            <div className="row">
                              <div className="col-md-6">RFC</div>
                              <div className="col-md-6">{RFCP}</div>
                            </div>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )) ||
            (user_rol === "cliente" && (
              <div className="col-md-12 col-xl-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="list-wrapper">
                      <ul className="d-flex flex-column todo-list">
                        <li>
                          <div className="form-check" style={{ width: "100%" }}>
                            <label htmlFor="" className="form-check-label">
                              <div className="row">
                                <div className="col-md-6">Nombre Comercial</div>
                                <div className="col-md-6">
                                  {NombreComercial}
                                </div>
                              </div>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check" style={{ width: "100%" }}>
                            <label htmlFor="" className="form-check-label">
                              <div className="row">
                                <div className="col-md-6">Telefono</div>
                                <div className="col-md-6">{Telefono}</div>
                              </div>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check" style={{ width: "100%" }}>
                            <label htmlFor="" className="form-check-label">
                              <div className="row">
                                <div className="col-md-6">Correo</div>
                                <div className="col-md-6">{Email}</div>
                              </div>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check" style={{ width: "100%" }}>
                            <label htmlFor="" className="form-check-label">
                              <div className="row">
                                <div className="col-md-6">RFC</div>
                                <div className="col-md-6">{RFC}</div>
                              </div>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check" style={{ width: "100%" }}>
                            <label htmlFor="" className="form-check-label">
                              <div className="row">
                                <div className="col-md-6">Tipo</div>
                                <div className="col-md-6">{Tipo}</div>
                              </div>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check" style={{ width: "100%" }}>
                            <label htmlFor="" className="form-check-label">
                              <div className="row">
                                <div className="col-md-6">Calle</div>
                                <div className="col-md-6">{Calle}</div>
                              </div>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check" style={{ width: "100%" }}>
                            <label htmlFor="" className="form-check-label">
                              <div className="row">
                                <div className="col-md-6">No. Exterior</div>
                                <div className="col-md-6">{Exterior}</div>
                              </div>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check" style={{ width: "100%" }}>
                            <label htmlFor="" className="form-check-label">
                              <div className="row">
                                <div className="col-md-6">No. Interior</div>
                                <div className="col-md-6">{Interior}</div>
                              </div>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check" style={{ width: "100%" }}>
                            <label htmlFor="" className="form-check-label">
                              <div className="row">
                                <div className="col-md-6">Colonia</div>
                                <div className="col-md-6">{Colonia}</div>
                              </div>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check" style={{ width: "100%" }}>
                            <label htmlFor="" className="form-check-label">
                              <div className="row">
                                <div className="col-md-6">Codigo Postal</div>
                                <div className="col-md-6">{CP}</div>
                              </div>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check" style={{ width: "100%" }}>
                            <label htmlFor="" className="form-check-label">
                              <div className="row">
                                <div className="col-md-6">Municipio</div>
                                <div className="col-md-6">{Municipio}</div>
                              </div>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check" style={{ width: "100%" }}>
                            <label htmlFor="" className="form-check-label">
                              <div className="row">
                                <div className="col-md-6">Estado</div>
                                <div className="col-md-6">{Estado}</div>
                              </div>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check" style={{ width: "100%" }}>
                            <label htmlFor="" className="form-check-label">
                              <div className="row">
                                <div className="col-md-6">Pais</div>
                                <div className="col-md-6">{Pais}</div>
                              </div>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )) ||
            user_rol !== "cliente" ||
            (user_rol !== "proveedor" && (
              <div className="col-md-12 col-xl-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <div className="list-wrapper">
                      <ul className="d-flex flex-column todo-list">
                        <li>
                          <div className="form-check" style={{ width: "100%" }}>
                            <label htmlFor="" className="form-check-label">
                              <div className="row">
                                <div className="col-md-6">Nombre</div>
                                <div className="col-md-6">{user}</div>
                              </div>
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check" style={{ width: "100%" }}>
                            <label htmlFor="" className="form-check-label">
                              <div className="row">
                                <div className="col-md-6">Correo</div>
                                <div className="col-md-6">{user_email}</div>
                              </div>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
