/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ModalAdd from "./Modals/ModalAdd";
import Link from "../../components/Form/Link";
import Title from "../../components/Form/Title";
import useLogin from "../../hoock/useLogin";
import Swal from "sweetalert2";
import { ConfiguracionGet } from "../../service/configuracion";
import {
  ProveedoresNuevosGet,
  ProveedoresNuevosDelete,
} from "../../service/proveedorNuevos";
import { Link as LinkReact } from "react-router-dom";
import { Alert, AlertTitle, Button, Chip } from "@mui/material";
import copy from "copy-to-clipboard";

export default function Index() {
  const { user_id, token, cliente_id } = useLogin();
  const [modal, setModal] = useState(false);
  const [Update, setUpdate] = useState(false);
  const [Id, setId] = useState("");
  const [configuracionGet, setconfiguracionGet] = useState();
  const [form, setform] = useState([]);
  const [array, setarray] = useState([]);

  useEffect(() => {
    ConfiguracionGet(token).then((response) => {
      if (response.success) {
        const { data } = response;
        data.preguntas_json = JSON.parse(data.preguntas);
        setconfiguracionGet(data);
        setform(data.preguntas_json);
      }
    });

    getInformacion();
    return () => {};
  }, []);

  const getInformacion = () => {
    ProveedoresNuevosGet(user_id, token).then((response) => {
      response.map((item) => {
        item.respuestas = JSON.parse(item.respuestas);
      });
      setarray(response);
    });
  };

  const toggle = () => {
    setModal(!modal);
    setUpdate(false);
    setId("");
  };

  const Eliminar = (id) => {
    Swal.fire({
      title: "Espere por favor...",
      text: "Cargando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      icon: "question",
      onOpen: () => {
        Swal.showLoading();
      },
    });

    const data = {
      id: id,
    };

    ProveedoresNuevosDelete(data, token).then((response) => {
      if (response.success) {
        getInformacion();
        Swal.close();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Algo salio mal!",
          footer: "<a href>Why do I have this issue?</a>",
        });
      }
    });
  };

  return (
    <>
      {modal && (
        <ModalAdd
          modal={modal}
          toggle={toggle}
          Update={Update}
          Id={Id}
          token={token}
          form={form}
          setform={setform}
          cliente_id={cliente_id}
          getInformacion={getInformacion}
        />
      )}
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <Title title="Listado de Proveedores" className="text-left" />
          </div>
          <div className="col-md-6 text-end">
            <Button variant="contained" color="success" onClick={toggle}>
              Agregar proveedores
            </Button>
          </div>
        </div>
        <br />
        {configuracionGet ? (
          <div className="row">
            <div className="row">
              <div className="col-md-12">
                <Alert severity="info" style={{ width: "100%" }}>
                  <AlertTitle>
                    <strong>Informacion</strong>
                  </AlertTitle>
                  <h5 className="text-center">
                    <LinkReact
                      to="/configuracion/create-formulario"
                      target="_blank"
                    >
                      {" "}
                      Aqui
                    </LinkReact>{" "}
                    puedes crear un nuevo formulario para proveedores o
                    compartir este link con el proveedor para que lo llene
                    <br />
                    <Button
                      variant="contained"
                      onClick={() => {
                        copy(
                          `http://localhost:3000/configuracion/view-formulario/${configuracionGet.uuid}`
                        );
                        Swal.fire({
                          icon: "success",
                          title: "Exito",
                          text: "Se ha copiado el link correctamente",
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      }}
                      sx={{ ml: 1 }}
                      color="info"
                    >
                      Copiar link
                    </Button>
                  </h5>
                </Alert>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <table className="table table-bordered">
                <thead className="table-dark">
                  <tr className="text-center">
                    {configuracionGet.preguntas_json.map((item, index) => {
                      const label = item.label;
                      return <th key={index}>{label}</th>;
                    })}
                    <th>Contrato</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {array.map((item, index) => {
                    return (
                      <tr key={index}>
                        {item.respuestas.map((item2, index2) => {
                          return (
                            <td key={index2}>
                              {item2.type === "password"
                                ? "***********"
                                : item2.value}
                            </td>
                          );
                        })}
                        <td className="text-center">
                          {item.contrato === null ? (
                            <Chip label="Sin contrato" color="warning" />
                          ) : (
                            <Button
                              onClick={() => {
                                window.open(
                                  `http://localhost:8000/api/generar-contrato/${item.contrato}/${item.id}`,
                                  "_blank",
                                  "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes,top=100,left=250,width=1200,height=800"
                                );
                              }}
                              variant="contained"
                              color="info"
                              size="small"
                            >
                              Ver contrato
                            </Button>
                          )}
                        </td>
                        <td className="text-center d-flex gap-3 justify-content-center">
                          <Button
                            variant="contained"
                            color="warning"
                            size="small"
                            onClick={() => {
                              setUpdate(true);
                              setId(item.id);
                              setModal(true);
                              setform(item.respuestas);
                            }}
                          >
                            Editar
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => {
                              Eliminar(item.id);
                            }}
                          >
                            Eliminar
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <h5 className="text-center">
                No hay informacion disponible para mostrar, agrega una nueva
                configuracion de proveedor para mostrar la informacion
                <LinkReact
                  to="/configuracion/create-formulario"
                  target="_blank"
                >
                  {" "}
                  aqui
                </LinkReact>
              </h5>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
