import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
} from "reactstrap";
import useHijos from "../../../hoock/useHijos";
import Select from "react-select";
import Hijos from "../../../components/HOC´s/Hijos";
import Swal from "sweetalert2";

const unidades = [
  { value: "unico", label: "Unico" },
  { value: "semanal", label: "Semanal" },
  { value: "quincenal", label: "Quincenal" },
  { value: "mensual", label: "Mensual" },
  { value: "bimestral", label: "Bimestral" },
  { value: "trimestral", label: "Trimestral" },
  { value: "semestral", label: "Semestral" },
  { value: "anual", label: "Anual" },
];

const templete = {
  NombreH: "",
  CuentaPre: "",
  VariableID: "",
  Cantidad: "",
  X: "",
  Unidades: "",
  MostrarVariable: false,
  IsChecked: true,
  Fecha: "",
  Opcion: "",
  Cuantos: "",
  Costo: "",
  Subtota: "",
  Iva: "",
  Total: "",
};

export default function ModalAdd({
  modalHijo,
  toggleHijo,
  PadreID,
  HijoID,
  NivelID,
  IsChecked,
  token,
  setIsChecked,
  PresupuestosGetService,
}) {
  const { loading, error, message, success, HijosAddService, HijosHijosAdd_Service } = useHijos();

  const [SelectedVariable, setSelectedVariable] = useState([]);
  const [SelectedOpcion, setSelectedOpcion] = useState([]);
  const [Arreglo, setArreglo] = useState([templete]);
  const [Numerica, setNumerica] = useState(null);

  useEffect(() => {
    if (success === true) {
      PresupuestosGetService(token);
      toggleHijo();
    }
  }, [PresupuestosGetService, success]);

  const NuevaSerie = () => {
    setArreglo([...Arreglo, templete]);
  };

  const guardar = () => {
    const data = new FormData();
    data.append("PadreID", PadreID);
    data.append("HijoID", HijoID);
    data.append("items", JSON.stringify(Arreglo));
    data.append("nivel_id", parseInt(NivelID) + 1);
    data.append("subpadre", "No");
    HijosHijosAdd_Service(data);

    Swal.fire({
      title: "Registrando cuenta hijo",
      text: "Loading...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      icon: 'question',
      onOpen: () => {
        Swal.showLoading();
      }
    });
  };

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error])

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
      toggleHijo();
    }
  }, [message, success])

  // const guardarVariable = () => {
  //   const data = new FormData();
  //   data.append("nombre", NombreVari);
  //   data.append("cantidad", );

  //   VariableAddService(data);
  // };

  // const handleOnChange = () => {
  //   setIsChecked(!IsChecked);
  // };

  return (
    <>
      <Modal
        isOpen={modalHijo}
        toggle={toggleHijo}
        size="lg"
        style={{ maxWidth: "1600px", width: "80%" }}
      >
        <ModalHeader className="backend_titulo" toggle={toggleHijo}>
          Agregar Hijos
        </ModalHeader>
        <ModalBody>
          {Arreglo.map((item, index) => {
            return (
              <Hijos
                key={index}
                item={item}
                index={index}
                Arreglo={Arreglo}
                templete={templete}
                Numerica={Numerica}
                token={token}
                setArreglo={setArreglo}
                setNumerica={setNumerica}
                setIsChecked={setIsChecked}
                PadreID={PadreID}
                HijoID={HijoID}
              />
            );
          })}
        </ModalBody>
        <ModalFooter>
          <Button className="backend_boton" onClick={() => guardar()}>
            Guardar y Cerrar
          </Button>
          <Button color="danger" onClick={toggleHijo}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
