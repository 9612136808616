import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

export default function ItemsSidebar({
    item,
    index,
    pathname,
}) {
  return (
    <li
      className={`nav-item menu-items ${
        pathname === item.path ? "active" : ""
      }`}
      key={index}
    >
      <Link className="nav-link" to={item.path}>
        <span className="menu-icon">
          <i className={item.icon}></i>
        </span>
        <span className="menu-title">
          <Trans>{item.state}</Trans>
        </span>
      </Link>
    </li>
  );
}
