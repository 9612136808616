/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import useFechas from "../../hoock/useFechas";
// import ModalAdd from "./Modals/ModalAdd";
import {
  Grid,
  GridColumn,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridPDFExport } from "@progress/kendo-react-pdf";
import { filterBy } from "@progress/kendo-data-query";
import Link from "../../components/Form/Link";
import Title from "../../components/Form/Title";
import { hideLoader, showLoader, setTitle } from "../../reducers/loaderReducer";
import { useDispatch } from "react-redux";
import useLogin from "../../hoock/useLogin";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { OrdenesPagoAdd } from "../../service/ordenesPago";

const PageTemplate = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        left: "10px",
      }}
    >
      Page {props.pageNum} of {props.totalPages}
    </div>
  );
};

const initialDataState = {
  skip: 0,
  take: 10,
};

const initialFilter = {
  logic: "and",
  filters: [],
};

const initialSort = [
  // {
  //   field: "modelo",
  //   dir: "desc",
  // },
  // {
  //   field: "serie",
  //   dir: "desc",
  // },
  // {
  //   field: "fecha_venta",
  //   dir: "desc",
  // },
  // {
  //   field: "Venta",
  //   dir: "desc",
  // },
];

// arreglo de productos
const Prducts = [
  {
    id: "1",
    cantidad: "1",
    descripcion: "Contador de producción en set",
    partida: "1003",
    importe: 10000,
  },
  {
    id: "2",
    cantidad: "1",
    descripcion: "Contador de producción en set",
    partida: "1002",
    importe: 20000,
  },
];

// fecha actual
const date = new Date();

export default function Index() {
  const { token, cliente_id, user_rol } = useLogin();
  const dispatch = useDispatch();
  const {
    loading,
    error,
    message,
    success,
    Fecha,
    FechasGetService,
    FechasOrdenAddService,
  } = useFechas();
  const [Confirmar, setConfirmar] = useState(false);
  const [Facturas, setFacturas] = useState([]);
  const [FacturasValue, setFacturasValue] = useState([]);
  const [FechaPago, setFechaPago] = useState("");
  const [sort, setSort] = React.useState(initialSort);
  const [filter, setFilter] = React.useState(initialFilter);
  const [page, setPage] = React.useState(initialDataState);
  const { id } = useParams();
  let i = 1;

  useEffect(() => {
    dispatch(setTitle("Descargando Fechas de pago"));
    dispatch(showLoader());
    FechasGetService(id, token);
    return () => {};
  }, []);

  useEffect(() => {
    if (Fecha.length >= 0) {
      dispatch(hideLoader());
      dispatch(setTitle(""));
    }
    return () => {};
  }, [Fecha]);

  useEffect(() => {
    if (success === true) {
      FechasGetService(id, token);
    }
  }, [FechasGetService, success]);

  const pageChange = (event) => {
    setPage(event.page);
  };

  const formatNumber = (number) =>
    new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

  useEffect(() => {
    if (success) {
      Swal.close();
      Swal.fire({
        icon: "success",
        title: "Exito",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, success]);

  const generatePDF = (
    fehca_pago,
    nombre_comercial,
    RFC,
    proyecto,
    empresa,
    created_at,
    direecion,
    ordenes_pagos
  ) => {
    const doc = new jsPDF();
    let subtotal = 0;

    var img = new Image();
    img.src = "/logo-login.jpg";
    //img.src = "/incidencias/images/Logo_Horizontal_ANAM.png";
    doc.addImage(img, "png", 10, 10, 80, 20);
    doc.setFontSize(12);
    doc.text(110, 10, nombre_comercial);
    doc.setFontSize(10);
    doc.text(110, 15, "RFC: " + RFC);
    doc.text(110, 20, direecion, {
      align: "left",
      lineHeightFactor: 1,
      maxWidth: 100,
    });
    doc.text(110, 29, proyecto);
    doc.text(110, 33, "(" + empresa + ")");
    doc.text(110, 38, "SOLICITUD DE PAGO No. 6");
    doc.text(110, 43, "FECHA DE ELABORACION: " + created_at);

    autoTable(doc, { html: "#my-pagos" });
    autoTable(doc, {
      theme: "grid",
      body: [
        [
          "PERIODO DE PAGO",
          "Del " +
            getFirstDayOfWeek(date) +
            " al " +
            getLastDayOfWeek(date) +
            " del " +
            date.getFullYear(),
          "SEMANA",
          getWeek(date) - 1,
          "ETAPA",
          "Rodaje",
        ],
        [
          { content: "FECHA DE PAGO", colSpan: 1 },
          { content: fehca_pago, colSpan: 1 },
          { content: "FORMA DE PAGO", colSpan: 1 },
          { content: "03 - Transferencia electrónica de fondos", colSpan: 3 },
        ],
      ],

      startY: 50,
    });

    autoTable(doc, { html: "#my-departamento" });
    autoTable(doc, {
      theme: "plain",
      body: [
        [
          "DEPARTAMENTO PRESTADOR DEL SERVICIO ",
          "ELYSIAN SYS MEXICO, SA DE CV",
        ],
        ["PAGO A FAVOR DE:", "ELYSIAN SYS MÉXICO S.A. DE C.V."],
        ["R. F. C.", "ESM170628CK1"],
        ["BANCO", "INBURSA"],
        ["CUENTA - CLABE", "036180500422014822"],
      ],

      startY: 73,
    });

    autoTable(doc, { html: "#my-table" });
    autoTable(doc, {
      theme: "striped",
      head: [["CANTIDAD", "DESCRIPCIÓN", "PARTIDA P", "IMPORTE"]],
      body: ordenes_pagos.map((product) => {
        subtotal += product.monto;
        return [
          1,
          product.nombre,
          { content: product.cuenta_presupuental, styles: { halign: "center" } },
          {
            content: "$" + formatNumber(product.monto),
            styles: { halign: "right" },
          },
        ];
      }),
      foot: [
        [
          "",
          "",
          "SUBTOTAL",
          { content: "$" + formatNumber(subtotal), styles: { halign: "right" } },
        ],
        [
          "",
          "",
          "IVA",
          { content: "$" + formatNumber(subtotal*0.16), styles: { halign: "right" } },
        ],
        [
          "",
          "",
          "TOTAL A PAGAR",
          { content: "$" + formatNumber(subtotal*1.16), styles: { halign: "right" } },
        ],
        ["", "", "FOLIO FISCAL", "7204a394-cdb2-4352-92a4-3b7b26028341"],
      ],

      startY: 115,
    });

    autoTable(doc, { html: "#my-productor" });
    autoTable(doc, {
      theme: "grid",
      body: [
        ["PRODUCTOR", "DIRECCIÓN DE PRODUCCIÓN", "PRODUCTOR EJECUTIVO"],
        ["Alejandro Sugich", "Paola Méndez", "Julieta Perales"],
      ],
    });

    autoTable(doc, { html: "#my-nota" });
    autoTable(doc, {
      theme: "plain",
      body: [
        [
          "Nota",
          "El pago se hará a la cuenta bancaria indicada en esta solicitud de pago, por lo que se solicita a la empresa proveedora de servicios, que en caso de no ser la misma cuenta bancaria, se indique la cuenta a la que se hará el depósito.",
        ],
      ],
    });

    // doc.text(
    //   15,
    //   200,
    //   "Nota: ",
    //   { align: "left", lineHeightFactor: 1, maxWidth: 180 }
    // );

    // doc.save("demo.pdf");
    window.open(
      doc.output("bloburl"),
      "_blank",
      "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes,top=100,left=250,width=1200,height=800"
    );
  };

  const sendArchivo = (e, folio) => {
    // send comprobante_pago fetch post OrdenesPagoAdd
    const formData = new FormData();
    formData.append("comprobante_pago", e.target.files[0]);
    formData.append("folio", folio);

    OrdenesPagoAdd(folio, formData, token).then((response) => {
      if(response.success){
        FechasGetService(id, token);
      }else{
        // Swal error message
        Swal.fire({
          icon: "danger",
          title: "Error",
          confirmButtonColor: "#3b82f6",
          text: "Hubo un error al subir el archivo",
        });
      }
    });
  };

  const UnirFacturas = (e) => {
    const newFactura =
      FacturasValue.some((value) => value == e) === true ? true : false;
    let facturasLength = [];
    if (newFactura) {
      facturasLength = FacturasValue.filter((value) => value != e);
      setFacturasValue(FacturasValue.filter((value) => value != e));
      setFacturas(Facturas.filter((value) => value.e != e));
    } else {
      facturasLength = [...FacturasValue, e];
      setFacturasValue([...FacturasValue, e]);
      setFacturas([...Facturas, { id: e }]);
      setConfirmar(true);
    }

    let cantidad = facturasLength.length;
    if (cantidad == 0) {
      setConfirmar(false);
    } else {
      setConfirmar(true);
    }
  };

  const getWeek = (date) => {
    const onejan = new Date(date.getFullYear(), 0, 1);
    return Math.ceil(((date - onejan) / 86400000 + onejan.getDay() + 1) / 7);
  };

  const getFirstDayOfWeek = (date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day == 0 ? -6 : 1);
    const firstDay = new Date(date.setDate(diff));
    return firstDay.getDate() + " de " + getMonthName(firstDay.getMonth() + 1);
  };
  const getLastDayOfWeek = (date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day == 0 ? -6 : 1) + 6;
    const lastDay = new Date(date.setDate(diff));
    return lastDay.getDate() + " de " + getMonthName(lastDay.getMonth() + 1);
  };
  // funcion mes en letras
  const getMonthName = (month) => {
    switch (month) {
      case 1:
        return "Enero";
      case 2:
        return "Febrero";
      case 3:
        return "Marzo";
      case 4:
        return "Abril";
      case 5:
        return "Mayo";
      case 6:
        return "Junio";
      case 7:
        return "Julio";
      case 8:
        return "Agosto";
      case 9:
        return "Septiembre";
      case 10:
        return "Octubre";
      case 11:
        return "Noviembre";
      case 12:
        return "Diciembre";
      default:
        return "Mes no encontrado";
    }
  };

  const createOrden = () => {
    // validar que FechaPago no sea null
    if (FechaPago == "") {
      Swal.fire({
        title: "Error",
        text: "Seleccione una fecha de pago",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    } else {
      const data = new FormData();
      data.append("cliente_id", cliente_id);
      data.append("facturas", FacturasValue);
      data.append("fecha_pago", FechaPago);

      FechasOrdenAddService(data, token);

      Swal.fire({
        title: "Registrando orden de pago",
        text: "Loading...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: "question",
        onOpen: () => {
          Swal.showLoading();
        },
      });
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <Title title="Listado de Fechas de pago" className="text-left" />
          </div>
          {Confirmar == true ? (
            <div className="col-md-6 text-end">
              <div class="input-group mb-3">
                <input
                  type="date"
                  class="form-control"
                  value={FechaPago}
                  onChange={(e) => setFechaPago(e.target.value)}
                />
                <button
                  class="btn btn-outline-primary"
                  type="button"
                  id="button-addon2"
                  onClick={() => createOrden()}
                >
                  Confirmar facturas
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <Grid
              sortable={true}
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
              filterable={true}
              filter={filter}
              onFilterChange={(e) => setFilter(e.filter)}
              pageable={true}
              data={filterBy(
                Fecha.slice(page.skip, page.take + page.skip),
                filter
              )}
              skip={page.skip}
              take={page.take}
              total={Fecha.length}
              onPageChange={pageChange}
              style={{
                height: "520px",
              }}
            >
              <GridNoRecords>No hay informacion disponible</GridNoRecords>
              <GridColumn field="folio" title="Folio ordend e pago" />
              <GridColumn field="proyecto" title="Proyecto" />
              <GridColumn field="cuenta_padre" title="Cuenta Padre" />
              <GridColumn
                field="cuenta_presupuental"
                title="Cuenta Presupuestal"
              />
              <GridColumn field="fecha" title="Fechas de pagos" filter="text" />
              <GridColumn
                field="monto"
                title="Monto"
                filter="text"
                format="{0:c}"
              />
              <GridColumn
                field="Status"
                filter={false}
                filterable={false}
                cell={(a) => {
                  let status = a.dataItem.status;
                  return (
                    <td className=" text-center">
                      {(status == "Pagado" && (
                        <div className="badge badge-outline-success text-success">
                          Pagado
                        </div>
                      )) ||
                        (status == "Pendiente" && (
                          <div className="badge badge-outline-warning text-warning">
                            Pendiente
                          </div>
                        ))}
                    </td>
                  );
                }}
              />
              <GridColumn
                field="Unir Facturas"
                filter={false}
                filterable={false}
                cell={(a) => {
                  let id = a.dataItem.id;
                  let semana = getWeek(date);
                  let fecha_pago = a.dataItem.fecha;
                  let semana_pago = getWeek(new Date(fecha_pago));
                  let folio = a.dataItem.folio;
                  return (
                    <td className=" text-center">
                      {folio == null
                        ? semana == semana_pago && (
                            <input
                              type={"checkbox"}
                              checked={
                                FacturasValue.some((value) => value == id) ===
                                true
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                UnirFacturas(id);
                              }}
                            />
                          )
                        : ""}
                    </td>
                  );
                }}
              />
              <GridColumn
                field="Orden PDF"
                filter={false}
                filterable={false}
                cell={(e) => {
                  let folio = e.dataItem.folio;
                  let fehca_pago = e.dataItem.fecha_pago;
                  let nombre_comercial = e.dataItem.nombre_comercial;
                  let RFC = e.dataItem.RFC;
                  let proyecto = e.dataItem.proyecto;
                  let empresa = e.dataItem.empresa;
                  let created_at = e.dataItem.created_at;
                  let direecion =
                    e.dataItem.calle +
                    " " +
                    e.dataItem.exterior +
                    " " +
                    e.dataItem.inerior +
                    " " +
                    e.dataItem.colonia +
                    " " +
                    e.dataItem.cp +
                    " " +
                    e.dataItem.nom_mun +
                    " " +
                    e.dataItem.nom_estado +
                    " " +
                    e.dataItem.nom_pais;
                    let ordenes_pagos = JSON.parse(e.dataItem.ordenes_pagos);
                  return (
                    <td className=" text-center">
                      {folio != null && (
                        <button
                          onClick={() => {
                            generatePDF(
                              fehca_pago,
                              nombre_comercial,
                              RFC,
                              proyecto,
                              empresa,
                              created_at,
                              direecion,
                              ordenes_pagos
                            );
                          }}
                          className="btn btn-danger btn-sm"
                        >
                          PDF
                        </button>
                      )}
                    </td>
                  );
                }}
              />
              {user_rol === "contabilidad" && (
                <GridColumn
                  field="Ficha de pago"
                  filter={false}
                  filterable={false}
                  cell={(e) => {
                    let folio = e.dataItem.folio;
                    return (
                      <td className=" text-center">
                        {folio != null && (
                          <input
                            onChange={(e) => sendArchivo(e, folio)}
                            type="file"
                            placeholder="Subir ficha de pago"
                            className="btn btn-dark btn-sm text-white"
                          />
                        )}
                      </td>
                    );
                  }}
                />
              )}
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
