import React, { useState, useEffect } from 'react';
import useNiveles from '../../hoock/useNiveles';

export default function Index() {
    const { loading, error, message, success, Niveles, NivelGetService } = useNiveles();
    const [modal, setModal] = useState(false);

    

    let i = 1;
    useEffect(() => {
        NivelGetService()
    }, [NivelGetService])

    useEffect(() => {
        if (success == true) {
            NivelGetService()
        }
    }, [NivelGetService, success])
    return <>
        <section style={{ paddingTop: 50, paddingBottom: 50 }}>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className="col-md-6">
                                <h2 className="text-black backend_titulo">Listado de Niveles</h2>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr className="text-black">
                                                <th>#</th>
                                                <th>Numeración</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Niveles.map(({ id, numero }) => {
                                                    return <tr className="text-black" key={id}>
                                                        <td>{i++}</td>
                                                        <td>Posición {numero}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}