/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ConfiguracionGetUUID } from "../../service/configuracion";
import useLogin from "../../hoock/useLogin";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import "./style/app.css";
import { ProveedoresNuevosAdd } from "../../service/proveedorNuevos";
import Swal from "sweetalert2";

export default function ViewFormulario() {
  const { uuid } = useParams();
  const { token } = useLogin();
  const [preguntas, setpreguntas] = useState([]);
  const [cliente_id, setcliente_id] = useState();

  useEffect(() => {
    const second = async () => {
      const { data } = await ConfiguracionGetUUID(uuid, token);
      setcliente_id(data?.cliente_id);
      setpreguntas(data?.preguntas ? JSON.parse(data?.preguntas) : []);
    };

    second();
    return () => {};
  }, []);

  const saveInformacion = (e) => {
    const data = {
      respuestas: JSON.stringify(preguntas),
      cliente_id: cliente_id,
    };
    ProveedoresNuevosAdd(data, token).then((response) => {
      if (response.success) {
        Swal.fire({
          icon: "success",
          title: "Guardado correctamente",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Algo salio mal!",
          footer: "<a href>Why do I have this issue?</a>",
        });
      }
    });
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-12">
          <h1>Formulario de registro para proveedor</h1>
        </div>
        {preguntas.map((pregunta, index) => (
          <div className="col-4">
            {pregunta.type === "select" ? (
              <div className="input-group">
                <label htmlFor={pregunta.label}>{pregunta.label}</label>
                <div className="input">
                  <select
                    type={pregunta.type || "text"}
                    id={pregunta.label}
                    className="form-control"
                    value={pregunta.value}
                    onChange={(e) => {
                      const newForm = [...preguntas];
                      newForm[index].value = e.target.value;
                      setpreguntas(newForm);
                    }}
                  >
                    <option value="">Seleccione una opción</option>
                    {pregunta.opciones.map((opcion, index) => (
                      <option key={index} value={opcion}>
                        {opcion}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              <div className="input-group">
                <label htmlFor={pregunta.label}>{pregunta.label}</label>
                <div className="input">
                  <input
                    type={pregunta.type || "text"}
                    id={pregunta.label}
                    className="form-control"
                    value={pregunta.value}
                    onChange={(e) => {
                      const newForm = [...preguntas];
                      newForm[index].value = e.target.value;
                      setpreguntas(newForm);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        ))}

        <div className="col-12">
          <button className="add-btn" onClick={saveInformacion}>
            Guardar Informacion
          </button>
        </div>
      </div>
    </div>
  );
}
