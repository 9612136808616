/* eslint-disable eqeqeq */
import { useCallback, useState } from 'react';
import { NivelGet, NivelAdd, NivelUpdate, NivelDelete} from '../service/nivel';

export default function useUsers() {
    const [Niveles, setNiveles] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const NivelGetService = useCallback((token) => {
        NivelGet(token).then(response => {
            setNiveles(response)
        })
    }, []);
    /*
     * Agregar Nivel
     */
    const NivelAddService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        NivelAdd(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let nombre = errores['nombre'];
                let email = errores['email'];
                let password = errores['password'];
                let tipo_Nivel = errores['tipo_Nivel'];
                if (nombre != null) {
                    setState({ loading: false, error: true, message: nombre[0], success: false })
                }
                if (email != null) {
                    setState({ loading: false, error: true, message: email[0], success: false })
                }
                if (password != null) {
                    setState({ loading: false, error: true, message: password[0], success: false })
                }
                if (tipo_Nivel != null) {
                    setState({ loading: false, error: true, message: tipo_Nivel[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Nivel registrado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al registrar Nivel',
                        success: false
                    })
                }
            }
        })
    }, [])

    /*
     * Actualizar Nivel
     */
    const NivelUpdateService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        NivelUpdate(
            data, token
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let nombre = errores['nombre'];
                let email = errores['email'];
                let tipo_Nivel = errores['tipo_Nivel'];
                if (nombre != null) {
                    setState({ loading: false, error: true, message: nombre[0], success: false })
                }
                if (email != null) {
                    setState({ loading: false, error: true, message: email[0], success: false })
                }
                if (tipo_Nivel != null) {
                    setState({ loading: false, error: true, message: tipo_Nivel[0], success: false })
                }
            } else {
                if (response.guardado === true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Nivel actualizado con exito',
                        success: true
                    })
                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al actualizar Nivel',
                        success: false
                    })
                }
            }
        })
    }, [])
    /*
     * Eliminar Nivel
     */
    const NivelDeleteService = useCallback((
        data, token
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        NivelDelete(
            data, token
        ).then(response => {
            if (response.guardado === true) {
                setState({
                    loading: false,
                    error: false,
                    message: 'Nivel eliminado con exito',
                    success: true
                })
            } else {
                setState({
                    loading: false,
                    error: true,
                    message: 'Error al eliminar Nivel',
                    success: false
                })
            }
        })
    }, [])

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Niveles,
        NivelGetService,
        NivelAddService,
        NivelUpdateService,
        NivelDeleteService
    }
}